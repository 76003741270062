const CORSI_QUESTIONS = [
    {
        a: {
            question: [0, 4],
        },
        b: {
            question: [2, 5],
        },
        c: {
            question: [6, 7],
        },
        d: {
            question: [1, 4],
        },
    },
    {
        a: {
            question: [0, 3, 1],
        },
        b: {
            question: [1, 4, 8],
        },
        c: {
            question: [4, 7, 5],
        },
        d: {
            question: [0, 3, 7],
        },
    },
    {
        a: {
            question: [3, 7, 4, 5],
        },
        b: {
            question: [4, 7, 2, 3],
        },
        c: {
            question: [1, 3, 4, 8],
        },
        d: {
            question: [1, 5, 4, 2],
        },
    },
    {
        a: {
            question: [6, 7, 3, 1, 5],
        },
        b: {
            question: [2, 7, 4, 5, 1],
        },
        c: {
            question: [5, 7, 4, 3, 1],
        },
        d: {
            question: [3, 7, 5, 6, 4],
        },
    },
    {
        a: {
            question: [7, 5, 4, 2, 1, 3],
        },
        b: {
            question: [5, 1, 8, 4, 2, 3],
        },
        c: {
            question: [2, 4, 6, 7, 5, 8],
        },
        d: {
            question: [7, 8, 3, 4, 6, 1],
        },
    },
    {
        a: {
            question: [4, 3, 7, 8, 1, 2, 5],
        },
        b: {
            question: [8, 4, 5, 7, 3, 1, 2],
        },
        c: {
            question: [8, 4, 2, 1, 3, 7, 6],
        },
        d: {
            question: [4, 0, 1, 5, 7, 3, 6],
        },
    },
    {
        a: {
            question: [1, 5, 0, 4, 6, 4, 2, 3],
        },
        b: {
            question: [6, 1, 3, 4, 7, 2, 5, 0],
        },
        c: {
            question: [6, 4, 2, 3, 1, 0, 5, 4],
        },
        d: {
            question: [1, 3, 2, 5, 4, 7, 0, 1],
        },
    },
    {
        a: {
            question: [3, 6, 4, 0, 1, 5, 8, 7, 2],
        },
        b: {
            question: [5, 4, 0, 1, 3, 7, 6, 5, 8],
        },
        c: {
            question: [5, 8, 7, 0, 0, 8, 3, 0, 1],
        },
        d: {
            question: [3, 7, 6, 1, 5, 8, 4, 2, 1],
        },
    },
];

export { CORSI_QUESTIONS };
