import React from 'react';
import { Page, StyleSheet, View, Text } from '@react-pdf/renderer';
import Table from './Table';
import GPTtable from './GPTTABLE';



const styles = StyleSheet.create({
    page: {
        flexDirection: "row",
        backgroundColor: "#E4E4E4",
        
    },
    content: {
      padding: 10,

      '@media max-width: 400': {
        flexDirection: 'column',
      },
      '@media min-width: 400': {
        flexDirection: 'row',
      },

    },
    section: {
        margin: 10,
        padding: 10,
        flexGrow: 1,
    },
    gaugeheader: {
      fontSize: 12,
    },

    view: {
        fontFamily: 'Helvetica',
        width: '250px',
        fontSize: 11,
        paddingTop: 10,
        lineHeight: 2,
        flexDirection: 'column',
        position: 'absolute',
        left: 0,
        top: 0,
    }, 

    headertext:{
        padding: '0',
        fontSize: 14
        
    },

    headerboxtext:{
        padding: '10 12 0 12',
        fontSize: 12
    },

    subheading:{
        padding: '10 12 0 12',
        fontSize: 9
    },

    bodytext: {
        padding: '5 0',
        fontSize:9, 
        lineHeight:1.3,
        },

    bodyboxtext: {
        padding: '5 12',
        fontSize:9, 
        lineHeight:1.3,

    },
});



function InfoPage({data}) {
     const ratingquestions = data.data.rating_block.questions.map((question, index) => {
            return{
                id: index, 
                title: question.question,
                value: question.answer, 
            }
     });
     const half = Math.ceil(ratingquestions.length / 2);   
     const firstHalf = ratingquestions.slice(0, half)
     const secondHalf = ratingquestions.slice(half)
     
    return(
    <Page size="a4" style={{padding:'35px'}}> 
    <View style={{width: '100%', marginBottom: 10, height: 30, backgroundColor: '#ED663B', padding:5}}>
         <Text style={{color:"white", fontSize:14 }}> 
            Elevinformation    
        </Text>
    </View>

        <View style={{padding:'10 0 20 0'}}> 
        <GPTtable data={{ items: [{id: 0, title: "Elevnamn", value: data.data.info_form_block.namn }, {id:1, title:"Födelsedatum", value: data.data.info_form_block.födelsedatum}, {id:2, title:"Årskurs", value: data.data.info_form_block.year === 10 ? "Gymnasiet" : data.data.info_form_block.year}, {id:3, title:"Genomförd ", value:data.data.created_at.slice(0,10)}, {id:4, title:"Administratör", value:data.data.owner_name}]}}/>
        </View> 


        {/* skattningsformulär section */}
        <View style={{width: '100%', marginBottom: 10, height: 30, backgroundColor: '#ED663B', padding:5, }}>
         <Text style={{color:"white", fontSize:14 }}> 
            Metakognitivt skattningsformulär - så lär jag mig i skolan   
        </Text>
    </View>

        <View style={{width: '100%', marginBottom: 0, height: 375, position:'absolute,', top:10}}>
            <View > 
            <GPTtable data={{items: ratingquestions}} />
            </View> 
         </View> 
        
    <View style={{width: '100%', height: 150, position: 'absolute', margin:'0 35 20 35', bottom:35}}>
         <Text style={styles.headertext}> 
            Rekommendationer 
        </Text>     
                <Text style={styles.bodytext}> 
                        {data.data.rating_block.result_text[0]}
                </Text>
                <Text style={styles.bodytext}> 
                        {data.data.rating_block.result_text[1]}
                </Text>
                <Text style={styles.bodytext}> 
                        {data.data.rating_block.result_text[2]}
                </Text>
                <Text style={styles.bodytext}> 
                        {data.data.rating_block.result_text[3]}
                </Text>
    </View>

    </Page>
    )
}

export default InfoPage