import React from "react";
import { Button, Typography } from "@material-ui/core";
import Card from "../common/Card";


class AssessorIntroBlock extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        return (
            <div className="Wrapper-Container">
                <Card>
                    <div>
                        <h1>Om kartläggningen</h1>
                        <Typography variant="body2" component="p" style={{textAlign:"left"}}> 
                        {this.props.text}
                        </Typography>
                        <br/> 

                        
                        <Typography variant="body2" component="p" style={{textAlign:"left", fontWeight:'bold'}}>
                         OBS.Rapporten från kartläggningen kan laddas ned i 30 dagar, därefter raderas den automatiskt.
                        </Typography>
                    </div>
                    <Button
                        onClick={this.props.nextBlock}
                        style={{
                            backgroundColor: "#0F77F2",
                            margin: "25px",
                            color: "#FFFFFF",
                        }}
                        variant="contained"
                    >
                        Fortsätt
                    </Button>


                    {/** Component styling starts here */}
                    {/* <style jsx>{`
                        .introDiv {
                            padding: 0 40px;
                            border: solid 2px lightGray;
                            border-radius: 16px;
                            margin-top: 18px;
                        }
                    `}</style> */}
                </Card>
            </div>
        );
    }
}

export default AssessorIntroBlock;
