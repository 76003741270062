import React from "react";
import Card from "../common/Card";
import IntroVideo from "./IntroVideo";

export default class AssesseeRatingIntroBlock extends React.Component {

    render() {
        return (
            <Card>
                <div>
                    <IntroVideo
                        handleContinue={this.props.nextBlock}
                        video={"https://vimeo.com/598937940/8820e707e3"}
                    />
                </div>
            </Card>
        );
    }
}
