
import React from "react";
import Sidebar from "./Sidebar.js";

import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';


class SupportPage extends React.Component {

    render() {
        return (
            <div>
                <Sidebar />
                <div style={{marginLeft:'200px', marginTop:'58px', fontSize:'small'}}> 
                <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Grid container spacing={3}>
    
              {/* Recent Orders */}
              <Grid item xs={12}>
                <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', height: 750}}>
                <Typography component="h4" variant="h4" color="black" gutterBottom align="left" style={{marginLeft:'20px'}}>
                    Support
                </Typography>
                <br></br>
                {/* <div style={{margin:'20px'}}> 
                </div> */}
                <Typography variant="body1" align="left" style={{marginLeft:'20px'}}> 
                    För att få hjälp eller rapportera en bugg, maila support@digibility.se beskriv ditt problem i detalj. 
                </Typography>
                <br/> 
                <Typography variant="body1" align="left" style={{marginLeft:'20px'}}> 
                    Detta hjälper oss att förbättra plattformen, samt skapa artiklar och instruktionsvideos som bemöter vanliga frågetecken som uppstår i användandet av plattformen. 
                </Typography>
                </Paper>
              </Grid>
            </Grid>
          </Container>
              </div>
            </div>
        );
    }
}
export default SupportPage;
