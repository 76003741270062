import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/Button";

import AssessorIntroBlock from "./blocks/AssessorIntroBlock";
import InfoFormBlock from "./blocks/InfoFormBlock";
import AssesseeIntroBlock from "./blocks/AssesseeIntroBlock";
import AssesseeRatingBlock from "./blocks/ratingBlock/AssesseeRatingBlock";
import AssesseeRatingIntroBlock from "./blocks/AssesseRatingIntroBlock";
import MatrixIntroBlock from "./blocks/matrixBlock/MatrixIntroBlock";
import MatrixBlock from "./blocks/matrixBlock/MatrixBlock";
import OutroBlock from "./blocks/OutroBlock";
import StroopIntroBlock from "./blocks/stroopBlock/StroopIntroBlock";
import StroopBlock from "./blocks/stroopBlock/StroopBlock";
import CorsiBlock from "./blocks/corsiBlock/CorsiBlock";
import CorsiIntroBlock from "./blocks/corsiBlock/CorsiIntroBlock";

import ProgressBar from "./common/ProgressBar";
import SimpleMenu from "./common/SimpleMenu";

import { useSelector, useDispatch } from "react-redux";
import {
    nextBlock,
    prevBlock,
    fetchAssessmentData,
    setBlockTime,
    setSkippedSection
} from "./assessmentSlice";
import { useBeforeunload } from "react-beforeunload";
import { useParams } from "react-router-dom";

function DevTools() {
    const dispatch = useDispatch();
    const current_block = useSelector(
        (state) => state.assessment.current_block
    );

    return (
        <div className="devTools">
            {/* <h2>dev tools</h2> */}
            <p>Current block: {current_block}</p>
            <Button onClick={() => dispatch(prevBlock())}>Prev Block</Button>
            <Button onClick={() => dispatch(nextBlock())}>Next Block</Button>
        </div>
    );
}

export default function Assessment() {
    const styles = {
        containerWrapper: {
            width: '100%',
            maxWidth: '800px',
            margin: 'auto'
        },
        progressNavWrapper: {
            maxWidth: '800px',
            padding: '0',
            margin: 'auto',
            marginTop: '14px',
            marginBottom: '20px',
        },
        progressNav: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            padding: '10px',
            border: 'solid 2px #ededed',
            backgroundColor: 'white',
            alignItems: 'center',
            borderRadius: '40px',
        },
        logo: {
            marginLeft: '16px',
            width: '32px',
            height: '24px',
        }
    };

    const assessmentQAstate = useSelector(
        (state) => state.assessment.assessmentQAstate
    );
    const load_assessment_status = useSelector(
        (state) => state.assessment.load_assessment_status
    );
    const current_block = useSelector(
        (state) => state.assessment.current_block
    );

    const testExtent = useSelector(
        (state) => state.assessment.testExtent
        );



    const is_completed_and_submitted_to_db = useSelector(
        (state) => state.assessment.is_completed_and_submitted_to_db
    );
    const { id } = useParams();
    const dispatch = useDispatch();
    const [assessment_start_time_stamp, Setassessment_start_time_stamp] =
        useState(null);

    useBeforeunload((event) => {
        if (!is_completed_and_submitted_to_db) {
            event.preventDefault();
        }
    });

    useEffect(() => {
        dispatch(fetchAssessmentData(id)); 
    }, []);

    useEffect(() => {
        Setassessment_start_time_stamp();
    }, []);


    useEffect(() => {
        window.history.pushState(null, document.title, window.location.href);
        window.addEventListener('popstate', function (event){
            window.history.pushState(null, document.title,  window.location.href);
        });
    }, []);


    function blockTimer(block, start, stop) {
        let time = (stop - start) / 1000;
        dispatch(setBlockTime({ block, time }));
    }

    function renderSwitch() {
        //Handles what to render based on this.state.current_block

        const { info_form_block, rating_block, matrix_block } =
            assessmentQAstate;
        
        const { year } = assessmentQAstate.info_form_block.year; 

        switch (current_block) {
            case 0:
                if (testExtent === "complete") {
                    return (
                        <AssessorIntroBlock
                            text="Grundläggande dynamisk kartläggning av analogt tänkande, planeringsförmåga, uppmärksamhetsreglering och självskattning. Kartläggningen tar cirka 15 - 25 minuter att genomföra. Den är möjlig att genomföra för elever i årskurs 2 till årskurs 9. Vid kartläggning av yngre elever rekommenderas en högre grad av tillsyn och stöd."
                            nextBlock={() => {
                                dispatch(nextBlock());
                            }}
                        />
                    );
                } else if (testExtent === "matrix") { 
                    return (
                        <AssessorIntroBlock
                        text="Kompletterande kartläggning av analogt tänkande.  Den är möjlig att genomföra för elever i årskurs 2 till årskurs 9. Vid kartläggning av yngre elever rekommenderas en högre grad av tillsyn och stöd."
                        nextBlock={() => {
                                dispatch(nextBlock());
                            }}
                        />  
                    );
                } else if (testExtent === "corsi") {  
                    return (
                        <AssessorIntroBlock
                        text="Kompletterande kartläggning av arbetsminne. Den är möjlig att genomföra för elever i årskurs 2 till årskurs 9. Vid kartläggning av yngre elever rekommenderas en högre grad av tillsyn och stöd."
                            nextBlock={() => {          
                                dispatch(nextBlock());
                            }}  

                        />
                    );
                } else if (testExtent === "stroop") {
                    return (                                                                                                                                                                                
                        <AssessorIntroBlock         
                        text="Kompletterande kartläggning av uppmärksamhetsreglering.  Den är möjlig att genomföra för elever i årskurs 2 till årskurs 9. Vid kartläggning av yngre elever rekommenderas en högre grad av tillsyn och stöd."
                            nextBlock={() => {      
                                dispatch(nextBlock());  
                            }}  
                        />
                    );
                } else {    
                    dispatch(nextBlock());
                }              
            case 1:
                return <InfoFormBlock />;

            case 2:
                return (
                    <AssesseeIntroBlock
                        nextBlock={() => {
                            dispatch(nextBlock());
                        }}
                    />
                );
                    
            case 3:
                if (testExtent === "complete") {
                    return (
                        <AssesseeRatingIntroBlock
                            nextBlock={() => {
                                dispatch(nextBlock());
                            }}
                        />
                    );
                } else { 
                    dispatch(nextBlock());
                }

            case 4:
                if (testExtent === "complete") {
                    return <AssesseeRatingBlock />;
                } else {
                    dispatch(nextBlock());
                    setSkippedSection({block: "rating_block"});
                }
                
            case 5:
                if (testExtent === "complete" || testExtent === "matrix") {
                    return (
                        <MatrixIntroBlock
                            nextBlock={() => {
                                dispatch(nextBlock());
                            }}
                        />
                    );
                    
                } else {
                    dispatch(nextBlock());
                }

            case 6:
                if (testExtent === "complete" || testExtent === "matrix") {
                    return <MatrixBlock blockTimer={blockTimer} />;
                } else {
                    dispatch(setSkippedSection({block: "matrix_block"}));
                    dispatch(nextBlock());
                }
            
            case 7:
                if (testExtent === "complete" || testExtent === "corsi") {
                    return (
                            <CorsiIntroBlock
                                nextBlock={() => {
                                    dispatch(nextBlock());
                                }}
                            />
                        );
                } else {
                    dispatch(nextBlock());
                }

            case 8:
                if (testExtent === "complete" || testExtent === "corsi") {
                    return <CorsiBlock />;
                } else {
                    dispatch(setSkippedSection({block: "corsi_block"}));
                    dispatch(nextBlock());
                }

            case 9:
                if ( 
                    (testExtent === "complete" || testExtent === "stroop") &&
                    assessmentQAstate.info_form_block.year > 1
                ){
                    return (
                        <StroopIntroBlock
                            nextBlock={() => {
                                dispatch(nextBlock());
                            }}
                        />
                    );
                } else{
                    dispatch(nextBlock());
                }

            case 10:
                if (
                    (testExtent === "complete" || testExtent === "stroop") &&
                    assessmentQAstate.info_form_block.year > 1
                    ) {
                    return <StroopBlock blockTimer={blockTimer} />;
                } else {
                    dispatch(setSkippedSection({block: "stroop_block"}));
                    dispatch(nextBlock());
                }

            case 11:
                return <OutroBlock />;

            default:
                break;
        }
    }

    switch (load_assessment_status) {
        case "initial":
            return (
                <div>
                    <CircularProgress />
                </div>
            );

        case "pending":
            return (
                <div>
                    <CircularProgress />
                </div>
            );

        case "rejected":
            return (
                <div>
                    <div>
                        Kontrollera din internetanslutning och försök igen
                    </div>
                    <Button
                        onClick={() => dispatch(fetchAssessmentData(id))}
                        style={{
                            backgroundColor: "#0F77F2",
                        }}
                    >
                        {" "}
                        försök igen
                    </Button>
                </div>
            );

        case "fulfilled":
            return (
                <div>
                       <div style={styles.progressNavWrapper}>
                        <div style={styles.progressNav}>
                            <img className="logo" src='https://digibility.ams3.digitaloceanspaces.com/digibility/media/images/digilogo.webp' style={{width: 95, height: 23.75}}></img>
                            <ProgressBar style={{ verticalAlign: "middle" }} />
                            <div
                                style={{
                                    float: "right",
                                    verticalAlign: "middle",
                                }}
                            ></div>
                            <SimpleMenu
                                style={{
                                    verticalAlign: "middle",

                                    marginTop: "0px",
                                }}
                            />
                        </div>
                    </div>

                    <div style={styles.containerWrapper}>{renderSwitch()}</div>
                    {/* <DevTools /> */}

                    {/* <style jsx>{`
                        .Container-Wrapper {
                            width: 100%;
                            max-width: 800px;
                            margin: auto;
                        }
                        .assessementCard {
                            padding: 0 40px;
                            border: solid 2px lightGray;
                            border-radius: 40px;
                            margin-top: 18px;
                        }
                        .devTools {
                            display: flex;
                            align-items: center;
                            background-color: white;
                            border: solid 1px lightGray;
                            padding: 0 16px 0 20px;
                            position: fixed;
                            background-color: white;
                            border-radius: 14px;

                            bottom: 10px;
                            right: auto;
                            left: auto;
                            z-index: 1200;
                        }
                        .devTools Button {
                            padding: 2px 4px;
                            height: 24px;
                            margin: 0 4px;
                            background-color: lightGray;
                        }
                        .devTools p {
                            margin-right: 6px;
                        }
                        .progressNavWrapper {
                            max-width: 800px;
                            padding: 0;
                            margin: auto;
                            margin-top: 14px;
                            margin-bottom: 20px;
                        }
                        .progressNav {
                            display: flex;
                            flex-direction: row;
                            justify-content: space-between;
                            padding: 10px;
                            border: solid 2px #ededed;
                            background-color: white;
                            align-items: center;
                            border-radius: 40px;
                        }
                        .logo {
                            margin-left: 16px;
                            width: 32px;
                            height: 24px;
                        }
                    `}</style> */}
                </div>
            );

        default:
            break;
    }
}
