import React from "react";
import Sidebar from "./Sidebar.js";
import GetAppIcon from "@material-ui/icons/GetApp";
import { Button, Card, CardActions, CardContent, Typography, withStyles} from '@material-ui/core';
import Container from '@mui/material/Container';



// const useStyles = makeStyles({
//     root: {
//         width: 200,
//         height: 100,
//         position: 'relative', 

//         // minWidth: 275,
//         // maxWidth: 400,
//         // minHeight: 275, 
//         // MaxHeight: 400, 

//     },
//     bullet: {
//         display: "inline-block",
//         margin: "0 2px",
//         transform: "scale(0.8)",
//     },
//     title: {
//         fontSize: 14,
//     },
//     pos: {
//         marginBottom: 12,
//     },

//     gradientBackground: {
//         background: 'hsla(14, 83%, 58%, 1)',
//         background: 'linear-gradient(315deg, hsla(14, 83%, 58%, 1) 5%, hsla(14, 85%, 95%, 1) 76%)',
//         background: '-moz-linear-gradient(315deg, hsla(14, 83%, 58%, 1) 5%, hsla(14, 85%, 95%, 1) 76%)',
//         background: '-webkit-linear-gradient(315deg, hsla(14, 83%, 58%, 1) 5%, hsla(14, 85%, 95%, 1) 76%)',
//         filter: 'progid:DXImageTransform.Microsoft.gradient(startColorstr="#ED663B", endColorstr="#FDECE7", GradientType=1)',
//       },
// });



const styles = {
    root: {
        width: 200,
        height: 100,
        position: 'relative', 
    },
    bullet: {
        display: "inline-block",
        margin: "0 2px",
        transform: "scale(0.8)",
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
    gradientBackground: {
        background: 'linear-gradient(315deg, hsla(14, 83%, 58%, 1) 5%, hsla(14, 85%, 95%, 1) 76%)',
        minHeight: '100vh',
        minWidth: '100vw',
        position: 'fixed',
        top: 0,
        left: 0,
    },

};



class Dashboard extends React.Component {
    // constructor(props) {
    //     super(props);
    // }
    render() {
        const { classes } = this.props;
        return (
            // <div className={classes.gradientBackground}> for gradient background
            <div>
                <Sidebar />
                <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
                <div style={{ marginLeft: '200px', marginTop: '58px', fontSize: 'small' }}>
                    {/* <h1 style={{ textAlign: "left" }}>Manual</h1>
                    <hr /> */}
                    <div style={{ paddingTop: '16px', paddingBottom: '16px', display: 'flex', flexDirection: 'column' }}>
       

            <Card style={{ width: 350,height: 350, position: 'relative'}}>
            <CardContent>
                <Typography variant="h5" component="h2" style={{textAlign:"center"}}>
                    Instruktionsmanual
                </Typography>
                <br/> 
                <Typography variant="body2" component="p" style={{textAlign:"left"}}>
                Här kan du ladda ned instruktionsmanual för Digibility som pdf. 
                    <br />
                </Typography>
            </CardContent>
            <CardActions
                style={{
                    display: "block",
                    marginLeft: "auto",
                    marginRight: "auto",
                }}
            >


                <a href={'https://digibility.ams3.digitaloceanspaces.com/digibility/media/images/Instruktionsmanual_v.1_1.pdf'} download="digibility-manual.pdf" target='_blank'>
                
                    <Button
                        style={{
                            backgroundColor: "#0F77F2",
                            color: "#FFFFFF",
                            position: 'absolute', 
                            bottom:'15px',
                            right: '15px'
                        }}
                        >
                        <GetAppIcon></GetAppIcon>
                        Ladda ner manual 
                    </Button>
                        </a>
            </CardActions>
        </Card>
                     </div>
                </div>
                </Container>
            </div>
        );
    }
}
export default withStyles(styles)(Dashboard);
