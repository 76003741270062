import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { logout } from "../actions/auth";
import Button from "@material-ui/core/Button";
import { Typography } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import FaceIcon from '@material-ui/icons//Face';

export class SidebarFooter extends Component {
    static propTypes = {
        auth: PropTypes.object.isRequired,
        logout: PropTypes.func.isRequired,
    };

    render() {
        const { isAuthenticated, user } = this.props.auth;

        const authLinks = (
            
            <Fragment > 
                <List>
                        <ListItem>
                            <ListItemIcon>
                            <FaceIcon />
                            </ListItemIcon>
                            <ListItemText primary={user ? `${user.name}` : ""} />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                            </ListItemIcon>
                            <ListItemText primary={user ? `${user.school_name}` : ""} />
                        </ListItem>
                        </List>                  
            <br></br>
            <div> 
                    <Button variant="outlined" color="lightgrey" onClick={this.props.logout}>
                    Logga ut 
                  </Button>
            </div>
                  <br></br>
            </Fragment>
        );

        return (
            <div >
                {isAuthenticated ? authLinks : <div> </div>}
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    auth: state.auth,
});

export default connect(mapStateToProps, { logout })(SidebarFooter);
