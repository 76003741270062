import React from "react";

import { Button } from "@material-ui/core";
import Vimeo from "@u-wave/react-vimeo";
import ForwardIcon from "@material-ui/icons/Forward";

export default class CorsiVideo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isEnabled: false,
        };
    }

    render() {
        return (
            <div className="videoWrapper">
                {!this.state.isEnabled ? (
                    <Vimeo
                        video={"https://vimeo.com/598907766/44a0efc266"}
                        onEnd={() => this.setState({ isEnabled: true })}
                        autoplay
                        width={640}
                        height={480}
                    />
                ) : (
                    <div>
                        <div style={{ width: "640px%", height: "480px" }}></div>
                    </div>
                )}

                <Button
                    style={{
                        backgroundColor: this.state.isEnabled
                            ? "#0F77F2"
                            : null,
                        color: this.state.isEnabled ? "#FFFFFF" : null,
                        margin: "50px",
                    }}
                    variant="contained"
                    disabled={!this.state.isEnabled}
                    onClick={this.props.handleContinue}
                    endIcon={<ForwardIcon />}
                >
                    Fortsätt
                </Button>
                <style jsx>
                    {`
                        .videoWrapper {
                            max-width: 100vw;
                        }
                    `}
                </style>
            </div>
        );
    }
}
