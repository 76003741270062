import React from "react";
import {
    FormControl,
    FormLabel,
    FormControlLabel,
    Radio,
    RadioGroup,
    Typography,
} from "@material-ui/core";
import MoodIcon from "@material-ui/icons/Mood";
import MoodBadIcon from "@material-ui/icons/MoodBad";
import VolumeUpIcon from "@material-ui/icons/VolumeUp";

import Card from "./Card";


export default class RatingQuestion extends React.Component {
    constructor(props) {
        super(props);
        let temp = new Audio(this.props.audio);
        this.state = {
            audioplaying: false, 
            audio: temp, 
        }
    }

    componentDidMount() {
        this.state.audio.addEventListener("ended", () =>
          this.setState({ audioplaying: false })
        );
      }

    componentWillUnmount() {
        this.state.audio.removeEventListener("ended", () =>
          this.setState({ audioplaying: false })
        );
      }


    switchPlay = () => {
        this.setState({ audioplaying: !this.state.audioplaying }, () => {
            if(this.state.audioplaying) {
                this.state.audio.play()
            };
        });
      };



    labelLeft() {
        if (this.props.labeltype === "number") {
            return (
                <Typography
                    variant="h6"
                    gutterBottom
                    display="inline"
                    style={{
                        verticalAlign: "middle",
                        marginRight: "20px",
                    }}
                >
                    1
                </Typography>
            );
        } else if (this.props.labeltype === "smiley") {
            return (
                <MoodBadIcon
                    fontSize="medium"
                    style={{
                        display: "inlineBlock",
                        verticalAlign: "middle",
                        marginRight: "20px",
                    }}
                />
            );
        } else return;
    }

    labelRight() {
        if (this.props.labeltype === "number") {
            return (
                <Typography
                    variant="h6"
                    gutterBottom
                    display="inline"
                    style={{
                        verticalAlign: "middle",
                        marginRight: "20px",
                    }}
                >
                    5
                </Typography>
            );
        } else if (this.props.labeltype === "smiley") {
            return (
                <MoodIcon
                    fontSize="medium"
                    style={{
                        display: "inlineBlock",
                        verticalAlign: "middle",
                        marginLeft: "0px",
                    }}
                />
            );
        } else return;
    }

    render() {
        return (
            <div>
                <Card> 
                    <FormControl component="fieldset">
                        <FormLabel component="legend">
                            <h3 style={{ color: "black" }}>
                                {this.props.children}
                            </h3>
                        </FormLabel>

                        <RadioGroup
                            name="rating"
                            value={this.props.value}
                            onChange={this.props.onChange}
                        >
                            <div>
                                <FormLabel></FormLabel>
                                <div> </div>
                                {this.labelLeft("number")}
                                <FormControlLabel
                                    value={"1"}
                                    control={<Radio />}
                                />
                                <FormControlLabel
                                    value={"2"}
                                    control={<Radio />}
                                />
                                <FormControlLabel
                                    value={"3"}
                                    control={<Radio />}
                                />
                                <FormControlLabel
                                    value={"4"}
                                    control={<Radio />}
                                />
                                <FormControlLabel
                                    value={"5"}
                                    control={<Radio />}
                                />
                                {this.labelRight("number")}
                            </div>
                        </RadioGroup>
                    </FormControl>

                    {this.props.audio 
                    ? <VolumeUpIcon fontSize="large" onClick={this.switchPlay} > </VolumeUpIcon>
                    : <div></div>
                }
                </Card>
            </div>
        );
    }
}
