import React from "react";
import { Helmet } from "react-helmet";

import "./App.css";

import Assessment from "./components/assessment/Assessment";
import Dashboard from "./components/layout/Dashboard";
import Alerts from './components/layout/Alerts';

import Register from "./components/acccounts/Register";
import Login from "./components/acccounts/Login";
import ResetPassword from "./components/acccounts/ResetPassword";



import { Route, Switch, BrowserRouter as Router } from "react-router-dom";
// import logotype from "./ArtB.png";
import InsamlingsInstruktioner from "./components/blog/InsamlingsInstruktioner";
import ReportsPage from "./components/layout/ReportsPage";

import ModulerPage from "./components/layout/ModulerPage"

import { loadUser } from "./components/actions/auth";
import { Provider } from "react-redux";
import { Provider as AlertProvider } from 'react-alert';

import store from "./store/store";
import AssessmentsPage from "./components/layout/AssessmentsPage";
import UserDetailsPage from "./components/layout/UserDetailsPage";
import SupportPage from "./components/layout/SupportPage";

import Course from "./components/layout/Course"; 

import AlertTemplate from 'react-alert-template-basic';
import PrivateRoute from "./helpers/PrivateRoute";
import { ConfirmPassword } from "./components/acccounts/ConfirmPassword";

const alertOptions = {
    timeout: 7500,
    position: 'top center',
  };

export default class App extends React.Component {
    componentDidMount() {
        store.dispatch(loadUser());        
    }
    render() {
        return (
            <div>
                <Helmet>
                    <title>Digibility</title>
                    <meta name="description" content="Digibility" />
                </Helmet>
                <Provider store={store}>
                <AlertProvider template={AlertTemplate} {...alertOptions}>
                <Alerts />
                    <div className="App">
                        <Router>
                            <Switch>
                                <PrivateRoute exact path="/" component={Dashboard} />
                                <PrivateRoute
                                    exact
                                    path="/instruktioner/"
                                    component={InsamlingsInstruktioner}
                                />
                                <PrivateRoute
                                    path="/assessments"
                                    component={AssessmentsPage}
                                />
                                <PrivateRoute
                                    path="/assessment/:id"
                                    component={Assessment}
                                />
                                <PrivateRoute
                                    path="/rapporter"
                                    component={ReportsPage}
                                />
                                
                                <PrivateRoute
                                    path="/moduler"
                                    component={ModulerPage}
                                />
                                <PrivateRoute
                                    path="/kurser/:id"
                                    component={Course}
                                />


                                <PrivateRoute
                                    path="/support"
                                    component={SupportPage}
                                />
                                
                                <PrivateRoute
                                    path="/installningar"
                                    component={UserDetailsPage}
                                />


                                <Route
                                    exact
                                    path="/register"
                                    component={Register}
                                />
                                <Route exact path="/login" component={Login} />

                                <Route
                                    exact
                                    path="/reset-password"
                                    component={ResetPassword}
                                />

                                <Route
                                    exact
                                    path="/password-reset-confirm"
                                    component={ConfirmPassword}
                                />

                            </Switch>
                        </Router>
                    </div>
                    </AlertProvider>
                </Provider>
            </div>
        );
    }
}
