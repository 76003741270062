import React from "react";
import { Button } from "@material-ui/core";
import RatingQuestion from "../../common/RatingQuestion";
import ForwardIcon from "@material-ui/icons/Forward";


export default class RatingQuestionList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            index: 0,
        };

        this.handleContinue = this.handleContinue.bind(this);
    }

    handleChange = (i) => (e) => {
        this.props.handleChange(i)(e);
    };

    handleContinue() {
        if (this.state.index + this.props.n >= this.props.questions.length) {
            //Continue to next block
            this.props.nextBlock();
        } else {
            //Increase index from which to slice
            this.setState((s, p) => {
                return { index: s.index + p.n };
            });
        }
    }

    isDisabled() {
        let qas = this.props.questions.slice(
            this.state.index,
            this.state.index + this.props.n
        );
        return qas.some((question) => {
            return (question.answer === null) | (question.answer === "");
        });
    }

    button() {
        if (this.props.button) {
            return (
                <Button
                    onClick={this.handleContinue}
                    disabled={this.isDisabled()}
                    style={{
                        backgroundColor: !this.isDisabled()
                            ? "#0F77F2"
                            : "#d7d8d9",
                        color: !this.isDisabled() ? "#FFFFFF" : "primary",
                        margin: "50px",
                    }}
                    endIcon={<ForwardIcon />}
                >
                    Fortsätt
                </Button>
            );
        }
    }

    render() {
        let list = this.props.questions.map((e, i) => (
            <RatingQuestion
                key={i}
                audio={e.audio}
                value={e.answer}
                onChange={this.handleChange(i)}
                labeltype={this.props.labeltype}
            >
                {e.question}
            </RatingQuestion>
        ));

        if (this.props.slice) {
            list = list.slice(
                this.state.index,
                this.state.index + this.props.n
            );
        }

        return (
            <div>
                {list}
                {this.button()}
            </div>
        );
    }
}
