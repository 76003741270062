
import React from "react";
import Sidebar from "./Sidebar.js";
import axiosInstance from "../../helpers/axios"
import ReportsTable from "./ReportTable";

import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';


class ReportsPage extends React.Component {
        constructor(props) {
        super(props);
        this.state = {
            data: null,
            isLoading: false,
        };
    }

    componentDidMount() {
        this.setState({ isLoading: true });
        const token = localStorage.getItem("token");

        const config = {
            headers: { Authorization: `Token ${token}` }
        };
        
        axiosInstance
            .get(`rapporter/`, 
            config           
            )
            .then((response) => {
                for (let student in response.data) {
                    response.data[student]["info_form_block"] = JSON.parse(
                        response.data[student]["info_form_block"]
                    );
                }
                this.setState({ data: response.data, isLoading: false });
            })
            .catch((err) => {
                console.log(err);
            });
    }

    render() {
        return (
            <div>
                <Sidebar />
                <div style={{marginLeft:'200px', marginTop:'58px', fontSize:'small'}}> 
                <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Grid container spacing={3}>
    
              {/* Recent Orders */}
              <Grid item xs={12}>
                <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', height: 750}}>
                <Typography component="h4" variant="h4" color="black" gutterBottom align="left" style={{marginLeft:'20px'}}>
                    Rapporter
                </Typography>
                {/* <div style={{margin:'20px'}}> 
                </div> */}
      
                <br/> 
                <div style={{ margin: '20px' }}>
                                    {this.state.data ? (
                                        <ReportsTable data={this.state.data} />
                                    ) : (
                                        <div>Inga rapporter tillgängliga</div>
                                    )}
                                </div> 
                </Paper>
              </Grid>
            </Grid>
          </Container>
              </div>
            </div>
        );
    }
}
export default ReportsPage;
