// import axios from "axios";
import axiosInstance from "../../helpers/axios";
import { returnErrors } from "./messages";

import {
    USER_LOADED,
    USER_LOADING,
    AUTH_ERROR,
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    LOGOUT_SUCCESS,
    REGISTER_SUCCESS,
    REGISTER_FAIL,
} from "./types";

// CHECK TOKEN & LOAD USER
export const loadUser = () => (dispatch, getState) => {
    // User Loading
    dispatch({ type: USER_LOADING });

    axiosInstance
        .get("auth/user/", tokenConfig(getState))
        .then((res) => {
            dispatch({
                type: USER_LOADED,
                payload: res.data,
            });
        })
        .catch((err) => {
            dispatch(returnErrors(err.response.data, err.response.status));
            console.log("err:", err);
            dispatch({
                type: AUTH_ERROR,
            });
        });
};

// LOGIN USER
export const login = (email, password) => (dispatch) => {
    // Headers
    const config = {
        headers: {
            "Content-Type": "application/json",
        },
    };

    // Request Body
    const body = JSON.stringify({ email, password });

    axiosInstance
        .post("auth/login/", body, config)
        .then((res) => {
            dispatch({
                type: LOGIN_SUCCESS,
                payload: res.data,
            });
        })
        .catch((err) => {
            dispatch(returnErrors(err.response.data, err.response.status));
            dispatch({
                type: LOGIN_FAIL,
            });
        });
};

// REGISTER USER
export const register =
    ({ school_key, name, role, password, email }) =>
    (dispatch) => {
        // Headers
        const config = {
            headers: {
                "Content-Type": "application/json",
            },
        };

        // Request Body
        const body = JSON.stringify({ school_key, name, role, email, password });

        axiosInstance
            .post("auth/register/", body, config)
            .then((res) => {
                dispatch({
                    type: REGISTER_SUCCESS,
                    payload: res.data,
                });
            })
            .catch((err) => {
                dispatch(returnErrors(err.response.data, err.response.status));
                dispatch({
                    type: REGISTER_FAIL,
                });
            });
    };

// LOGOUT USER
export const logout = () => (dispatch, getState) => {
    axiosInstance
        .post("auth/logout/", null, tokenConfig(getState))
        .then((res) => {
            dispatch({ type: "CLEAR_LEADS" });
            dispatch({
                type: LOGOUT_SUCCESS,
            });
        })
        .catch((err) => {
            // dispatch(returnErrors(err.response.data, err.response.status));
        });
};

// Setup config with token - helper function
export const tokenConfig = (getState) => {
    // Get token from state
    const token = getState().auth.token;

    // Headers
    const config = {
        headers: {
            "Content-Type": "application/json",
        },
    };

    // If token, add to headers config
    if (token) {
        config.headers["Authorization"] = `Token ${token}`;
    }

    return config;
};
