import React from "react";
import Sidebar from "./Sidebar";

import { makeStyles, withStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from '@mui/material/CardMedia';

// import CardMedia from '@mui/material/CardMedia';

import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { Link } from "react-router-dom";
import Grid from '@mui/material/Grid';

import { useDispatch, connect } from "react-redux";
import { setTestExtent } from "../assessment/assessmentSlice";
import { G } from "@react-pdf/renderer";


const useStyles = makeStyles({
    root: {
        width: 350,
        height: 350,
        position: 'relative', 
        marginLeft: '50px', 
        marginBottom: '50px',
        transition: "0.3s",
        "&:hover": {
          transform: "scale(1.02)",
          boxShadow: "0 6px 18px 0 rgba(0,0,0,0.2)",
            },  
    

        // minWidth: 275,
        // maxWidth: 400,
        // minHeight: 275, 
        // MaxHeight: 400, 

    },
    bullet: {
        display: "inline-block",
        margin: "0 2px",
        transform: "scale(0.8)",
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
});

const styles = {
  gradientBackground: {
    background: 'linear-gradient(315deg, hsla(14, 83%, 58%, 1) 5%, hsla(14, 85%, 95%, 1) 76%)',
    minHeight: '100vh',
    minWidth: '100vw',
    position: 'fixed',
    top: 0,
    left: 0,
  }
  // Add your other styles...
};


// function SimpleCard() {
//     const classes = useStyles();

//     return (
//         <Card className={classes.root}>
//             <CardContent>
//                 <Typography variant="h5" component="h2" style={{textAlign:"center"}}>
//                     Dynamisk kartläggning
//                 </Typography>
//                 <br/> 
//                 <Typography variant="body2" component="p" style={{textAlign:"left"}}>
//                 Dynamisk kartläggning med metakognitiv självskattning, analogt tänkande med strategistöd, metakognitivt arbetsminne samt uppmärksamhetsreglering. 
//                     <br />
//                     <br />
//                     -Tid att genomföra cirka 15 - 25 minuter. 
//                     <br />
//                     -För elever mellan årskurs 2 till 9. 
//                 </Typography>
//             </CardContent>
//             <CardActions
//                 style={{
//                     display: "block",
//                     marginLeft: "auto",
//                     marginRight: "auto",
//                 }}
//             >
//                 <Link to="/assessment/1" style={{ textDecoration: "none" }}>
//                     <Button
//                         style={{
//                             backgroundColor: "#0F77F2",
//                             color: "#FFFFFF",
//                             position: 'absolute', 
//                             bottom:'15px',
//                             right: '15px'
//                         }}
//                     >
//                         Starta Kartläggning
//                     </Button>
//                 </Link>
//             </CardActions>
//         </Card>
//     );
// }


function MediaCard(props) {
    const { title, image, description } = props;
    const dispatch = useDispatch();

    const classes = useStyles();

    return (
        <Card className={classes.root}>
          <Typography gutterBottom variant="h5" component="div" style={{marginTop:'10px', fontWeight: 'bold'}}>
            {title}
          </Typography>
        <CardMedia
          sx={{ height: 240}}
          image={image}
          title="Digibility logo"
        />
        <CardContent>
          <Typography variant="body2" color="textSecondary">
            {description}
        
                    </Typography>
        </CardContent>
        <CardActions>
            </CardActions>
      </Card>
    );
  }

//   onClick={() => dispatch(setTestExtent("complete"))}


  class AssessmentsPage extends React.Component {
    // constructor(props) {
    //     super(props);
    // }
    //constructor(props) {
    //    super(props);

    handleOnClick = (testExtent) => {
        this.props.dispatch(setTestExtent(testExtent));
      }

    render() {
        const { classes } = this.props;
        return (
          <div>
          <Sidebar />
            <div style={{ marginLeft: '350px', marginTop:'60px'}}>
              <Typography component="h4" variant="h4" style={{ textAlign: "left", fontWeight: '550', marginBottom: '50px' }}>Fullständig dynamisk kartläggning</Typography>
              <Grid container spacing={4}>
            <Link to="/assessment/1" onClick={() => this.handleOnClick("complete")} style={{ textDecoration: "none" }}>    
              <MediaCard 
                       title="Dynamisk kartläggning"
                       image="https://digibility.ams3.digitaloceanspaces.com/digibility/media/images/all_assessments_illu.png"
                       description="Fullständig kartläggning med samtliga av de fyra delarna, för årskurs 2-9. ~20 minuter."
                       link="/assessment/1"/>   
            </Link>
            </Grid>
            <hr></hr>
            <br/>
            <div> 
            <Typography component="h5" variant="h5" style={{ textAlign: "left", fontWeight: '550', marginBottom: '10px' }}>Kompletterande deltest</Typography>
            <Typography component="h6" variant="h6" style={{ textAlign: "left", fontWeight: '300', marginBottom: '40px' }}>När ett eller flera av delarna i kartläggningen behövde skippas eller avbrytas.</Typography>
            </div>
            <br></br>
            <Grid container spacing={4}>
            <Link to="/assessment/1" onClick={() => this.handleOnClick("matrix")} style={{ textDecoration: "none" }}>    
            <MediaCard 
                       title="Analogt tänkande"
                       image="https://digibility.ams3.digitaloceanspaces.com/digibility/media/images/analogue_illu.png"
                       description="Endast deltestet analogt tänkande. ~5 minuter."
                       link="/assessment/1"/>   
            </Link>
            
            <Link to="/assessment/1" onClick={() => this.handleOnClick("corsi")} style={{ textDecoration: "none" }}>                
            <MediaCard 
                       title="Arbetsminne"
                       image="https://digibility.ams3.digitaloceanspaces.com/digibility/media/images/arbetsminne_illu.png"
                       description="Endast deltestet metakognitivt arbetsminne.  ~5 minuter."
                       link="/assessment/1"/>   
            </Link>
            
            <Link to="/assessment/1" onClick={() => this.handleOnClick("stroop")} style={{ textDecoration: "none" }}> 
            <MediaCard 
                       title="Uppmärksamhetsreglering"
                       image="https://digibility.ams3.digitaloceanspaces.com/digibility/media/images/attentionregulation_illu.png"
                       description="Endast deltestet metakognitiv uppmärksamhetsreglering. ~5 minuter."
                       link="/assessment/1"/>   
            </Link> 
              </Grid>
            
            </div>
          </div>
        );
    }
}


export default connect()(withStyles(styles)(AssessmentsPage));
