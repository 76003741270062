import React from "react";
import {Button, Typography} from "@material-ui/core";
import Card from "../common/Card";

export default class AssesseeIntroBlock extends React.Component {

    render() {
        return (
            <Card>
                <div>

                <div>
                        <h2>Påbörja första uppgiften</h2>
                        <Typography variant="body2" component="p" style={{textAlign:"left"}}> 
                        Nu börjar kartläggningen. Förbered genom att sätta eleven framför skärmen, och klicka sedan “Fortsätt”
                        </Typography>
                        <br/> 
                    </div>

                    <Button
                        onClick={this.props.nextBlock}
                        style={{
                            backgroundColor: "#0F77F2",
                            margin: "36px 0 0 0",
                            color: "#FFFFFF",
                        }}
                    >
                        Fortsätt
                    </Button>
                </div>
            </Card>
        );
    }
}