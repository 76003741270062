import React from "react";
import { nextBlock, setSkippedSection } from "../assessmentSlice";
import { useSelector, useDispatch } from "react-redux";

import Button from "@material-ui/core/Button";
import SettingsIcon from "@material-ui/icons/Settings";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { Snackbar } from "@material-ui/core";

import { Dialog, DialogTitle, DialogContentText, DialogActions, DialogContent } from '@mui/material';

export default function SimpleMenu() {
    const [openSnack, setOpenSnack] = React.useState(false);

    const [openDialog, setOpenDialog] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
  
    const [openDialogEnd, setOpenDialogEnd] = React.useState(false);

    const [anchorElEnd, setAnchorElEnd] = React.useState(null);
    
   
    function refreshPage() {
        window.location = process.env.REACT_APP_URL + 'assessments/'
    }

    const dispatch = useDispatch();

    const year = useSelector(
        (state) => state.assessment.assessmentQAstate.info_form_block.year 
    );

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const handleCloseMenuEnd = () => {
        setAnchorElEnd(null); 
    }

    const current_block = useSelector(
        (state) => state.assessment.current_block
    );

    const onSkip = () => {
        if (year === "" && current_block === 1){
            setOpenSnack(true);
            handleCloseMenu();

        } else if (current_block === 1) {
            dispatch(nextBlock());
            handleCloseMenu();
        } else{
            setOpenDialog(true);  
        }
    };

    const onEnd = () =>{
        setOpenDialogEnd(true); 
    }

    const onSkipDialog = () => {

        if (current_block === 4){
            dispatch(setSkippedSection({
                block: "rating_block"
            }))
        } else if (current_block === 6){
            dispatch(setSkippedSection({
                block: "matrix_block"
            }))

        } else if (current_block === 8){
            dispatch(setSkippedSection({
                block: "corsi_block"
            }))
        } else if (current_block === 10){
            dispatch(setSkippedSection({
                block: "stroop_block"
            }))
        }
        dispatch(nextBlock())
        handleCloseDialog();
        handleCloseMenu();
    }; 

    const onEndDialog = () =>{
        refreshPage();
        handleCloseEndDialog();
        handleCloseMenuEnd();
    } 

    const handleCloseSnack = (event, reason) => {
        if (reason === 'clickaway') {
        return;
        }

        setOpenSnack(false);
    };

    const handleCloseDialog = (event, reason) => {
        if (reason === 'clickaway') {
        return;
        }
        setOpenDialog(false);
        handleCloseMenu();
    };

    const handleCloseEndDialog = (event, reason) => {
        setOpenDialogEnd(false); 
        handleCloseMenuEnd(); 
    }


    return (
        <div>
            <Button
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={handleClick}
            >
                <SettingsIcon />
            </Button>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleCloseMenu}
            >
                <MenuItem onClick={onSkip}>
                    Hoppa över sektion 
                </MenuItem>

                <MenuItem onClick={onEnd}>
                    Avsluta kartläggning 
                </MenuItem>

                <MenuItem onClick={handleCloseMenu}>Stäng meny</MenuItem>
            </Menu>
            <Snackbar
                sx={{ height: "100%"}}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "center"
                }}
                open={openSnack}
                autoHideDuration={4000}
                onClose={handleCloseSnack}
                message="Fyll i namn och årskurs"
                />

            {/* warning before skipping test section */}
           <Dialog
                disableEnforceFocus
                open={openDialog}
                onClose={handleCloseDialog}
                aria-labelledby="draggable-dialog-title"
            >
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          Skippa sektion
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Är du säker på att du vill skippa sektionen? Om du skippar sektionen så kan inget resultat rapporteras på det särskilda deluppgiften.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus style={{color:'green', float:'left'}} onClick={handleCloseDialog}>
            Återuppta 
          </Button>
          <Button style={{color:'red'}} onClick={onSkipDialog}>Skippa</Button>
        </DialogActions>
      </Dialog>



        {/* Dialog for ending assessment */}

             <Dialog
                disableEnforceFocus
                open={openDialogEnd}
                onClose={handleCloseEndDialog}
                aria-labelledby="draggable-dialog-title"
            >
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          Avsluta kartläggning
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Är du säker på att du vill avsluta pågående kartläggning? Hittills besvarade sektioner kommer försvinna. 
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus style={{color:'green', float:'left'}} onClick={handleCloseEndDialog}>
            Återuppta
          </Button>
          <Button style={{color:'red'}} onClick={onEndDialog}>Avsluta</Button>
        </DialogActions>
      </Dialog>
      
        </div>
    );
}
