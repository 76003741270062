import React, { useEffect, useState } from "react";
import StroopTest from "./StroopTest";
import Card from "../../common/Card";

// refactor
import /* all functions */ "../../assessmentSlice";

import { useDispatch } from "react-redux";
import { submitStroopBlockAnswer, nextBlock } from "../../assessmentSlice";

//               0       1       2      3
const words = ["GRÖN", "RÖD", "BLÅ", "GUL"];
const colors = ["009900", "990000", "000099", "E6E600"];
const combos = [
    { word: 1, color: 1 },
    { word: 0, color: 0 },
    { word: 1, color: 3 },
    { word: 2, color: 2 },
    { word: 3, color: 3 },
    { word: 0, color: 1 },
    { word: 1, color: 1 },
    { word: 2, color: 3 },
    { word: 3, color: 0 },
    { word: 0, color: 0 },
    { word: 1, color: 2 },
    { word: 0, color: 2 },
    { word: 2, color: 2 },
    { word: 3, color: 3 },
    { word: 2, color: 1 },
    { word: 1, color: 1 },
    { word: 0, color: 0 },
    { word: 3, color: 0 },
    { word: 2, color: 2 },
    { word: 1, color: 3 },
    { word: 0, color: 2 },
    { word: 3, color: 3 },
    { word: 2, color: 0 },
    { word: 3, color: 1 },
];

function get_current_timestamp() {
    const date = new Date();
    return date.getTime();
}

function StroopBlock(props) {
    const [started_block, setStarted_block] = useState(null);

    const dispatch = useDispatch();

    useEffect(() => {
        let isMounted = true;
        if (isMounted) setStarted_block(get_current_timestamp());
        return () => {
            isMounted = false;
        };
    }, []);

    function handleComplete({
        events,
        start,
        stop,
        timeLimit,
        timeLimitReached,
    }) {
        // generating meta-data for stroopBlock, score and item_count
        let finished_block = get_current_timestamp();
        let score = 0;
        let item_count = 1;
        for (let i = 0; i < events.length; i++) {
            item_count += 1;
            if (events[i].isCorrect === true) {
                score += 1;
            }
        }
        dispatch(
            submitStroopBlockAnswer({
                events,
                item_count,
                score,
            })
        );
        props.blockTimer("stroop_block", started_block, finished_block);
        dispatch(nextBlock());
    }

    return (
        <Card>
            <div className="stroop">
                <StroopTest
                    colors={colors}
                    combos={combos}
                    onComplete={handleComplete} // wrong? onComplete?
                    // onError = {this.handleError} // wrong? -||-
                    // onSuccess = {this.handleSuccess} // wrong? -||-
                    words={words}
                />
                <style jsx>{`
                    .stroop {
                        margin-top: 24px;
                    }
                `}</style>
            </div>
        </Card>
    );
}

export default StroopBlock;
