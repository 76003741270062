import React from "react";

export default ({ children }) => (
    <div className="standardCard">
        {children}

        <style jsx>{`
            .standardCard {
                padding: 36px 40px;
                border: solid 2px #ededed;
                border-radius: 16px;
                margin-top: 18px;
                box-shadow: 0px 4px 16px 8px rgba(0, 0, 0, 0.03);
            }
            .standardCard h2 {
                margin-top: 10px;
            }
        `}</style>
    </div>
);


