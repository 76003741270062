import React from "react";
import {
    Box,
    FormControl,
    InputLabel,
    TextField,
    FormGroup,
    Button,
    Select,
    MenuItem,
    
} from "@material-ui/core";
import { submitInfoFormBlockAnswer, nextBlock } from "../assessmentSlice";
import Card from "../common/Card";
import { useSelector, useDispatch } from "react-redux";

export default function InfoFormBlock(props) {
    const dispatch = useDispatch();
    const questions = useSelector(
        (state) => state.assessment.assessmentQAstate.info_form_block
    );

    const handleChange =
        (field) =>
        (index = undefined) =>
        (e) => {
            dispatch(
                submitInfoFormBlockAnswer({
                    field,
                    answer: e.target.value,
                    index,
                })
            );
        };

    function render() {
        let {
            namn,
            födelsedatum,
            year,
            // behov_av_extraanpassning,
        } = questions;
        // let isAtLeastOneNullÄmne = skattning_ämne.some(function (question) {
        //     return question.a === null;
        // });
        // let isAtLeastOneNullLäs = skattning_läs_och_skriv.some(function (
        //     question
        // ) {
        //     return question.a === null;
        // });

        let isEnabled =
            namn.length > 0 &&
            year !== ""
        return (
            <div>
                <Card>
                    <FormGroup row > 
                        <TextField
                            style={{ margin: "10px", width:"30%"  }}
                            label="Namn (elev)"
                            variant="outlined"
                            value={questions.namn}
                            required
                            onChange={handleChange("namn")()}
                            color="primary"
                            inputProps={{ style: { color: "black" } }}
                        />
                        <TextField
                            style={{ margin: "10px", width:"30%" }}
                            label="födelsedatum"
                            type="date"
                            variant="outlined"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={questions.födelsedatum}
                            onChange={handleChange("födelsedatum")()}
                        />
                        <Box sx={{ m: 1, minWidth: 120 }} style={{ margin: "10px", width:"30%" }}>
                            <FormControl fullWidth variant="outlined">
                                <InputLabel id="year" inputlabelprops={{ shrink: true }}>
                                    Årskurs * 
                                    </InputLabel>
                                <Select
                                    labelId="year"
                                    value={questions.year}
                                    onChange={handleChange("year")()}>

                                    <MenuItem value={2}>2</MenuItem>
                                    <MenuItem value={3}>3</MenuItem>
                                    <MenuItem value={4}>4</MenuItem>
                                    <MenuItem value={5}>5</MenuItem>
                                    <MenuItem value={6}>6</MenuItem>
                                    <MenuItem value={7}>7</MenuItem>
                                    <MenuItem value={8}>8</MenuItem>
                                    <MenuItem value={9}>9</MenuItem> 
                                    <MenuItem value={10}>Gymnasium</MenuItem> 

                                </Select>
                            </FormControl>
                        </Box>
                    </FormGroup>
                </Card>


                {/* <RatingQuestionList
                    questions={questions.skattning_ämne}
                    handleChange={handleChange("skattning_ämne")}
                    labeltype={"number"}
                />
                <RatingQuestionList
                    questions={questions.skattning_läs_och_skriv}
                    handleChange={handleChange("skattning_läs_och_skriv")}
                    labeltype={"number"}
                /> */}
                <Card style={{ backgroundColor: "#FFFFFF" }}>
                <FormGroup row > 
                    {/* <div>
                        Behov av extra anpassning:
                        <Select
                            value={questions.behov_av_extraanpassning}
                            onChange={handleChange(
                                "behov_av_extraanpassning"
                                )()}
                                >
                            <MenuItem value={"Har"}>Har</MenuItem>
                            <MenuItem value={"Utreds för behov"}>
                                Utreds för behov
                            </MenuItem>
                            <MenuItem value={"Nej"}>Nej</MenuItem>
                        </Select>
                    </div> */}
                                            <Box sx={{ m: 1, minWidth: 120 }} style={{ margin: "10px", width:"30%" }}>
                            <FormControl fullWidth variant="outlined">
                                <InputLabel id="kön" inputlabelprops={{ shrink: true }}>
                                    Kön
                                    </InputLabel>
                                <Select
                                    labelId="kön"
                                    onChange={handleChange("kön")()}>

                                    <MenuItem value={"Pojke"}>Pojke</MenuItem>
                                    <MenuItem value={"Flicka"}>Flicka</MenuItem>
                                    <MenuItem value={"Annan"}>Annan</MenuItem>
                
                                </Select>
                            </FormControl>
                        </Box>

                    <Box sx={{ m: 1, minWidth: 120 }} style={{ margin: "10px", width:"30%" }}>
                            <FormControl fullWidth variant="outlined">
                                <InputLabel id="year" inputlabelprops={{ shrink: true }}>
                                    Behov av extra anpassning
                                    </InputLabel>
                                <Select
                                    labelId="year"
                                    onChange={handleChange("behov_av_extraanpassning")()}>

                                    <MenuItem value={"Har extra anpassning"}>Har extra anpassning</MenuItem>
                                    <MenuItem value={"Kartläggs för behov av extra anpassning"}>Kartläggs för behov av extra anpassning</MenuItem>
                                    <MenuItem value={"Har inte behov av extra anpassning"}>Har inte behov av extra anpassning</MenuItem>
                                </Select>
                            </FormControl>
                        </Box>


                    <Box sx={{ m: 1, minWidth: 120 }} style={{ margin: "10px", width:"30%" }}>
                            <FormControl fullWidth variant="outlined">
                                <InputLabel id="year" inputlabelprops={{ shrink: true }}>
                                    Behov av särskilt stöd
                                    </InputLabel>
                                <Select
                                    labelId="year"
                                    onChange={handleChange("behov_av_särskilt_stöd")()}>
                                    <MenuItem value={"Har särskilt stöd"}>Har särskilt stöd</MenuItem>
                                    <MenuItem value={"Utreds för särskilt stöd"}>Utreds för särskilt stöd</MenuItem>
                                    <MenuItem value={"Har inte särskilt stöd"}>Har inte särskilt stöd</MenuItem>
        
                                </Select>
                            </FormControl>
                        </Box>

                    </FormGroup>
                    <div>
                        <TextField
                            label="Valfri övrig kommentar"
                            variant="outlined"
                            fullWidth
                            value={questions.kommentar}
                            onChange={handleChange("kommentar")()}
                            style={{ width: "90%", margin: "5%" }}
                        />
                    </div>
                </Card>
                <Button
                    style={{
                        backgroundColor: isEnabled ? "#0F77F2" : "#d7d8d9",
                        color: isEnabled ? "#FFFFFF" : "primary",
                        margin: "50px",
                    }}
                    variant="contained"
                    disabled={!isEnabled}
                    onClick={() => dispatch(nextBlock())}
                >
                    {" "}
                    Forsätt{" "}
                </Button>
            </div>
        );
    }

    return render();
}
