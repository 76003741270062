
import React from 'react';
import { Page, View, Text } from '@react-pdf/renderer';
import GPTtable from './GPTTABLE';


const SubtestPage = ({data})  => (
    <Page size="a4" style={{padding:60}}>

    <View style={{width: '100%', marginBottom: 10, height: 30, backgroundColor: '#ED663B', padding:5}}>
         <Text style={{color:"white", fontSize:14 }}> 
            Elevinformation    
        </Text>
    </View>

   
        <View style={{padding:'10 0 20 0'}}> 
            <GPTtable data={{ items: [{id: 0, title: "Elevnamn", value: data.data.info_form_block.namn }, {id:1, title:"Födelsedatum", value: data.data.info_form_block.födelsedatum}, {id:2, title:"Årskurs", value: data.data.info_form_block.year === 10 ? "Gymnasiet" : data.data.info_form_block.year}, {id:3, title:"Genomförd ", value:data.data.created_at.slice(0,10)}, {id:4, title:"Administratör", value:data.data.owner_name}]}}/>
        </View> 

        <View style={{width: '100%', marginBottom: 10, height: 30, backgroundColor: '#ED663B', padding:5}}>
        <Text style={{color:"white", fontSize:14 }}> 
         Om rapporten
        </Text>
     </View>

        <View style={{width: '100%', marginBottom: 30, borderRadius: 5, height: 600, backgroundColor: 'white', padding:20}}>
           
           {data.data.test_extent === "matrix" ? (
            <View> 
            <Text style={{fontSize:10, paddingTop:10}}> 
            Denna rapport är för deltestet ”Tänk ut figuren” – Analogt tänkande med strategistöd. 
            </Text>
           
           <Text style={{fontSize:10, paddingTop:10}}> 
            I Analogt tänkande med strategistöd löser eleven problem som kräver analogt tänkande. Analogt tänkande innebär att eleven utifrån identifierade likheter och skillnader i visuell information tänker relationellt kring samband och mönster i informationen, för resonemang och drar slutsatser. Efter att eleven når sin nivå för problemlösningen får eleven en explicit modellande instruktion för att stödja elevens strategier. Därefter får eleven genomföra ytterligare uppgifter som börjar från elevens taknivå. Resultatet redovisas i form av ett sammantaget resultat innan strategistöd, samt ett resultat efter strategistöd.        
            </Text>
            </View>
            ): null}

            {data.data.test_extent === "corsi" ? (
            <View> 
                <Text style={{fontSize:10, paddingTop:10}}> 
            Denna rapport är för deltestet ”Baklängesminne” – Metakognitivt arbetsminne.
            </Text>

            <Text style={{fontSize:10, paddingTop:10}}> 
            I Metakognitivt arbetsminne presenteras eleven visuella stimuli i successiv ordning som kräver stegvis längre uppmärksamhetspann. Därefter ska eleven återge ordningen baklänges. Efter att eleven når sin självständiga taknivå får eleven stöd med exempel på två explicita minnesstrategier. Därefter får eleven genomföra ytterligare uppgifter som börjar från elevens taknivå. Resultatet redovisas i form av ett sammantaget resultat innan strategistöd, samt ett resultat efter strategistöd.       
            </Text>

            </View> 
           ): null}

            {data.data.test_extent === "stroop" ? (
            <View> 

            <Text style={{fontSize:10, paddingTop:10}}> 
            Denna rapport är för deltestet ”Håll koll på färgen” – Uppmärksamhetsreglering. 
            </Text>
            <Text style={{fontSize:10, paddingTop:10}}> 
            I Uppmärksamhetsreglering presenteras eleven ett ord på en färg, skrivet med en färg som kan vara samma färg (kongruent uppgift) eller i en annan färg än ordets innebörd (inkongruent uppgift). Regeln för uppgiften är att eleven ska peka på den färg ordet är skrivet med, inte den färg som står skriven i ordet. När eleven genomför uppgiften ska eleven hålla regeln i huvudet och samtidigt uppmärksamma rätt egenskap i de ord som presenteras, utan att bli distraherad av eller agera impulsivt utifrån. Resultatet redovisas i form av ett sammantaget resultat som utgör skillnaden mellan kongruenta och inkongruenta uppgifter.        

            </Text>
            </View> 
           ): null}

            <Text style={{fontSize:10, paddingTop:10}}> 
            Deltestet rapporteras med resultat och förslag till analys och rekommendation. De sammantagna resultatet bygger på en normerad jämförelse med årskursvisa grupper.
            </Text>


            <Text style={{fontSize:10, paddingTop:10}}> 
            Ett resultat måste alltid tolkas i sitt sammanhang utifrån den enskilda elevens förutsättningar. Det finns en rad faktorer som kan påverka resultatet så att det inte är rättvisande. I analysen är det viktigt att väga resultatet mot andra underlag och informationskällor. Det är en fördel att samtala med eleven om uppgifterna efteråt, vilka som upplevdes lätta, vilka som var utmanande för att få en kvalitativ förståelse för resultatet. På detta sätt kan man tillsammans identifiera ledtrådar som bidrar med en fördjupad förståelse för elevens lärande och behov i skolan inom olika områden, ämnen, läromedel och undervisning.      
            </Text>
        </View>
    </Page> 
);

export default SubtestPage