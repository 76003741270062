import * as React from 'react';
import {
    Document,
    Page,
    Text,
    View,
    StyleSheet,
    Font,
  } from '@react-pdf/renderer';




// GPTSTYLES
const styles = StyleSheet.create({
    // not used since it returns a view and not document in current implementation
    page: {
      flexDirection: 'column',
      backgroundColor: '#fff',
      padding: '1in',
    },
    table: {
      display: 'table',
      width: '100%',
      borderStyle: 'solid',
      borderWidth: 1,
      borderRightWidth: 0,
      borderBottomWidth: 0,
    },
    tableHeader: {
      flexDirection: 'row',
      backgroundColor: '#898989',
      borderBottomWidth: 1, // Add a bottom border
      borderBottomColor: 'black', // Set the border color
      borderBottomStyle: 'solid', // Set the border style
  
    },
    tableRow: {
      flexDirection: 'row',
      backgroundColor: 'white',
    },
    tableRowGrey: {
      flexDirection: 'row',
      backgroundColor: '#ebebeb',
    },
    tableCol: {
      flexGrow: 1,
      borderStyle: 'solid',
      borderWidth: 1,
      borderLeftWidth: 0,
      borderTopWidth: 0,
      padding: 5,
    },
    tableHeaderCol: {
      flexGrow: 1,
      borderStyle: 'solid',
      borderWidth: 1,
      borderLeftWidth: 0,
      borderTopWidth: 0,
      borderBottomWidth: 0,
      padding: 5,
      minHeight: 24, 
    },
    tableCell: {
      fontSize: 8,
    },
    tableHeaderCell: {
      fontSize: 8,
      fontWeight: 'bold',
      color: '#fff',
    },
  
  
    tableColLeft: {
      width: '80%',
      borderStyle: 'solid',
      borderWidth: 1,
      borderLeftWidth: 0,
      borderTopWidth: 0,
      padding: 5,
    },
    tableColRight: {
      width: '20%', 
      borderStyle: 'solid',
      borderWidth: 1,
      borderLeftWidth: 0,
      borderTopWidth: 0,
      padding: 5,
    },  
  });


const GPTtable = ({data}) => {

    return (
        <View style={styles.table}>
          <View style={[styles.tableRow, styles.tableHeader]}>
              <View  style={styles.tableHeaderCol}>
  
              </View>
          </View>
          {data.items.map((item, rowIndex) => (
            <View
              key={rowIndex}
              style={
                rowIndex % 2 === 0 ? styles.tableRow : styles.tableRowGrey
              }
            >
              <View style={styles.tableColLeft}>
                <Text style={styles.tableCell}>{item.title}</Text>
              </View>
              <View style={styles.tableColRight}>
                <Text style={styles.tableCell}>{item.value}</Text>
              </View>
            </View>
          ))}
        </View>

    );
  };
  
export default GPTtable

