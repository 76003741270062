import React, { useEffect, useState } from "react";
import CorsiTest from "./CorsiTest";
import CorsiVideo from "./CorsiVideo";

import Card from "../../common/Card";

import { useSelector, useDispatch } from "react-redux";
import {
    nextBlock,
    increaseCorsiScore_before_baseline,
    increaseCorsiScore_after_baseline,
    submitCorsiBlockAnswer,
    setCorsiBaseline,
    setCorsiPostBaseline,
} from "../../assessmentSlice";
import "./styles.css";


const styles = {
    corsiDiv: {
        paddingBottom: '30px'
    }
}

function get_current_timestamp() {
    const date = new Date();
    return date.getTime();
}

export default function CorsiBlock(props) {
    const dispatch = useDispatch();

    const [level_index, setLevel_index] = useState(0);
    const [subquestion, setSubquestion] = useState("a");
    const [view_video, setView_video] = useState(false);
    const [started_question_timestamp, setSstarted_question_timestamp] =
        useState(null);
    const [nextQ, setNextQ] = useState(0);
    const levels = useSelector(
        (state) => state.assessment.assessmentQAstate.corsi_block.levels
    );
    const [is_post_baseline, setIs_post_baseline] = useState(false);
    const [started_block, setStarted_block] = useState(null);


    // useEffect(() => {
    //     if (didMount.current) next_question();
    //     else didMount.current = true;
    //     // return () => {
    //     //    console.log('Prev value', value)
    //     // }
    // }, [levels]);
    useEffect(() => {
        if (nextQ > 0) next_question();
    }, [nextQ]);

    useEffect(() => {
        //initializing
        // time stamp used to record time to react to every question
        setSstarted_question_timestamp(get_current_timestamp());

        // time stamp for complete block
        setStarted_block(get_current_timestamp());
    }, []);

    function get_current_question() {
        return levels[level_index][subquestion];
    }

    function arraysEqual(a, b) {
        if (a === b) return true;
        if (a == null || b == null) return false;
        if (a.length !== b.length) return false;

        for (var i = 0; i < a.length; ++i) {
            if (a[i] !== b[i]) return false;
        }
        return true;
    }

    function nextLevel() {
        if (level_index < levels.length - 1) {
            setLevel_index((current_level) => current_level + 1);
            setSubquestion("a");
        } else {
            if (!is_post_baseline) {
                dispatch(setCorsiBaseline(level_index));
            }
            dispatch(setCorsiPostBaseline(level_index));

            exit();
        }
    }

    function handleAnswer(answer) {
        let q = get_current_question().question;
        let revAnswer = [].concat(answer).reverse();
        let is_correct = arraysEqual(q, revAnswer);
        dispatch(
            submitCorsiBlockAnswer({
                level_index,
                subquestion,
                answer,
                is_correct,
                milliseconds_to_answer:
                    get_current_timestamp() - started_question_timestamp,
            })
        );
        setNextQ(nextQ + 1);
    }

    function exit() {
        // let finished_block = get_current_timestamp();
        // props.blockTimer("corsi_block", started_block, finished_block)
        dispatch(nextBlock());
    }

    function next_question() {
        if (!is_post_baseline) {
            dispatch(setCorsiBaseline(level_index - 1));
        }
        dispatch(setCorsiPostBaseline(level_index - 1));

        if (
            levels[level_index][subquestion].is_correct &&
            (subquestion === "a" || subquestion === "b")
        ) {
            if (!is_post_baseline) {
                dispatch(increaseCorsiScore_before_baseline());
            }
            dispatch(increaseCorsiScore_after_baseline());
        }

        const { a, b, c, d } = levels[level_index];

        switch (
            subquestion //What is the current question? A,B,C or D?
        ) {
            case "a": //A: Continue question B
                setSubquestion("b");
                break;

            case "b": //B:
                if (!a.is_correct && !b.is_correct) {
                    //is both A and B incorrect?
                    setIs_post_baseline(true);
                    if (is_post_baseline) {
                        //are we post-baseline/have assessee failed a level before?
                        //Exit to next block
                        exit();
                    } else {
                        //continue to C
                        setSubquestion("c");
                        setView_video(true);
                    }
                } else {
                    //Go to next level, or exit if there are no more questions
                    nextLevel();
                }
                break;

            case "c": //C: Continue question D
                setSubquestion("d");
                break;

            case "d": //D:
                if (!c.is_correct && !d.is_correct) {
                    //is both C and D incorrect?
                    //Exit to next block
                    exit();
                } else {
                    //Go to next level, or exit if there are no more questions

                    nextLevel();
                }
                break;

            default:
                break;
        }

        setSstarted_question_timestamp(get_current_timestamp());
    }

    function render() {
        let qa = get_current_question();

        if (view_video) {
            //View video
            //TODO: Video view with continue button that toggles off view_video state
            return (
                <CorsiVideo
                    video={levels[level_index].video}
                    handleContinue={() => {
                        setView_video(false);
                        setSstarted_question_timestamp(get_current_timestamp());
                    }}
                ></CorsiVideo>
            );
        } else {
            //render question
            return (
                <Card>
                    <div style={styles.corsiDiv}>
                        {/* <div>
                            {level_index}
                            {subquestion}
                        </div> */}
                        <CorsiTest
                            question={qa.question}
                            handleChange={handleAnswer}
                            style={{ marginBottom: "100" }}
                        />
                    </div>
                </Card>
            );
        }
    }

    return render();
}
