import React from "react";
import Card from "../../common/Card";
import IntroVideo from "../IntroVideo";


export default class MatrixIntroBlock extends React.Component {

    render() {
        return (
            <Card>
                <div>
                    <IntroVideo
                        handleContinue={this.props.nextBlock}
                        video={"https://vimeo.com/598881074/ed1c8b3ac2"}
                    />
                </div>
            </Card>
        );
    }
}
