import React from 'react';
import {Text, View, StyleSheet } from '@react-pdf/renderer';

const borderColor = 'black'
const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        borderBottomColor: 'black',
        backgroundColor: '#e5e5e5',
        borderBottomWidth: 1,
        alignItems: 'center',
        height: 24,
        textAlign: 'center',
        fontStyle: 'bold',
        flexGrow: 1,
    },
    description: {
        width: '100%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        color: 'white', 
    },
    qty: {
        width: '50%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
    },

  });

  const TableHeader = () => (
    <View style={styles.container}>
        <Text style={styles.description}></Text>

    </View>
  );
  
  export default TableHeader