import React from "react";
import Sidebar from "./Sidebar.js";
// import CourseNav from "./CourseNav.js"
import axiosInstance from "../../helpers/axios"
import Vimeo from "@u-wave/react-vimeo";

import Box from '@mui/material/Box';

import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
// import { makeStyles } from "@material-ui/core";
import { Grid, Card, CardMedia, CardContent, Typography, Container, Stepper, Step, StepLabel, Link, StepConnector, makeStyles} from '@material-ui/core';
import ListItemText from "@material-ui/core/ListItemText";
import Drawer from "@material-ui/core/Drawer";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";

import introcourse from '../courses/content/introcourseContent';
  


const drawerWidth = 210;

const useStyles = makeStyles({
    drawerPaper: {
      marginLeft: "240px",
      width: drawerWidth
    }
  });

function CourseNav({onChapterClick, lessons, title}) {
    const classes = useStyles();


    return (
            <Drawer
                classes={{
                    paper: classes.drawerPaper
                }}
                className={classes.drawer}
                variant="permanent"
                // docked="false"
      
                anchor="left"
                sx={{
                    '& .MuiDrawer-root': {
                        position: 'absolute'
                    },
                    '& .MuiPaper-root': {
                        position: 'absolute'
                    },
                  }}
            >
                {/* <div className={classes.toolbar} /> */}
                <Typography variant="h5" component="h2" style={{ fontWeight: 'semi-bold', marginBottom: '0.5em', textAlign: 'middle', marginTop:'0.5em' }}>
                 {title}</Typography>
                <List>
                        {lessons.map((lesson, index) => (
                             <Link
                                    key={index}
                                    to="/"
                                    style={{
                                        textDecoration: "none",
                                        color: "#000000",
                                    }}
                                    onClick={() => onChapterClick(index)}
                                >
                             <ListItem button>
                                 <ListItemText 
                                         disableTypography
                                         primary={<Typography variant="body" style={{ color: '##000000', textDecoration:'underline'}}>{index+1 +". " + lesson.title }</Typography>}
                                 
                                //  primary={index+1 +". " + lesson.title }
                                />
                                 {/* {(lessons.map((lesson) => 
                                 console.log(lesson.title)
                                ))} */}
                             </ListItem>
                         </Link>
                         ))}

         
                </List>
            </Drawer>
    );
}






function CourseContentLayout({lessonId, lessons}) {
  const currentChapter = introcourse[lessonId]
  // const currentLessonContent = lessons[lessonId].body 

  console.log("lessons from CourseContentLayout", lessons[lessonId]);
  if (!lessons || !lessons[lessonId] || !lessons[lessonId].body) {
    return <div>Property is undefined</div>;
  }
  return (
    <Container maxWidth="md" style={{ paddingTop: '2em' }}>

      {lessons[lessonId].coverImage ? (
            <img
              src={lessons[lessonId].coverImage}
              alt="Blog"
              style={{ width: '100%', marginBottom: '1em' }}
            />
          ) : (
            <div style={{ height: '0px' }}></div>
          )}
    

      <Typography variant="h4" component="h1" style={{ fontWeight: 'bold', marginBottom: '1em', textAlign: 'left' }}>
        {lessons[lessonId].title}
      </Typography>

      {lessons[lessonId].video ? (
                   <Vimeo
                   video={lessons[lessonId].video}
                   width={640}
                   height={480}
               />
          ) : (
            <div style={{ height: '0px' }}></div>
          )}


    <div style={{textAlign: 'left'}} dangerouslySetInnerHTML={{ __html: lessons[lessonId].body }} > 

    </div>
  
 
      <div style={{height:"50px"}}> </div>
    </Container>
  );

  // return (
  //   <Container maxWidth="md" style={{ paddingTop: '2em' }}>
  //     <Typography variant="h4" component="h1" style={{ fontWeight: 'bold', marginBottom: '1em', textAlign: 'left' }}>
  //       {currentChapter.title}
  //     </Typography>
  //     <img src={currentChapter.image} alt="Blog" style={{ width: '100%', marginBottom: '1em' }} />
  //     <Typography variant="body1" component="p" style={{ lineHeight: '1.8', textAlign: 'left'}}>
  //     {currentChapter.paragraphs[0].text}
  //     </Typography>
    

  //     <Typography variant="h5" component="h2" style={{ fontWeight: 'bold', marginBottom: '0.5em', textAlign: 'left', marginTop:'1em' }}>
  //       {currentChapter.paragraphs[1].header}
  //       {/* Registrering */}
  //     </Typography>
  //     <Typography variant="body1" component="p" style={{ lineHeight: '1.8', textAlign: 'left'}}>
  //     {currentChapter.paragraphs[1].text}

  //     {/* Efter att skolan beställt Digibility skickas en aktiveringskod till beställaren. För att använda
  //     Digibility med personuppgifter behöver PUB-avtalet ha signerats av behörig person. Via
  //     Digibilitys hemsida väljer du ”Logga in"  och ”Har du inget konto? Registrera dig”.
  //     Du hamnar då på Registrering (se bilden nedan). Du fyller i er aktiveringskod, dina uppgifter
  //     och önskat lösenord, godkänner användarvillkoren och när du är klar trycker du på knappen ”Registrera”. */}
  //     </Typography>
  //     <Typography variant="h5" component="h2" style={{ fontWeight: 'bold', marginBottom: '0.5em', marginTop: '2em', textAlign: 'left' }}>
  //       {/* Ny kartläggning */}
  //       {currentChapter.paragraphs[2].header}
  //     </Typography>
  //     <Typography variant="body1" component="p" style={{ lineHeight: '1.8', textAlign: 'left' }}>
  //     {/* När du väljer ”Ny kartläggning” visas en kort informationstext och du uppmanas att ”Starta
  //     kartläggningen”. Du har nu kommit in i själva kartläggningen där det finns en procentmätare
  //     högst upp som visar hur långt kartläggningen fortskridit. */}
  //     {currentChapter.paragraphs[2].text}

  //     </Typography>
  //     <Typography variant="h5" component="h2" style={{ fontWeight: 'bold', marginBottom: '0.5em', marginTop: '2em', textAlign: 'left' }}>
  //     {currentChapter.paragraphs[3].header}
  //       {/* Att tänka på under kartläggning */}
  //     </Typography>
  //     <Typography variant="body1" component="p" style={{ lineHeight: '1.8', textAlign: 'left' }}>
  //     {currentChapter.paragraphs[3].text}
  //     {/* Tänk på att samtliga uppgifter sedan kommer i ett flöde, varvat med videofilmer och
  //     ”fortsätt”-knappar. Det är nödvändigt med en bra wifi-uppkoppling eller fast uppkoppling då
  //     samtliga uppgifter är webbaserade och innehåller videofilmer som spelas upp. Prova gärna
  //     igenom uppgifterna så du är trygg med flödet och den utrustning du använder i
  //       kartläggningen. Det är en fördel att undvika alltför små skärmar.  */}
  //     </Typography>
     
  //     {/* 
  //     <Typography variant="h5" component="h2" style={{ fontWeight: 'bold', marginBottom: '0.5em', marginTop: '2em', textAlign: 'left' }}>
  //       Subheading 4
  //     </Typography>
  //     <Typography variant="body1" component="p" style={{ lineHeight: '1.8', textAlign: 'left' }}>
  //     Du eller eleven kan välja att hoppa över enskilda uppgifter med ”Hoppa över sektion” som
  //     finns i menyn som kommer fram när du klickar på kugghjulet. Men, tänk på att du inte kan
  //     gå tillbaka och komplettera med en uppgift som hoppats över. Om eleven hoppat över eller
  //     avbrutit en uppgift kan man alltså inte gå tillbaka och komplettera. Detta kommer att synas i
  //     rapporten som då rapporterar de deluppgifter som inte genomförts. Men, ett sätt att
  //     genomföra en uppgift är då att registrera en ny kartläggning och hoppa fram till just den
  //     uppgiften som man önskar genomföra. Ett tips är att döpa den nya kartläggningen med en
  //     siffra efter namnet så att man vet att det är andra tillfället, t ex ”Pelle Persson, 2”.
  //     </Typography> */}

  //     <div style={{height:"50px"}}> </div>
  //   </Container>
  // );


}


// const useStyles = makeStyles((theme) => ({
//     root: {
//         marginLeft: '100px', // Set the left margin to 100px
//         [theme.breakpoints.down('sm')]: {
//           marginLeft: '0', // Reset the left margin for small screens
//         },
//       },
// }));



class Course extends React.Component {

    
    constructor(props) {
      super(props);
      this.state = {
        lessonId: 0, // Set the initial value to the first lesson
        course: null,
        title: "",
        lessons: [], 
        loading: true
      };
    }

    componentDidMount() {
      const token = localStorage.getItem('token');
      const config = {
        headers: { Authorization: `Token ${token}` }
      };

      const courseId = this.props.match.params.id;
  
      axiosInstance
        .get(`content/course/${courseId}/`, config)
        .then((response) => {
          this.setState({ title: response.data.title, lessons: response.data.lessons, isLoading: false });
          console.log("response.data:",response.data)
        })
        .catch((err) => {
          console.log(err);
        });
    }
  

    handleChapterClick = (id) => {
      this.setState({ lessonId: id });
    };  
    

    render() {
      const { lessonId, title, lessons } = this.state;

        const containerStyle = {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'flex-start',
            margin: '0 auto',
            maxWidth: '1024px',
          };
        console.log("lessons:", lessons)
        

        return (
            <div>
                <Sidebar></Sidebar>
   
                {/*
                 <Grid style={{
                    position: 'fixed',
                    left: 240,
                    top: 0,
                    bottom: 0}}>                          
                    */}
 
                <div style={containerStyle}>
                    <div style={{width:'500px'}}> </div> 
                    <CourseNav 
                      title={title}
                      onChapterClick={this.handleChapterClick}
                      lessons={lessons}
                      ></CourseNav>
                      {console.log("lessons.isArray:",lessons.isArray)}
                    <CourseContentLayout lessonId={lessonId} lessons={lessons}></CourseContentLayout>
          
              </div>
              </div>
        );
    }
}
export default Course;
