import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useSelector } from "react-redux";
import CreateIcon from '@material-ui/icons/Create';
import Button from "@material-ui/core/Button";

import MenuItem from "@material-ui/core/MenuItem";
import { Dialog, DialogTitle, DialogContentText, DialogActions, DialogContent } from '@mui/material';
import TextField from '@mui/material/TextField';

import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import axiosInstance from "../../helpers/axios";


function FormDialog() {
  const [open, setOpen] = React.useState(false);
  const [name, SetName] = React.useState("");
  const [password, SetPassword] = React.useState("");

  
  const handleClickOpen = () => {
    setOpen(true);
  };


  const handleClose = () => {
    setOpen(false);
  };

  function refreshPage() {
    window.location = process.env.REACT_APP_URL + 'installningar'
}

  const onSubmitName = (e) => {
    e.preventDefault();
    const token = localStorage.getItem("token");
    const config = {
        headers: { Authorization: `Token ${token}` }
    };
    axiosInstance 
        .put(`auth/change_name/`, 
        {name:  name, password: password}, 
        config
        )
        .then((response) => {
            
        })
        .catch((err) => {
            console.log(err);
        }); 
    refreshPage();
  };

  return (
    <div>
      <Button variant="text" onClick={handleClickOpen}  style={{textTransform: 'none'}}>
        Redigera namn
        <CreateIcon style={{color:"grey", marginLeft:'7.5px'}}/> 
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Redigera namn</DialogTitle>
        <DialogContent>
          <DialogContentText>
            För att ändra namn skriv i nytt namn (för- och efternamn) och tryck "bekräfta ändring". 
          </DialogContentText>

            <TextField
                  name="name"
                  required
                  fullWidth
                  id="password"
                  label="Namn"
                  autoFocus
                  value={name}
                  onChange={event => SetName(event.target.value)}
                />

          <br></br>
          <br></br>
            <TextField
                  name="name"
                  required
                  fullWidth
                  id="name"
                  type="password"
                  label="Lösenord"
                  autoFocus
                  value={password}
                  onChange={event => SetPassword(event.target.value)}
                />

        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} style={{color:"red"}}>Avbryt</Button>
          <Button onClick={onSubmitName} disabled={name.length === 0 || password.length === 0}  >Bekräfta ändring</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}



function OptionDialog() {
  const [open, setOpen] = React.useState(false);
  const [role, setRole] = React.useState('');
  const [password, SetPassword] = React.useState("");


  const handleChange = (event) => {
    setRole(event.target.value);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  function refreshPage() {
    window.location = process.env.REACT_APP_URL + 'installningar'
  }

  const onSubmitRole = (e) => {
    e.preventDefault();
    const token = localStorage.getItem("token");
    const config = {
        headers: { Authorization: `Token ${token}` }
    };
    axiosInstance 
        .put(`auth/change_role/`, 
        {role:  role, password: password}, 
        config
        )
        .then((response) => {
            
        })
        .catch((err) => {
            console.log(err);
        }); 
    refreshPage();
  };

  return (
    <div>
      <Button variant="text" onClick={handleClickOpen}  style={{textTransform: 'none'}}>
        Redigera roll
        <CreateIcon style={{color:"grey", marginLeft:'7.5px'}}/> 
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Redigera roll</DialogTitle>
        <DialogContent>
          <DialogContentText style={{marginBottom:'30px'}}>
            Använd rutan nedan för att ange ändring av roll. För att avbryta redigering tryck på den röda knappen "avbryt".  
          </DialogContentText>

            <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Roll</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={role}
              label="Roll"
              onChange={handleChange}
              >
              <MenuItem value={"Lärare"}>Lärare</MenuItem>
              <MenuItem value={"Specialpedagog"}>Specialpedagog</MenuItem>
              <MenuItem value={"Rektor"}>Rektor</MenuItem>
              <MenuItem value={"Administrativ"}>Administrativ</MenuItem>

            </Select>
          </FormControl>

          <br></br>
          <br></br>
            <TextField
                  name="password"
                  required
                  fullWidth
                  id="password"
                  type="password"
                  label="Lösenord"
                  autoFocus
                  value={password}
                  onChange={event => SetPassword(event.target.value)}
                />

        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} style={{color:"red"}}>Avbryt</Button>
          <Button onClick={onSubmitRole} disabled={role.length === 0 || password.length === 0}>Bekräfta ändring</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}


const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: 'lightgrey',
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));




export default function UserDetailsTable() {
  // const [anchorEl, setAnchorEl] = React.useState(null);
  // const [openDialogChange, setOpenDialogChange] = React.useState(false);
  // const [open, setOpen] = React.useState(false);
  // const [name, SetName] = React.useState("");
  
  // const handleClickOpen = () => {
  //   setOpen(true);
  // };

  // const handleClose = () => {
  //   setOpen(false);
  // };
  
  const user = useSelector(
    (state) => state.auth.user
);

// not needed when school code is with hyphens, not only representation 
// const addHyphens = (s) => {
//   var hypString= s.slice(0,4) + '-' + s.slice(4, 7) + '-' + s.slice(7);
//   return hypString
// }


  // const handleClick = (event) => {
  //   setAnchorEl(event.currentTarget);
  // };

  // const handleCloseMenu = () => {
  //   setAnchorEl(null);
  // };

  // const onClickName = () =>{
  //   setOpenDialogChange(true); 
  // }

// const handleCloseChange = (event, reason) => {
//   // if (reason === 'clickaway') {
//   //     return;
//   //     }
//       setOpenDialogChange(false); 
//       handleCloseMenu(); 
//   }

  return (
    <div> 
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>
              
              {/* Column name here */}
        
              </StyledTableCell>

            <StyledTableCell align="right">

              {/* Column name here */}
            </StyledTableCell>
            
            <StyledTableCell align="right">
            <div style={{height:'25px'}}> </div>
              {/* Third Column name here? */}
            </StyledTableCell>
          
          
          </TableRow>
        </TableHead>
        <TableBody>
        <StyledTableRow >
              <StyledTableCell component="th" scope="row">
                Namn 
              </StyledTableCell>
   
              <StyledTableCell align="right">{user ? user.name: "-"}</StyledTableCell>
              <StyledTableCell align="right">
                <FormDialog/>
                {/* <Button onClick={handleClick}> 
                <SettingsIcon style={{color:"grey"}}/>

                </Button> 
                      <Menu
                        id="simple-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleCloseMenu}
                    >
                        <MenuItem onClick={handleCloseMenu} >
                            Avbryt  
                        </MenuItem>
                        <MenuItem onClick={handleClickOpen} >
                            Ändra namn    
                        </MenuItem>
                        </Menu> */}
                          {/* <Dialog
                                open={openDialogDelete}
                                onClose={handleCloseDeleteDialog}
                                aria-labelledby="draggable-dialog-title"
                                     >
                                 <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                                 Ändra kontonamn 
                                 </DialogTitle>
                                 <DialogContent>
                                 <DialogContentText>
                                     Är du säker på att du vill ta bort kartläggningen? Det går inte att ångra. 
                                 </DialogContentText>
                                 </DialogContent>
                                 <DialogActions>
                                 <Button autoFocus style={{color:'grey', float:'left'}} onClick={handleCloseDeleteDialog}>
                                     Avbryt
                                 </Button>
                                 <Button color="secondary" onClick={() => onDeleteDialog(student.id)}>Ta bort</Button>
                                 </DialogActions>
                             </Dialog>    */}
          
          {/* <Dialog open={openDialogChange} onClose={handleCloseChange} disableEnforceFocus>
            <DialogTitle>Ändra namn</DialogTitle>
            <DialogContent>
              <DialogContentText>
                För att ändra namn, skriv i det nya namnet i rutan nedan och tryck bekräfta ändring.
              </DialogContentText>
              <TextField
                autoFocus
                margin="dense"
                id="name"
                label="Name"
                value={name}
                onChange={event => SetName(event.target.value)}
                type="name"
                fullWidth
                variant="standard"

              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseChange}>Avbryt</Button>
              <Button onClick={handleCloseChange}>Bekräfta namnändring</Button>
            </DialogActions>
          </Dialog> */}


              {/* <div>
          <Button variant="outlined" onClick={handleClickOpen}>
            Open form dialog
          </Button>
          <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Subscribe</DialogTitle>
            <DialogContent>
              <DialogContentText>
                To subscribe to this website, please enter your email address here. We
                will send updates occasionally.
              </DialogContentText>
              <TextField
                autoFocus
                margin="dense"
                id="name"
                label="Email Address"
                value={name}
                onChange={event => SetName(event.target.value)}
                type="email"
                fullWidth
                variant="standard"
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              <Button onClick={handleClose}>Subscribe</Button>
            </DialogActions>
          </Dialog>
        </div>


 */}



                                                
                </StyledTableCell>
            </StyledTableRow>
            
            <StyledTableRow >
              <StyledTableCell component="th" scope="row">
              E-postadress 
              </StyledTableCell>
   
              <StyledTableCell align="right">{user ? user.email: "-"}</StyledTableCell>
              <StyledTableCell align="right"></StyledTableCell>
            </StyledTableRow>

            <StyledTableRow >
              <StyledTableCell component="th" scope="row">
              Skola 
              </StyledTableCell>
   
              <StyledTableCell align="right">{user ? user.school_name : "-"}</StyledTableCell>
              <StyledTableCell align="right"></StyledTableCell>
            </StyledTableRow>

            <StyledTableRow >
              <StyledTableCell component="th" scope="row">
                Roll  
              </StyledTableCell>
   
              <StyledTableCell align="right">{user ? user.role: "-"}</StyledTableCell>
              <StyledTableCell align="right">
              <OptionDialog/> 
              </StyledTableCell>
            </StyledTableRow>

            <StyledTableRow >
              <StyledTableCell component="th" scope="row">
                Aktiveringskod 
              </StyledTableCell>
              <StyledTableCell align="right">{user ? user.school_code : "-"}</StyledTableCell>
   
              <StyledTableCell align="right"></StyledTableCell>
              <StyledTableCell align="right"></StyledTableCell>
            </StyledTableRow>


            <StyledTableRow >
              <StyledTableCell component="th" scope="row">
                Kontoskapat  
              </StyledTableCell>
              <StyledTableCell align="right">{user ? user.date_joined.slice(0,10): "-"}</StyledTableCell>
              <StyledTableCell align="right"></StyledTableCell>
            </StyledTableRow>


        </TableBody>
      </Table>
    </TableContainer>
    </div>
  );
}
