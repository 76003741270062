import React from 'react';
import {
    Text,
    Svg,
    Path,
    // StyleSheet,
    Circle,
} from "@react-pdf/renderer";


// const styles = StyleSheet.create({
//     page: {
//         flexDirection: "column",
//         backgroundColor: "#E4E4E4",
        
//     },
//     content: {
//       padding: 10,
//       flexDirection: 'column',
      
//     },
//     section: {
//         margin: 10,
//         padding: 10,
//         flexGrow: 1,
//     },
//     gaugeheader: {
//       fontSize: 12,
//     },
// });




function GaugeChart({info}) {
  let needle = null; 
  if(info.Value == null){
     needle = <Path d="M125,140 125 60" strokeWidth="3" stroke="grey" opacity= "0" transform={"rotate(" + 45* info.Value + ")"  } style={{ transformOrigin: '125 140' }} /> 
  } else {
     needle = <Path d="M125,140 125 60" strokeWidth="3" stroke="black" transform={"rotate(" + 45* info.Value + ")"  } style={{ transformOrigin: '125 140' }} />

  }

  return ( 
    <Svg  width="250px" height="215px" style={{backgroundColor:'#e5e5e5'}} >
      <Text x="125" y="20" textAnchor="middle" style={{fontSize:11, fontWeight: 'bold'}}>{info.Title} </Text>
      <Text x="30" y="200" textAnchor="middle" style={{fontSize:10}}> Mycket låg </Text>
      <Text x="25" y="110" textAnchor="middle" style={{fontSize:10}}> Låg </Text>
      <Text x="225" y="110" textAnchor="middle" style={{fontSize:10}}> Hög </Text> 
      <Text x="125" y="50" textAnchor="middle" style={{fontSize:10}}> Genomsnittlig </Text>
      <Text x="220" y="200" textAnchor="middle" style={{fontSize:10}}> Mycket hög </Text>
  
      <Path d="M 83,182 A60,60 225 0,1 65 140" stroke="#bdbef6" strokeWidth="35"/>
      <Path d="M65,140 A60,60 270 0,1 83 98" stroke="#abacf5" strokeWidth="35"/>
      <Path d="M83,98 A60,60 315 0,1 125 80" stroke="#989af4" strokeWidth="35"/>
  
      <Path d="M125,80 A60,60 0 0,1 167 98" stroke="#8587f4" strokeWidth="35"/>
      <Path d="M167,98 A60,60 45 0,1 185 140"   stroke="#7174f4" strokeWidth="35"/>
      <Path d="M185,140 A60,60 90 0,1 167 182" stroke="#5d60f4" strokeWidth="35"/>

      <Circle
          cx="125"
          cy="140"
          r="4"
          fill="black"
        />
        {needle}
  </Svg>
  ); 
}


export default GaugeChart
