import { CircularProgress } from "@material-ui/core";
import React from "react";

import MatrixAnswers from "./MatrixAnswers";


class MatrixQuestion extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          isLoading: true,
        };
      }

    render() {
        return (
            <div className="matrixWrapper">
                {this.state.isLoading ? (
                    <div> 
                    <div style={{height:'150px'}}> 
                    </div> 
                    <CircularProgress></CircularProgress>
                    <div style={{height:'160px'}}> </div> 
                    </div>
                ) : (
                    <p></p>
                    )}
                    <img
                    src={this.props.img}
                    style={{
                        width: "440px",
                        backgroundColor: "white",
                        borderRadius: "3px",
                        // visibility: this.state.isLoading ? 'hidden' : 'visible',
                        // image is shown and starts affecting page layout when loaded
                        pointerEvents: this.state.isLoading ? 'none' : 'auto',
                        opacity: this.state.isLoading ? 0 : 1,
                        position: this.state.isLoading ? 'absolute' : 'relative',
                    }}
                    onLoad={() =>  this.setState({ isLoading: false })}
                    // onLoaded={() => this.setState({ isLoading: false })}
                    />

                <div
                    style={{
                        width: "$width",
                        maxWidth: "$max",
                        minWidth: "$min",
                        display: "inline-block",
                    }}
                >

                    <div 
                        style={{
                            // Answers is revelead after question img has been loaded   
                             visibility: this.state.isLoading ? 'hidden' : 'visible',
                             pointerEvents: this.state.isLoading ? 'none' : 'auto',
                             // opacity: this.state.isLoading ? 0 : 1,
                             // position: this.state.isLoading ? 'absolute' : 'relative',
                            }}
                    
                    > 
                            <MatrixAnswers
                                    answers={this.props.answers}
                                    selected={this.props.selected}
                                    handleChange={this.props.handleChange}
                                />      
                    </div>


  
                </div>
                <style jsx>{`
                    .matrixWrapper {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                    }
                `}</style>
            </div>
        );
    }
}
export default MatrixQuestion;
