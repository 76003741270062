import React, {Fragment} from 'react';
import {Text, View, StyleSheet } from '@react-pdf/renderer';

const borderColor = '#000000'
const styles = StyleSheet.create({
    row: {
        flexDirection: 'row',
        borderBottomColor: '#000000',
        borderBottomWidth: 1,
        alignItems: 'center',
        height: 24,
        fontStyle: 'bold',
    },
    title: {
        width: '75%',
        textAlign: 'center',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        paddingLeft: 8,
        fontSize: 9, 

    },
    value: {
        width: '25%',
        borderRightColor: "white",
        borderRightWidth: 1,
        textAlign: 'center',
        paddingRight: 8,
        fontSize: 9, 

    },


  });


const TableRow = ({items}) => {
    const rows = items.map( item => 
        <View style={styles.row} key={item.id.toString()}>
            <Text style={styles.title}>{item.title}</Text>
            <Text style={styles.value}>{item.value}</Text>
        </View>
    )
    return (<Fragment>{rows}</Fragment> )
};
  
export default TableRow