import React from 'react';
import { Page, StyleSheet, View, Text, Svg } from '@react-pdf/renderer';
import Table from './Table';
import GaugeChart from "./GaugeChart";
import GPTtable from './GPTTABLE';


// Create styles
const styles = StyleSheet.create({
    page: {
        flexDirection: "row",
        backgroundColor: "#E4E4E4",
        
    },
    content: {
      padding: 10,

      '@media max-width: 400': {
        flexDirection: 'column',
      },
      '@media min-width: 400': {
        flexDirection: 'row',
      },

    },
    section: {
        margin: 10,
        padding: 10,
        flexGrow: 1,
    },
    gaugeheader: {
      fontSize: 12,
    },

    view: {
        fontFamily: 'Helvetica',
        width: '250px',
        fontSize: 11,
        paddingTop: 10,
        lineHeight: 2,
        flexDirection: 'column',
        position: 'absolute',
        left: 0,
        top: 0,
    }, 

    headertext:{
        padding: '0',
        fontSize: 14
        
    },

    headerboxtext:{
        padding: '10 12 0 12',
        fontSize: 12
    },

    subheading:{
        padding: '10 12 0 12',
        fontSize: 9
    },

    bodytext: {
        padding: '5 0',
        fontSize:9, 
        lineHeight:1.3,
        },

    bodyboxtext: {
        padding: '5 12',
        fontSize:9, 
        lineHeight:1.3,

    },
});



function StroopPage({data}){

    function boolToSwedish(bool) {
        if (bool){
            return "Ja"
        } else{
            return "Nej"
        }
    }

    return(
    <Page size="A4" style={{padding:'35px'}} >
    <View style={{width: '100%', marginBottom: 10, height: 30, backgroundColor: '#ED663B', padding:5, }}>
         <Text style={{color:"white", fontSize:14 }}> 
            Uppmärksamhetsreglering - Håll koll på färgen  
        </Text>
    </View>

    <View style={{padding:'10 0 20 0'}}> 
    <GPTtable data={{ items: [{id: 0, title: "Antal rätt, kongruent", value: data.scoreCongruent }, {id: 1, title: "Antal rätt, Inkongruent", value: data.scoreInCongruent }, {id:2, title:"Tid att genomföra, kongruent (millisekunder)", value:data.timeCongruent}, {id:3, title:"Tid att genomföra, inkongruent (millisekunder)", value:data.timeInCongruent},  {id:4, title: "Har avbrutits", value: boolToSwedish(data.skipped)}]}} />
        </View> 




        {/* <View style={styles.content}> 
            <View style={{ position: 'absolute', left:'0px'}}> 
            <GaugeChart info={{Title: "Resultat",Value:data.stroop_z}} /> 
            </View>

            <View style={{ position: 'absolute', right:'0px'}}> 

            <Svg  width="250px" height="250px" style={{backgroundColor:'#e5e5e5', borderRadius: "10px"}} >
            <Text x="125" y="20" textAnchor="middle" style={{fontSize:11, fontWeight: 'bold'}}>Uppmärksamhetsreglering är inte dynamiskt. </Text>
            </Svg>

            </View> */}

            <View style={styles.content}> 
                <View style={{width: '250px', height: '215px', backgroundColor: '#e5e5e5', position:'absolute', left:0, }}>
                    <Text style={styles.headerboxtext}> 
                    Resultat  
                    </Text>
                    <Text style={styles.bodyboxtext}> 
                    {data.result_text}
                    </Text>
                </View>

                <View style={{position: 'absolute', right: '0px'}}> 
                <GaugeChart  info={{Title: "Resultat",Value:data.stroop_z}}/>
                </View>
            </View>

 

    <View style={{width: '100%', height: 325, position: 'absolute', bottom: '0px', margin:'0 35 20 35'}}>
         <Text style={styles.headertext}> 
            Rekommendationer 
        </Text>

        <Text style={styles.bodytext}> 
        {data.analysis_text[0]}

        </Text>
        <Text style={styles.bodytext}> 
        {data.analysis_text[1]}

        </Text>

        <Text style={styles.bodytext}>         
        {data.analysis_text[2]}

        </Text>

        <Text style={styles.bodytext}> 
        {data.analysis_text[3]}
        </Text>

    </View>
    </Page>
    )
}

export default StroopPage