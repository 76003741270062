
import React from "react";

class MatrixAnswer extends React.Component {
    render() {
        return (
            <div
                style={{
                    padding: "4px 4px 1px 4px",
                    border: "solid 2px",
                    margin: "0px 3px",
                    borderColor: this.props.selected ? "#2196f3" : "white",
                }}
            >
                <img
                    style={{
                        width: "80px",
                    }}
                    src={this.props.img}
                    onClick={this.props.onClick}
                />
            </div>
        );
    }
}
export default MatrixAnswer;