import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { CORSI_QUESTIONS } from "./AssessmentConstants";
import axiosInstance from "../../helpers/axios";
import axios from "axios";
// import { tokenConfig } from '../actions/auth';

export const fetchAssessmentData = createAsyncThunk(
    "assessment/fetchAssessmentData",
    async (test_id) => {
        // like this?
        // const token = localStorage.getItem('token')
        // const config = {
        //     headers: { Authorization: `Bearer ${token}` }
        // };
        
        return await axiosInstance
            .get(`assessment/${test_id}/`)
            .then((res) => res.data);
    }
);

//Maps assessment data from api to internal format for storing data about questions and answers, later to be posted for analysis.
function build_assessmentQAstate(api_data, testExtent) {
    return {
        testExtent: testExtent,
        info_form_block: {
            //Static for now
            namn: "",
            födelsedatum: "",
            year: "",
            kön: "", 
            behov_av_extraanpassning: "",
            behov_av_särskilt_stöd: "", 
            // skattning_ämne: [
            //     {
            //         question: "Skatta elevens förmåga i ämnet matematik",
            //         answer: "",
            //     },
            //     {
            //         question: "Skatta elevens förmåga i ämnet svenska",
            //         answer: "",
            //     },
            //     {
            //         question: "Skatta elevens förmåga i ämnet engelska",
            //         answer: "",
            //     },
            // ],
            // skattning_läs_och_skriv: [
            //     { question: "Skatta elevens avkodning", answer: "" },
            //     { question: "Skatta elevens språkförståelse", answer: "" },
            // ],f
            kommentar: "",
        },


        rating_block: {
            time_to_complete_rating_block: "",
            skipped: false, 
            corresponding_questions:
                api_data.rating_block.question_collection.map(
                    ( question , index) => {
                        return {
                            index: index+1, 
                            question: question.text, 
                            audio: question.audio, 
                            answer: null, 
                            // question_assessor: assessor_question.text,
                            // question_assessee: assessee_question.text,
                            // audio_assessor: assessor_question.audio,
                            // audio_assessee: assessee_question.audio, 
                            // answer_assessor: null,
                            // answer_assessee: null,
                        };
                    }
                ),
        },

        matrix_block: {
            time_to_complete_matrix_block: "",
            score_before_baseline: 0, //Metadata, not used in frontend
            score_after_baseline: 0, //Metadata, not used in frontend
            skipped: false,

            baseline_level: null,
            post_baseline_level: null,
            levels: api_data.matrix_block_levels.question_collection.map(
                ({ a, b, c, d, video }) => {
                    const build_question = (api_matrix_question) => {
                        let correct_index = null;
                        return {
                            img: api_matrix_question.img,
                            alternatives: api_matrix_question.answers.map(
                                ({ img, correct }, i) => {
                                    if (correct) {
                                        correct_index = i;
                                    }
                                    return img;
                                }
                            ),
                            correct_answer_index: correct_index,
                            answer_index: null,
                            milliseconds_to_answer: null,
                            is_correct: null, //Metadata, not used in frontend
                        };
                    };

                    return {
                        a: build_question(a),
                        b: build_question(b),
                        c: build_question(c),
                        d: build_question(d),
                        video: video,
                    };
                }
            ),
        },

        stroop_block: {
            time_to_complete_stroop_block: "",
            items_data: null,
            itemsGiven: null,
            score: null,
            skipped: false, 
        },

        corsi_block: {
            time_to_complete_corsi_block: "",
            score_before_baseline: null,
            score_after_baseline: null,
            skipped: false, 

            baseline_level: null,
            post_baseline_level: null,
            levels: CORSI_QUESTIONS.map(({ a, b, c, d }) => {
                const build_question = (constant_corsi_question) => {
                    return {
                        question: constant_corsi_question.question,
                        answer: [],
                        milliseconds_to_answer: null,
                        is_correct: null, //Metadata, not used in frontend
                    };
                };

                return {
                    a: build_question(a),
                    b: build_question(b),
                    c: build_question(c),
                    d: build_question(d),
                };
            }),
        },
    };
}

export const assessmentSlice = createSlice({
    name: "counter",

    initialState: {
        current_block: 0,
        assessmentQAstate: null,
        load_assessment_status: "initial",
        is_completed_and_submitted_to_db: false,
        testExtent: "complete",
    },

    reducers: {
        nextBlock: (state) => {
            state.current_block += 1;
        },

        prevBlock: (state) => {
            state.current_block -= 1;
        },

        setBlockTime: (state, action) => {
            const { block, time } = action.payload;
            console.log(block, time);
            if (block === "raing_block") {
                state.assessmentQAstate.rating_block.time_to_complete_rating_block =
                    time;
            } else if (block === "matrix_block") {
                state.assessmentQAstate.matrix_block.time_to_complete_matrix_block =
                    time;
            } else if (block === "stroop_block") {
                state.assessmentQAstate.stroop_block.time_to_complete_stroop_block =
                    time;
            } else if (block === "corsi_block") {
                state.assessmentQAstate.corsi_block.time_to_complete_corsi_block =
                    time;
            }
        },

        setSkippedSection: (state, action) => {

            const {block} = action.payload;
            console.log(block)
            if (block === "matrix_block") {
                state.assessmentQAstate.matrix_block.skipped = true;
            } else if (block === "stroop_block") {
                state.assessmentQAstate.stroop_block.skipped = true;
            } else if (block === "corsi_block") {
                state.assessmentQAstate.corsi_block.skipped = true;
            } else if (block === "rating_block") {
                state.assessmentQAstate.rating_block.skipped = true;
            } 
        }, 

        submitInfoFormBlockAnswer: (state, action) => {
            //{field:string, index:number|undefined, answer:string|number}
            if (action.payload.index !== undefined) {
                const { field, index, answer } = action.payload;
                state.assessmentQAstate.info_form_block[field][index].answer =
                    answer;
            } else {
                const { field, answer } = action.payload;
                state.assessmentQAstate.info_form_block[field] = answer;
            }
        },

        submitMatrixBlockAnswer: (state, action) => {
            //{level_index:number, subquestion:string, answer_index:number, milliseconds_to_answer:number}
            const {
                level_index,
                subquestion,
                answer_index,
                milliseconds_to_answer,
            } = action.payload;
            state.assessmentQAstate.matrix_block.levels[level_index][
                subquestion
            ].answer_index = answer_index;
            state.assessmentQAstate.matrix_block.levels[level_index][
                subquestion
            ].milliseconds_to_answer = milliseconds_to_answer;
            state.assessmentQAstate.matrix_block.levels[level_index][
                subquestion
            ].is_correct =
                answer_index ===
                state.assessmentQAstate.matrix_block.levels[level_index][
                    subquestion
                ].correct_answer_index;
        },

        setMatrixBaseline: (state, action) => {
            //number
            state.assessmentQAstate.matrix_block.baseline_level =
                action.payload;
        },

        setMatrixPostBaseline: (state, action) => {
            //number
            state.assessmentQAstate.matrix_block.post_baseline_level =
                action.payload;
        },

        increaseMatrixScore_before_baseline: (state, action) => {
            if (state.assessmentQAstate.matrix_block.score_before_baseline == null){
                state.assessmentQAstate.matrix_block.score_before_baseline = 1;
            } else {
                state.assessmentQAstate.matrix_block.score_before_baseline += 1;
            }
        },
        
        increaseMatrixScore_after_baseline: (state, action) => {
            if (state.assessmentQAstate.matrix_block.score_after_baseline == null){
                state.assessmentQAstate.matrix_block.score_after_baseline = 1;
            } else {
                state.assessmentQAstate.matrix_block.score_after_baseline += 1;
            }

        },


        submitAssessorRatingBlockAnswer: (state, action) => {
            //{question_index:number, answer:number}
            const { question_index, answer } = action.payload;
            state.assessmentQAstate.rating_block.corresponding_questions[
                question_index
            ].answer_assessor = answer;
        },

        // use this 
        submitAssesseeRatingBlockAnswer: (state, action) => {
            //{question_index:number, answer:number}
            const { question_index, answer } = action.payload;
            state.assessmentQAstate.rating_block.corresponding_questions[
                question_index
            ].answer = answer;
        },

        submitStroopBlockAnswer: (state, action) => {
            const { events, item_count, score } = action.payload;
            state.assessmentQAstate.stroop_block.items_data = events;
            state.assessmentQAstate.stroop_block.itemsGiven = item_count;
            state.assessmentQAstate.stroop_block.score = score;
        },

        submitCorsiBlockAnswer: (state, action) => {
            //{level_index:number, subquestion:string, answer_index:number, milliseconds_to_answer:number}
            const {
                level_index,
                subquestion,
                answer,
                is_correct,
                milliseconds_to_answer,
            } = action.payload;
            state.assessmentQAstate.corsi_block.levels[level_index][
                subquestion
            ].answer = answer;
            state.assessmentQAstate.corsi_block.levels[level_index][
                subquestion
            ].milliseconds_to_answer = milliseconds_to_answer;
            state.assessmentQAstate.corsi_block.levels[level_index][
                subquestion
            ].is_correct = is_correct;
        },

        setCorsiBaseline: (state, action) => {
            //number
            state.assessmentQAstate.corsi_block.baseline_level = action.payload;
        },

        setCorsiPostBaseline: (state, action) => {
            //number
            state.assessmentQAstate.corsi_block.post_baseline_level =
                action.payload;
        },

        increaseCorsiScore_before_baseline: (state, action) => {
            if (state.assessmentQAstate.corsi_block.score_before_baseline == null){
                state.assessmentQAstate.corsi_block.score_before_baseline = 1; 
            } else {
                state.assessmentQAstate.corsi_block.score_before_baseline += 1;
            }
        },

        increaseCorsiScore_after_baseline: (state, action) => {
            if (state.assessmentQAstate.corsi_block.score_after_baseline == null){
                state.assessmentQAstate.corsi_block.score_after_baseline = 1; 
            } else {
                state.assessmentQAstate.corsi_block.score_after_baseline += 1;
            }
        },

        completed_and_submitted_to_db: (state, action) => {
            state.is_completed_and_submitted_to_db = true;
        },

        setTestExtent: (state, action) => {
            state.testExtent = action.payload;
        },
    },

    extraReducers: {
        // Add reducers for additional action types here, and handle loading state as needed
        [fetchAssessmentData.fulfilled]: (state, action) => {
            // Add user to the state array
            state.assessmentQAstate = build_assessmentQAstate(action.payload, state.testExtent);  
            state.load_assessment_status = "fulfilled";
        },

        [fetchAssessmentData.pending]: (state, action) => {
            state.load_assessment_status = "pending";
        },

        [fetchAssessmentData.rejected]: (state, action) => {
            state.load_assessment_status = "rejected";
        },
    },
});

export const {
    nextBlock,
    prevBlock,
    setBlockTime,
    setSkippedSection, 
    setState,
    submitMatrixBlockAnswer,
    setMatrixBaseline,
    setMatrixPostBaseline,
    submitInfoFormBlockAnswer,
    submitAssesseeRatingBlockAnswer,
    completed_and_submitted_to_db,
    increaseMatrixScore_after_baseline,
    increaseMatrixScore_before_baseline,
    submitStroopBlockAnswer,
    submitCorsiBlockAnswer,
    setCorsiBaseline,
    setCorsiPostBaseline,
    increaseCorsiScore_after_baseline,
    increaseCorsiScore_before_baseline,
    setTestExtent,
} = assessmentSlice.actions;

export default assessmentSlice.reducer;