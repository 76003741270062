import React from "react";
import Sidebar from "./Sidebar.js";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import Typography from "@material-ui/core/Typography";



const mainStyle = {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    padding: '2rem',
  };
  

  const courseCardStyle = {
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    width: '300px',
    height: '240px',
    margin: '1rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '5px',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    transition: 'transform 0.2s ease-in-out',
  };
  
  
  const courseCardInnerStyle = {
    position: 'relative',
    width: '100%',
    height: '100%',
    textAlign: 'center',
    transition: 'transform 1s',
    transformStyle: 'preserve-3d',
  };
  
  const courseCardFrontStyle = {
    position: 'absolute',
    width: '100%',
    height: '100%',
    backfaceVisibility: 'hidden',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    borderRadius: '5px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
  };
  
  const courseTitleStyle = {
    color: '#fff',
    fontSize: '1.5rem',
    textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)',
  };
  
  const courseCardBackStyle = {
    position: 'absolute',
    width: '100%',
    height: '100%',
    backfaceVisibility: 'hidden',
    backgroundColor: '#3f3f3f',
    borderRadius: '5px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    transform: 'rotateY(180deg)',
  };
  
  const courseDescriptionStyle = {
    color: '#fff',
    padding: '1rem',
    fontSize: '1rem',
  };


const courses = [
    {
      title: 'Introduktionskurs',
      image: 'https://source.unsplash.com/OyCl7Y4y0Bk',
      description: 'Introduktionskurs till plattformen och dess funktioner, kartläggningen, rapporten och analys. Manual finns även på startsidan.',
    },
    {
      title: 'Introduktion till de kognitiva förmågorna',
      image: 'https://source.unsplash.com/ORDz1m1-q0I',
      description: 'Denna kurs beskriver de skolrelaterade kognitiva förmågor som kartläggs med hjälp av Digibilitys uppgifter.',
    },
    {
      title: 'Metakognition - vad är det?',
      image: 'https://digibility.ams3.digitaloceanspaces.com/digibility/media/images/brain_puzzle.webp',
      description: 'Elevers lärande påverkas av deras metakognition. Denna kurs beskriver vad metakognition är, hur den hänger ihop med elevers kunskapsutveckling och hur man kan stödja den.',
    },
    {
      title: 'Analogt tänkade - vad är det?',
      image: 'https://digibility.ams3.digitaloceanspaces.com/digibility/media/images/rubics_cube.webp',
      description: 'Elever använder analogt tänkande när de upptäcker mönster, samband och drar slutsatser. Denna kurs beskriver vad analogt tänkande är, hur det hänger ihop med kunskapsutvecklingen och hur man kan stödja den.',
    },
    {
      title: 'Arbetsminne - vad är det?',
      image: 'https://digibility.ams3.digitaloceanspaces.com/digibility/media/images/memory_game.webp',
      description: 'Elevers arbetsminne handlar om hur de klarar av minnas information medan de bearbetar den. Denna kurs beskriver vad arbetsminne är, hur det hänger ihop med kunskapsutvecklingen och hur man kan stödja den.',
    },
    {
      title: 'Uppmärksamhetsreglering - vad är det?',
      image: 'https://source.unsplash.com/SaAWhNNOEDw',
      description: 'Elevers uppmärksamhetsreglering handlar om hur de klarar av styra sin uppmärksamhet. Denna kurs beskriver vad uppmärksamhet är, hur den hänger ihop med kunskapsutvecklingen och hur man kan stödja den.',
    },
    {
      title: 'Om dynamisk kartläggning',
      image: 'https://source.unsplash.com/PjQ8VIZrZRE',
      description: 'Digibility bygger på dynamisk kartläggning genom strategistöd. Denna kurs beskriver vad dynamisk kartläggning och hur du kan förstå de dynamiska resultaten i Digibility. Kommer inom kort.',
    },
    {
      title: 'Matematikutveckling',
      image: 'https://source.unsplash.com/DJWvzY4pkGU',
      description: 'Elevers matematikutveckling påverkas av deras kognitiva förmågor. Denna kurs beskriver hur du kan förstå styrkor och utmaningar i elevers matematikutveckling med stöd av Digibility. Kommer inom kort.',
    },
    {
      title: 'Läs- och skrivutveckling',
      image: 'https://source.unsplash.com/RjdoQxJ7-5k',
      description: 'Elevers läs- och skrivutveckling påverkas av deras kognitiva förmågor. Denna kurs beskriver hur du kan förstå styrkor och utmaningar i elevers läs- och skrivutveckling med stöd av Digibility. Kommer inom kort.',
    },
    
    // add more courses here
    // puzzle img:  'https://source.unsplash.com/5B0IXL2wAQ0'
    // pencils img:  'https://source.unsplash.com/l3N9Q27zULw'
  ];
  


  class CourseCard extends React.Component {
    constructor(props) {
      super(props);
      this.state = { hover: false };
    }
  
  
    render() {
      const { title, image, description } = this.props;
      const cardStyle = {
        ...courseCardFrontStyle,
        backgroundImage: `url(${image})`,
      };
  
      const cardInnerStyle = {
        ...courseCardInnerStyle,
        transform: this.state.hover ? 'rotateY(180deg) scale(1.04)' : 'none',
        boxShadow: this.state.hover ? '0px 10px 20px rgba(0, 0, 0, 0.5)' : '0 4px 6px rgba(0, 0, 0, 0.1)',
        transition: 'transform 0.9s ease-in-out, boxShadow 0.6s ease-in-out',
  
      };
  
      return (
        <div
          className="course-card"
          style={courseCardStyle}
          onMouseEnter={() => this.setState({ hover: true })}
          onMouseLeave={() => this.setState({ hover: false })}
        >
          <div className="course-card-inner" style={cardInnerStyle}>
            <div className="course-card-front" style={cardStyle}>
              <h2 style={courseTitleStyle}>{title}</h2>
            </div>
            <div className="course-card-back" style={courseCardBackStyle}>
              <p style={courseDescriptionStyle}>{description}</p>
            </div>
          </div>
        </div>
      );
    }
  }  



class Dashboard extends React.Component {


    render() {
        return (
            <div>
                <Sidebar />
                <div style={{ float: 'left', marginLeft:'250px', marginTop:'20px'}}>
                {/* <Typography component="h4" variant="h4" style={{ textAlign: "left", padding: 0, fontWeight: '500' }}>Kurser</Typography> */}
                <main style={mainStyle}>
                <br></br>


                                {/* Introcourse */}
                                    <Link
                                          to="/kurser/1"
                                          style={{
                                              textDecoration: "none",
                                              color: "#000000",
                                          }}>
              
                                    <CourseCard
                                        key={courses[0].title}
                                        title={courses[0].title}
                                        image={courses[0].image}
                                        description={courses[0].description}
                                    />
                                    </Link>
                                
                                {/* metacognition course */}
                                  <Link
                                          to="/kurser/2"
                                          style={{
                                              textDecoration: "none",
                                              color: "#000000",
                                          }}>
                                    <CourseCard
                                        key={courses[1].title}
                                        title={courses[1].title}
                                        image={courses[1].image}
                                        description={courses[1].description}
                                    />
                                    </Link>

                                    <Link  
                                          to="/kurser/3"
                                          style={{
                                              textDecoration: "none",
                                              color: "#000000",
                                          }}> 
                                    <CourseCard
                                        key={courses[2].title}
                                        title={courses[2].title}
                                        image={courses[2].image}
                                        description={courses[2].description}
                                    />
                                     </Link>
                                    
                                    <Link 
                                          to="/kurser/4"  
                                          style={{  
                                              textDecoration: "none",
                                              color: "#000000",

                                          }}>       
                                    <CourseCard
                                        key={courses[3].title}
                                        title={courses[3].title}
                                        image={courses[3].image}
                                        description={courses[3].description}
                                    />
                                    </Link>
                                    <Link
                                          to="/kurser/5"    
                                          style={{  
                                              textDecoration: "none",
                                              color: "#000000",
                                          }}
                                          > 
                                    <CourseCard
                                        key={courses[4].title}
                                        title={courses[4].title}
                                        image={courses[4].image}
                                        description={courses[4].description}
                                    />
                                    </Link>

                                    <Link
                                          to="/kurser/6"  
                                          style={{  
                                              textDecoration: "none",
                                              color: "#000000",
                                          }}
                                          >
                                    <CourseCard
                                        key={courses[5].title}
                                        title={courses[5].title}
                                        image={courses[5].image}
                                        description={courses[5].description}
                                    />
                                    </Link> 

                                    <CourseCard
                                        key={courses[6].title}
                                        title={courses[6].title}
                                        image={courses[6].image}
                                        description={courses[6].description}
                                    />

                                    <CourseCard
                                        key={courses[7].title}
                                        title={courses[7].title}
                                        image={courses[7].image}
                                        description={courses[7].description}
                                    />

                                    <CourseCard
                                        key={courses[8].title}
                                        title={courses[8].title}
                                        image={courses[8].image}
                                        description={courses[8].description}
                                    />

{/* 
                    {courses.map((course) => (
                                          <Link
                                          to="/introduktionskurs"
                                          style={{
                                              textDecoration: "none",
                                              color: "#000000",
                                          }}>
              
                                    <CourseCard
                                        key={course.title}
                                        title={course.title}
                                        image={course.image}
                                        description={course.description}
                                    />
                                    </Link>
                  ))} */}
                </main>
                </div> 
                
                </div>
        );
    }
}
export default Dashboard;