import React, { Component, Fragment } from "react";
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import CheckCircleIcon from "@material-ui/icons/CheckCircle";


import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import { Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import axiosInstance from "../../helpers/axios";
import { returnErrors } from "../actions/messages";


const theme = createTheme();



export class ConfirmPassword extends Component {
    componentDidMount() {
        const queryParameters = new URLSearchParams(window.location.search)
        const token = queryParameters.get("token")
        this.setState({token: token})
        console.log(token); 
      
    }

    state = {
        password: "",
        password2: "",
        submitted: false, 
        token: ""
    };
    

    static propTypes = {
        register: PropTypes.func.isRequired,
        isAuthenticated: PropTypes.bool,
    };

    onSubmit = (e) => {
        e.preventDefault();

        const {password, password2, token} = this.state; 
        const body = JSON.stringify({ password, token});
        console.log(password, token);
        const config = {
            headers: {
                "Content-Type": "application/json",
            },
        };
        console.log("body", body); 
        axiosInstance
            .post("/password_reset/confirm/", body, config)
            .then((res) => {
                console.log(res)
                this.setState({submitted: true})

            })
            .catch((err) => {
                console.log("ERR", err, err.response.data, err.response.status );
                this.props.returnErrors(err)
            });

        };


    onChange = (e) => this.setState({ [e.target.name]: e.target.value });



    render() {
        const { password, password2, submitted} = this.state;

        let isEnabled =
        password !== "" &&
        password2 !== "" && 
        password.length == password2.length
    return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
            {
                submitted == false && 
                <Fragment> 

<Typography component="h1" variant="h5">
          Återställ lösenord 
          </Typography>
          <Box component="form" noValidate onSubmit={this.onSubmit} sx={{ mt: 3 }}>
              <Grid item xs={12}>

            </Grid>

              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="password"
                  label="Nytt lösenord"
                  type="password"
                  id="password"
                  autoComplete="new-password"
                  onChange={this.onChange}
                  value={password}
                />
              </Grid>
              <br></br>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  label="Upprepa lösenord"
                  type="password"
                  id="password2"
                  autoComplete="new-password"
                  name="password2"
                  onChange={this.onChange}
                  value={password2}
                />
              </Grid>

            <Button
              disabled={!isEnabled}
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Ändra lösenord
            </Button>


                  </Box>
                </Fragment>

            }
            {submitted == true &&
                    <Fragment> 
                    <br></br>
                    <CheckCircleIcon
                    style={{ color: "#009900", fontSize: "50px" }}
                />
                <Typography sx={{ mt: 1 }}> 
                    Lösenordet har ändrats
                </Typography>
                    <Link to="/login" variant="body2">
                        {"Gå till inloggning "}
                    </Link>
                    </Fragment>
            
            }
   
            <Grid container justifyContent="flex-end">
              <Grid item>
                {/* <Link to="/login" variant="body2">
                  Har du redan ett konto? Logga in  
                </Link> */}
              </Grid>
            </Grid>
          </Box>

      </Container>
    </ThemeProvider>
     );
  }
}

const mapDispatchToProps = (dispatch) => {
    return {
      returnErrors: (err) => dispatch(returnErrors(err.response.data, err.response.status))
    }
  };
  
  
export default connect(null, mapDispatchToProps)(ConfirmPassword);

//   export default connect(null, mapDispatchToProps)(ConfirmPassword);
  