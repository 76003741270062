import { configureStore } from "@reduxjs/toolkit";
import assessmentReducer from "../components/assessment/assessmentSlice";
import auth from "../components/reducers/auth";
import errors from "../components/reducers/errors";
import messages from "../components/reducers/messages";

export default configureStore({
    reducer: {
        assessment: assessmentReducer,
        auth: auth,
        errors: errors,
        messages: messages,
        
    },
});
