import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
// import TableBody from '@mui/material/TableBody';
import { tableCellClasses } from '@mui/material/TableCell';
// import TableContainer from '@mui/material/TableContainer';
// import TableHead from '@mui/material/TableHead';
// import TableRow from '@mui/material/TableRow';

import { Dialog, DialogTitle, DialogContentText, DialogActions, DialogContent, Button, Paper, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, Menu, MenuItem} from '@material-ui/core';

// import { Dialog, DialogTitle, DialogContentText, DialogActions, DialogContent } from '@mui/material';

// icons
import GetAppIcon from "@material-ui/icons/GetApp";
import DeleteIcon from '@material-ui/icons/Delete';
import FaceIcon from "@material-ui/icons/Face";
import Avatar from "@material-ui/core/Avatar";
import Report from "../reports/PDF";
import axios from "axios";
import axiosInstance from "../../helpers/axios"

import { PDFDownloadLink, Document, Page, pdf, Text} from '@react-pdf/renderer';



// import pdf from '@react-pdf/renderer';
import { saveAs } from 'file-saver';

const delay = (t) => new Promise((resolve) => setTimeout(resolve, t));

async function getProps() {
  await delay(500);
  return ({
    someString: 'You waited 1 second for this',
  });
}


function RemoveDialog({onDeleteDialog, id }) {
  const [openDialogDelete, setOpenDialogDelete] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);


  const handleCloseDeleteDialog = (event, reason) => {
      setOpenDialogDelete(false); 
      handleCloseMenu(); 
  };

  const onDelete = () =>{
      setOpenDialogDelete(true); 
  };

  const handleCloseMenu = () => {
      setAnchorEl(null);
  };

  const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
  };


  return (
    <div>
          <Button onClick={handleClick}><DeleteIcon style={{ color:"lightgrey"}}/> </Button>
                                    <Menu
                                          id="simple-menu"
                                          anchorEl={anchorEl}
                                          keepMounted
                                          open={Boolean(anchorEl)}
                                          onClose={handleCloseMenu}
                                      >
                                          <MenuItem onClick={handleCloseMenu} >
                                              Avbryt  
                                          </MenuItem>
                                          <MenuItem style={{color: "red"}} onClick={onDelete} >
                                              Ta bort rapport   
                                          </MenuItem>
                                          </Menu>
          <Dialog
                  open={openDialogDelete}
                  // onClose={handleCloseEndDialog}
                  aria-labelledby="draggable-dialog-title"
                  disableEnforceFocus
              >
          <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          Ta bort kartläggning
          </DialogTitle>
          <DialogContent>
          <DialogContentText>
              Är du säker på att du vill ta bort kartläggningen? Det går inte att ångra. 
              <br></br>
          </DialogContentText>
          </DialogContent>
          <DialogActions>
          <Button autoFocus style={{color:'grey', float:'left'}} onClick={handleCloseDeleteDialog}>
              Avbryt
          </Button>
          <Button color="secondary" onClick={() => onDeleteDialog(id)}>Ta bort</Button>
          </DialogActions>
          </Dialog>
    </div>
  );
}









const StyledTableCell = styled(TableCell)(({ theme, }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: 'lightgrey',
    color: theme.palette.common.white,

  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    width: 320,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));


export default function ReportsTable(props) {
  const getDaysToExpiry = (created_at) => {
    
    const todayDate = new Date();
    const createdDate = new Date(created_at.slice(0,10));
    const diffTime = Math.abs(todayDate - createdDate);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
    return(diffDays)
  }

  function refreshPage() {
    window.location = process.env.REACT_APP_URL + 'rapporter/'
  }

  const LazyDownloadPDFButton = ({data}) => (
      // console.log(data),
      <button style={{outline: "none"}}> 
      <div
        style={{ display: "inline-block" }}
        onClick={async () => {
          const props = await getProps();
          const doc = <Report data={data}/>;
          const asPdf = pdf([]); // {} is important, throws without an argument
          asPdf.updateContainer(doc);
          const blob = await asPdf.toBlob();

          const test_extent_sv = (() => {
            if (data.test_extent === "stroop") {
              return "-deltest-uppmärksamhetsreglering-";
            } else if (data.test_extent === "corsi") {
              return "-deltest-arbetsminne-";
            } else if (data.test_extent === "matrix") {
              return "-deltest-analogt-tänkande-";
            } 
            return ""; // default return in case none of the conditions match
          })();


          saveAs(blob, data.info_form_block.namn + test_extent_sv + data.created_at.slice(0,10) +'.pdf');
        }}
        
      >
      <GetAppIcon
          style={{
                  color: "#2F80ED",
                  float: "left",
                  marginRight: "5px",}}/>
                  <Typography style={{ color: "#2F80ED", float: "right" }}>
                      Ladda ned rapport
                  </Typography>
      </div>
      </button>
    );

          
  const onDeleteDialog = (id) =>{
  // make api delete call. 
  console.log("id to delete:", id);
  const token = localStorage.getItem("token");

  const config = {
      headers: { Authorization: `Token ${token}` }
  };
  axiosInstance 
      .delete(`rapporter/${id}/delete/`, 
      config
      

      )
      .then((response) => {
          
      })
      .catch((err) => {
          console.log(err);
      }); 
  refreshPage();
  } 

  return (
    <div> 
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow sx={{ minWidth: 700 }}>
              
              {/* Column name here */}
        

            <StyledTableCell align="right">

              {/* Column name here */}
            </StyledTableCell>
            
            <StyledTableCell align="right">
              <div style={{height:'25px'}}> </div>
              {/* Third Column name here? */}
            </StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
        {props.data.map((student) => (
            <StyledTableRow key={student.id}>
              <TableCell component="th" scope="row" width="250px">
                <div style={{ display: 'flex'}}> 
                  <div > 
                 <Avatar>
                    <FaceIcon />
                </Avatar>
                </div>
                <div style={{marginLeft: '1em'}}>  
                {/* TODO NAME IN BOLD */}
                {/*Append (deltest: {student.test_extent}) */}
                {student.test_extent === "stroop" ? student.info_form_block.namn + " (deltest)" : 
                  student.test_extent === "corsi" ? student.info_form_block.namn + " (deltest)" :
                  student.test_extent === "matrix" ? student.info_form_block.namn + " (deltest)" :
                  student.test_extent === "complete" ? student.info_form_block.namn :
        " "} 
              <div>
                {31 - getDaysToExpiry(student.created_at) + " dagar kvar att ladda ner"}
              </div>
              </div>
              <div> <RemoveDialog onDeleteDialog={onDeleteDialog} id={student.id}/></div> 
              </div> 
              </TableCell>

              <TableCell align="right" width="200px" sx={{width:"200px"}}>
              <LazyDownloadPDFButton data={student}/> 
              </TableCell>

            </StyledTableRow>
          ))}

        </TableBody>
      </Table>
    </TableContainer>
    </div>
  );
}