import axios from "axios";

// for production use:
const baseURL = process.env.REACT_APP_API_URL;

// for dev ? 
// set REACT_APP_API_URL = http://127.0.0.1:8000/ 
// with command $ setenv REACT_APP_API_URL = ... "http://127.0.0.1:8000/"
// const baseURL = "http://127.0.0.1:8000/api/"

const axiosInstance = axios.create({
    baseURL: baseURL,
});

export default axiosInstance;
