import React from "react";

import {
    submitAssesseeRatingBlockAnswer,
    nextBlock,
} from "../../assessmentSlice";
import RatingQuestionList from "./RatingQuestionList";
import { useSelector, useDispatch } from "react-redux";


export default function AssesseeRatingBlock() {
    const dispatch = useDispatch();
    const questions = useSelector((state) =>
        state.assessment.assessmentQAstate.rating_block.corresponding_questions.map(
            ({ question, audio, answer }) => {
                return { question: question, audio: audio, answer: answer };
            }
        )
    );

    const handleChange = (i) => (e) => {
        dispatch(
            submitAssesseeRatingBlockAnswer({
                question_index: i,
                answer: e.target.value,
            })
        );
    };
    return (
        <div>
            <RatingQuestionList
                questions={questions}
                handleChange={handleChange}
                n={2}
                slice
                button
                nextBlock={() => dispatch(nextBlock())}
                labeltype={"smiley"}
            />
        </div>
    );
}
