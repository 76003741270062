import React from "react";

import { Button } from "@material-ui/core";
import Vimeo from "@u-wave/react-vimeo";
import ForwardIcon from "@material-ui/icons/Forward";

export default class IntroVideo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isEnabled: false,
        };
    }

    render() {
        return (
            <div className="videoWrapper">
                {!this.state.isEnabled ? (
                    <Vimeo
                        video={this.props.video}
                        onEnd={() => this.setState({ isEnabled: true })}
                        autoplay
                        width={640}
                        height={480}
                    />
                ) : (
                    <div>
                        <div style={{ width: "640px%", height: "480px" }}></div>
                    </div>
                )}

                <Button
                    style={{
                        backgroundColor: !this.state.isEnabled
                            ? null
                            : "#0F77F2",
                        color: !this.state.isEnabled ? null : "#FFFFFF",
                        margin: "36px 0 0 24px",
                    }}
                    variant="contained"
                    disabled={!this.state.isEnabled}
                    onClick={this.props.handleContinue}
                    endIcon={<ForwardIcon />}
                >
                    Fortsätt
                </Button>

                <style jsx>
                    {`
                        .videoWrapper {
                            max-width: 100vw;
                        }
                    `}
                </style>
            </div>
        );
    }
}