import React from "react";
import IntroVideo from "../IntroVideo";
import Card from "../../common/Card";

export default class StroopIntroBlock extends React.Component {

    render() {
        return (
            <Card>
                <div>
                    <IntroVideo
                        handleContinue={this.props.nextBlock}
                        video={"https://vimeo.com/604718441/151c83aaef"}
                    />
                </div>
            </Card>
        );
    }
}
