import React from "react";
import Sidebar from "./Sidebar.js";
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import UserDetailsTable from "./UserDetailsTable.js";
import Button from "@material-ui/core/Button";
import axiosInstance from "../../helpers/axios";

import { Dialog, DialogTitle, DialogContentText, DialogActions, DialogContent } from '@mui/material';
import TextField from '@mui/material/TextField';



function ChangePasswordDialog() {
    const [open, setOpen] = React.useState(false);
    const [oldPassword, SetoldPassword] = React.useState("");
    const [password, SetPassword] = React.useState("");
    const [password2, SetPassword2] = React.useState("");

    
    const handleClickOpen = () => {
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
    };


    // in case of exit in dialog
    const handleExit = () => {
        setOpen(false);
        SetoldPassword("");
        SetPassword("");
        SetPassword2("");
      };

    function refreshPage() {
        window.location = process.env.REACT_APP_URL + 'installningar'
    }

    const onSubmit = (e) => {
        e.preventDefault();
        const token = localStorage.getItem("token");
        const config = {
            headers: { Authorization: `Token ${token}` }
        };
        axiosInstance 
        .put(`auth/change_password/`, 
        {old_password: oldPassword, password: password, password2: password2}, 
        config
        )
        .then((response) => {
            
        })
        .catch((err) => {
            console.log(err);
        }); 
    refreshPage();
    
        if (password !== password2) {
            console.log("passwords did not match");
            // this.props.createMessage({
            //     passwordNotMatch: "Passwords do not match",});
        } else {
            const passwordChangeData = {
                oldPassword,
                password,
                password2
            };
            // this.props.register(newUser);
        }
    };
    let isEnabled =
        oldPassword !== "" &&
        password !== "" &&
        password2 !== ""
        
  
    return (
      <div>
        <Button onClick={handleClickOpen} variant="text" color="lightgrey" style={{textDecoration: 'underline', align:'left', float:'left', marginLeft:'10px', color:'#89b5f0'}}  >
          Tryck här för att ändra lösenord
        </Button>
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>Ändra Lösenord</DialogTitle>
          <DialogContent>
            <DialogContentText>
              För att ändra lösenord, skriv i ditt gamla lösenord och ditt nya.
            </DialogContentText>
            <br></br>
            <TextField
                  autoComplete="given-name"
                  name="name"
                  required
                  fullWidth
                  id="name"
                  type="password"
                  label="Nuvarande lösenord"
                  autoFocus
                  value={oldPassword}
                  onChange={event => SetoldPassword(event.target.value)}
                />
            <br></br>
            <br></br>

            <TextField
                  autoComplete="given-name"
                  name="name"
                  required
                  fullWidth
                  id="name"
                  type="password"
                  label="Nytt lösenord"
                  autoFocus
                  value={password}
                  onChange={event => SetPassword(event.target.value)}

                />
            <br></br>
            <br></br>

            <TextField
                  autoComplete="given-name"
                  name="name"
                  required
                  fullWidth
                  id="name"
                  type="password"
                  label="Bekräfta nytt lösenord"
                  autoFocus
                  value={password2}
                  onChange={event => SetPassword2(event.target.value)}

                />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleExit} style={{color:"red"}}>Avbryt</Button>
            <Button onClick={onSubmit} disabled={!isEnabled}  >Bekräfta ändring av lösenord</Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }



class UserDetailsPage extends React.Component {

    render() {
        return (
            <div>
                <Sidebar />
                <div style={{marginLeft:'200px', marginTop:'58px', fontSize:'small'}}> 
                <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Grid container spacing={3}>
    
              {/* Recent Orders */}
              <Grid item xs={12}>
                <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', height: 750}}>
                <Typography component="h4" variant="h4" color="black" gutterBottom align="left" style={{marginLeft:'20px'}}>
                    Inställningar
                </Typography>
                <div style={{margin:'20px'}}> 
                <UserDetailsTable/>
                </div>
                <br/>  
                <div> 
                <Typography component="h3" variant="h7" color="black" gutterBottom align="left" style={{marginLeft:'20px'}}>
                    Ändra lösenord 
                </Typography>

                  <ChangePasswordDialog/> 
                  </div> 
                <br/> 
                </Paper>
              </Grid>
            </Grid>
          </Container>
              </div>
            </div>
        );
    }
}
export default UserDetailsPage;
