import React from "react";
import MatrixAnswer from "./MatrixAnswer";

const styles = {
    matrixAnswers: {
        display: 'flex',
        borderTop: 'solid 1px lightGray',
        paddingTop: '20px',
        marginTop: '20px'
    }
};

class MatrixAnswers extends React.Component {
    render() {
        return (
            <div style={styles.matrixAnswers}>
                {this.props.answers.map((e, i) => (
                    <MatrixAnswer
                        key={i}
                        img={e}
                        selected={this.props.selected === i}
                        onClick={() => this.props.handleChange(i)}
                    />
                ))}
            </div>
        );
    }
}
export default MatrixAnswers;