import React, { useEffect, useState, useRef } from "react";
import CountDown from "../../common/CountDown";

const TIME_INTERVAL = 1000;
const STARTUP_DELAY = 2000;
const HIDEBLOCK = 3500;

const INSTRUCTION_OBSERVE = "Titta noga!";
const INSTRUCTION_REPEAT = "Nu är det din tur!";

function CorsiTest(props) {
    const [isWaitingForAnswer, setIsWaitingForAnswer] = useState(false);
    const [blockMap, setBlockMap] = useState(getInitialBlockMap());
    const blockMapRef = useRef(blockMap);
    const [questionAnswered, setquestionAnswered] = useState(false);
    blockMapRef.current = blockMap;

    const [question, setQuestion] = useState([]);
    const [answer, setAnswer] = useState([]);
    const [showBlock, setShowBlock] = useState(false);
    // const [question_started_timestamp, setQuestioStartedTimestamp] = useState(null)

    useEffect(() => {
        setQuestion(props.question);
        return () => setQuestion();
    }, [props.question]);

    useEffect(() => {
        // Startup trigger, will run only once at first
        if (question.length === 0) {
            debugLog("Triggering new question generation from question change");
            // setQuestion(props.question);
            // setQuestioStartedTimestamp(get_current_timestamp())
        }
        let timer;
        let timer2;

        // Making sure that this is ran on a new and properly populated question set
        if (question.length > 0) {
            timer2 = setTimeout(() => {
                setShowBlock(true);
                // Initiate a small delay to orientate the user before a new question is set
                timer = setTimeout(() => {
                    // Show pattern
                    question.forEach((ques, index) => {
                        timer = setTimeout(() => {
                            highlightBlockState(ques);
                        }, index * TIME_INTERVAL);
                    });

                    // Reset and get the participant ready for the answer
                    timer = setTimeout(() => {
                        setIsWaitingForAnswer(true);
                        setBlockMap(getInitialBlockMap());
                    }, (question.length + 1) * TIME_INTERVAL);
                }, STARTUP_DELAY);
            }, HIDEBLOCK);
        }

        return () => clearTimeout(timer);
    }, [question, props.question]);

    useEffect(() => {
        let timer;

        // Only run when there has been active changes to the answer
        if (answer.length <= 0) return;

        // block student from spamming
        if (answer.length > question.length) {
            setBlockMap(getInitialBlockMap());
            return;
        }
        // Check if the latest answer is correct
        if (answer[answer.length - 1] === question[answer.length - 1]) {
            debugLog("Correct block");

            // The answer for this question has been completed
            if (answer.length === question.length) {
                setIsWaitingForAnswer(false);
                timer = setTimeout(() => {
                    setquestionAnswered(true);
                    setBlockMap(getInitialBlockMap());
                    setQuestion([]);
                    setShowBlock(false);

                    setAnswer([]);
                    debugLog(
                        "Triggering new question generation from answer completion"
                    );
                }, 1000);
            }
        } else if (answer.length === question.length) {
            setIsWaitingForAnswer(false);
            timer = setTimeout(() => {
                setquestionAnswered(true);
                setBlockMap(getInitialBlockMap());
                setQuestion([]);
                debugLog(
                    "Triggering new question generation from answer completion"
                );
                setShowBlock(false);
                setAnswer([]);
            }, 1000);
            debugLog("Wrong block");
        }
        return () => {
            clearTimeout(timer);
        };
    }, [answer]);

    useEffect(() => {
        if (questionAnswered === true) props.handleChange(answer);
        setquestionAnswered(false);
    }, [questionAnswered]);

    function getInitialBlockMap() {
        let array = [];
        for (let i = 0; i < 9; i++) {
            array.push(false);
        }
        return array;
    }

    function highlightBlockState(index) {
        if (process.env.REACT_APP_DEBUG_MODE) {
            debugLog("Highlighting block: " + index);
        }
        let newMap = getInitialBlockMap();
        newMap[index] = true;
        setBlockMap(newMap);
    }

    function addNewAnswerNode(index) {
        let newAns = [...answer];
        newAns.push(index);
        setAnswer(newAns);
    }

    function handleBlockClick(index) {
        if (isWaitingForAnswer) {
            highlightBlockState(index);
            addNewAnswerNode(index);
        }
    }

    return (
        <div>
            {showBlock ? (
                <div className="corsiblock">
                    {process.env.REACT_APP_DEBUG_MODE === "true" && (
                        <>
                            Debug data:{" "}
                            {question.map((num, index) => (
                                <span key={index}>{num} </span>
                            ))}
                            ;
                            {answer.map((num, index) => (
                                <span key={index}>{num} </span>
                            ))}
                        </>
                    )}

                    <div className="corsiblock__instruction">
                        <h2 className="corsiblock__instruction__content">
                            {isWaitingForAnswer ? (
                                <>{INSTRUCTION_REPEAT}</>
                            ) : (
                                    <>{INSTRUCTION_OBSERVE}</>
                                )}
                        </h2>
                    </div>

                    {/* The block map */}
                    <div className="corsiblock__blocks">
                        {blockMap.map((isHighlighted, index) => (
                            <div
                                key={index}
                                className="corsiblock__blocks__container"
                            >
                                <Block
                                    index={index}
                                    isHighlighted={isHighlighted}
                                    handleClick={handleBlockClick}
                                />
                            </div>
                        ))}
                    </div>
                </div>
            ) : (
                    <div style={{paddingTop:'4px', paddingBottom:'250px'}}>
                        <h2>Var beredd! </h2>
                        <div style={{height:'200px'}}> </div>
                        <br></br>
                        <CountDown />
                        <br></br>
                    </div>
                )}
        </div>
    );
}
// 230 -36= 194

function Block({ index, isHighlighted, handleClick }) {
    let classNames =
        "corsiblock__blocks__container__block corsiblock__blocks__container__block--clickable";

    if (isHighlighted)
        classNames += " corsiblock__blocks__container__block--highlighted";

    return (
        <div
            className={classNames}
            onClick={() => {
                handleClick(index);
            }}
        >
        </div>
    );
}

export default CorsiTest;

function debugLog(str) {
    if (process.env.REACT_APP_DEBUG_MODE) {
        console.log(str);
    }
}
