import React from 'react';
import { Page, StyleSheet, View, Text } from '@react-pdf/renderer';
import Table from './Table';
import GPTtable from './GPTTABLE';
import GaugeChart from "./GaugeChart";


// Create styles
const styles = StyleSheet.create({
    page: {
        flexDirection: "row",
        backgroundColor: "#E4E4E4",
        
    },
    content: {
      padding: 10,

      '@media max-width: 400': {
        flexDirection: 'column',
      },
      '@media min-width: 400': {
        flexDirection: 'row',
      },

    },
    section: {
        margin: 10,
        padding: 10,
        flexGrow: 1,
    },
    gaugeheader: {
      fontSize: 12,
    },

    view: {
        fontFamily: 'Helvetica',
        width: '250px',
        fontSize: 11,
        paddingTop: 10,
        lineHeight: 2,
        flexDirection: 'column',
        position: 'absolute',
        left: 0,
        top: 0,
    }, 

    headertext:{
        padding: '0',
        fontSize: 14
        
    },

    headerboxtext:{
        padding: '10 12 0 12',
        fontSize: 12
    },


    subheading:{
        padding: '10 12 0 12',
        fontSize: 9
    },

    bodytext: {
        padding: '5 0',
        fontSize:9, 
        lineHeight:1.3,
        },

    bodyboxtext: {
        padding: '5 12',
        fontSize:9, 
        lineHeight:1.3,

    },
});



function CorsiPage({data}){

    function boolToSwedish(bool) {
        if (bool){
            return "Ja"
        } else{
            return "Nej"
        }
    }
    
    return(
    <Page size="A4" style={{padding:'35px'}} >
    <View style={{width: '100%', marginBottom: 10, height: 30, backgroundColor: '#ED663B', padding:5, }}>
         <Text style={{color:"white", fontSize:14 }}> 
            Metakognitivt arbetsminne - Baklängesminne  
        </Text>
    </View>

    <View style={{width: '100%', marginBottom: 0, height: 75, position:'absolute,', top:0}}>
                        <View style={styles.view}> 
                        <View style={{height:"90%"}}> 
                           <GPTtable data={{ items: [{id: 0, title: "Antal rätt innan stöd", value: data.score_pre }, {id:1, title:"Antal rätt efter stöd", value: data.score_post}, {id:2, title: "Har avbrutits", value: boolToSwedish(data.skipped)}, {id:3, title:"Tid att genomföra", value:data.time_to_complete}]}} />
                          </View>
                        </View>
                <View style={{width: '250px', marginTop:10, height: 120, backgroundColor: '#e5e5e5', position:'absolute', right:0, top:0,}}>
                    <Text style={styles.headerboxtext}> 
                    Resultat  
                    </Text>
                    <Text style={styles.bodyboxtext}> 
                    {data.result_text}
                    </Text>
                </View>
    </View>


    <View style={{height:75}}>

    </View>

        <View style={styles.content}> 
            <View style={{ position: 'absolute', left:'0px'}}> 
            <GaugeChart info={{Title: "Resultat utan strategistöd",Value:data.corsi_z_pre}} /> 
            </View>

            <View style={{position: 'absolute', right: '0px'}}> 
            <GaugeChart  info={{Title: "Resultat med strategistöd",Value:data.corsi_z_post}}/>
            </View>
        </View>

    <View style={{width: '100%', borderRadius: '10px', height: 370, position: 'absolute', bottom: '0px', margin:'0 35 20 35'}}>
         <Text style={styles.headertext}> 
            Rekommendationer 
        </Text>

        <Text style={styles.bodytext}> 
        {data.analysis_text[0]}

        </Text>
        <Text style={styles.bodytext}> 
        {data.analysis_text[1]}

        </Text>

        <Text style={styles.bodytext}>         
        {data.analysis_text[2]}

        </Text>

        <Text style={styles.bodytext}> 
        {data.analysis_text[3]}
        </Text>

    </View>

    </Page>
)
}

export default CorsiPage