import React, { Component, Fragment } from "react";
import { Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import Button from '@mui/material/Button';
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
// import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import axiosInstance from "../../helpers/axios";
import { returnErrors } from "../actions/messages";




const theme = createTheme();


export class ResetPassword extends Component {
    state = {
        email: "",
        submitted: false, 
    };

    static propTypes = {
        login: PropTypes.func.isRequired,
        isAuthenticated: PropTypes.bool,
    };

    onSubmit = (e) =>  {
        e.preventDefault();
        let email = this.state.email
        const body = JSON.stringify({ email });
        const config = {
            headers: {
                "Content-Type": "application/json",
            },
        };
        axiosInstance
            .post("/password_reset/", body, config)
            .then((res) => {
                this.setState({submitted: true})

            })
            .catch((err) => {
              console.log("ERR", err, err.response.data, err.response.status );
                this.props.returnErrors(err)
            });



    };

    onChange = (e) => this.setState({ [e.target.name]: e.target.value });

    render() {
        if (this.props.isAuthenticated) {
            return <Redirect to="/" />;
        }
        const { email, submitted } = this.state;


        return( 
            <ThemeProvider theme={theme}>
            <Grid container component="main" sx={{ height: '100vh' }}>
              <CssBaseline />
              <Grid
                item
                xs={false}
                sm={4}
                md={7}
                sx={{
                  backgroundImage: 'url(https://digibility.ams3.digitaloceanspaces.com/digibility/media/images/1_1C.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=MGJW3UALUCXCG54TSGXQ%2F20221015%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20221015T094618Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Signature=340466c5d8d9211e299c5a363888bf4bf5b0969f0929675370b04466a9a2c088)',
                  backgroundRepeat: 'no-repeat',
                  backgroundColor: (t) =>
                    t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                }}
              />
              <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
                <Box
                  sx={{
                    my: 8,
                    mx: 4,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  { submitted == false && 
                <Fragment> 
                    <Typography component="h1" variant="h5">
                        Återställ lösenord
                    </Typography>

                  <Typography component="p" variant="p">
                    Ett mail med instruktioner kommer  <br></br>
                    skickas till din e-postadress. 
                  </Typography>

                  <Box component="form" noValidate onSubmit={this.onSubmit} sx={{ mt: 1 }}>
                    <TextField
                      margin="normal"
                      required
                      fullWidth
                      id="email"
                      label="E-postadress"
                      name="email"
                      autoComplete="email"
                      autoFocus
                      onChange={this.onChange}
                      value={email}
                      />

                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      sx={{ mt: 3, mb: 2 }}
                      >
                      Återställ
                    </Button>
                    <Grid container>
                      <Grid item>
                        <Link to="/login"  variant="body2">
                          {"Gå tillbaka till inloggning "}
                        </Link>
                      </Grid>
                    </Grid>
                    {/* <Copyright sx={{ mt: 5 }} /> */}
                  </Box>
                  </Fragment> 
                }
                
                {
                    submitted == true && 
                    <Fragment> 
                        <br></br>
                        <CheckCircleIcon
                        style={{ color: "#009900", fontSize: "50px" }}
                    />
                    <Typography sx={{ mt: 1 }}> 
                        Ett mail med instruktioner har skickats till din e-postadress!
                        <br></br>
                        Följ länken som finns i e-mailet för att skapa ett nytt lösenord. 
                        <br></br>
                    </Typography>
                        <Link to="/reset_password"  onClick={() => window.location.reload()} variant="body2">
                            {"Skicka ett nytt mail "}
                        </Link>
                        </Fragment>
                }
                </Box>

              </Grid>
            </Grid>
          </ThemeProvider>
        )
    }
}

// const mapStateToProps = (state) => ({
//     isAuthenticated: state.auth.isAuthenticated,
// });

const mapDispatchToProps = (dispatch) => {
  return {
    returnErrors: (err) => dispatch(returnErrors(err.response.data, err.response.status))
  }
};

export default connect(null, mapDispatchToProps)(ResetPassword);
