import React, { Component } from "react";
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import axiosInstance from "../../helpers/axios";

// import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import { Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { register } from "../actions/auth";
import { createMessage } from '../actions/messages';
import InputMask from "react-input-mask";


// import HelpIcon from '@mui/icons-material/Help';
// import HelpCenterIcon from '@mui/icons-material/HelpCenter';
import IconButton from "@mui/material/IconButton";
// import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import DashboardIcon from "@material-ui/icons/Dashboard";

import HelpIcon from "@material-ui/icons/Help";
// import QuestionMarkIcon from "@material-ui/icons/Question";

import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogContentText from '@mui/material/DialogContentText';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

const theme = createTheme();

function SimpleDialog(props) {
  const { onClose, selectedValue, open } = props;

  const handleClose = () => {
    onClose(selectedValue);
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>Aktiveringskod för skola</DialogTitle>
      <DialogContent> 
      <DialogContentText id="alert-dialog-description">
            Aktiveringskod för din skola finns att hitta i kvittot som skickades med vid köp av årslicens. Om du har en kollega som redan skapat konto finns den även att hitta i plattformen under inställningar. 
            <br/>
            <br/>
            En licens kan köpas på Digibilitys hemsida.  
          </DialogContentText>
      </DialogContent> 
      <DialogActions style={{ justifyContent: "space-between" }}>
            <div style={{float:'left', marginLeft:"10px", marginBottom:'10px', fontSize:'8', color:'grey'}}> 
                  {/* Har din skola ingen årslicens? 
                  <br/> 
                  <Link>Skaffa en årslicens</Link> */}
                </div>
          <Button onClick={handleClose}>Fortsätt</Button>
        </DialogActions>
    </Dialog>
  );
}




export class Register extends Component {
    state = {
        open: false,
        openTerms: false, 
        name: "",
        school_key: '', 
        mask: '9999-9999-9999', 
        role: "",
        email: "",
        password: "",
        password2: "",
        approvedTerms: false, 
    };


    static propTypes = {
        register: PropTypes.func.isRequired,
        isAuthenticated: PropTypes.bool,
    };


    handleClickOpen = () => {
      this.setState({open:true});
    };
  
      handleClose = () => {
        this.setState({open:false});
    };


    handleClickOpenTerms = () => {
      this.setState({openTerms:true});
    };
  
      handleCloseTerms = () => {
        this.setState({openTerms:false});
    };
  
  

    onSubmit = (e) => {

        e.preventDefault();
        const { name, school_key, role, email, password, password2 } = this.state;
        if (password !== password2) {
            this.props.createMessage({
                passwordNotMatch: "Lösenord och upprepade lösenord stämde inte.",});
        } else {

            const newUser = {
                name,
                school_key,
                role, 
                password,
                email
            };
            this.props.register(newUser);
        }
    };


    


    onChange = (e) => this.setState({ [e.target.name]: e.target.value });


    onChangeD = (event) => {
      var value = event.target.value.toUpperCase();
      var newState = {
        mask: '9999-9999-9999',
        school_key: value
      };
      if (/^3[47]/.test(value)) {
        newState.mask = '9999-999999-99999';
      }
      this.setState(newState);
    }

    removeMaskChars(s) {
      return(s.replace(/[_-]/g, '').length) 
    }


    handleChangeCheck = name => event => {
      this.setState({ [name]: event.target.checked });
    };


    render() {
        if (this.props.isAuthenticated) {
            return <Redirect to="/" />;
        }
        const { name, school_key, role, email, password, password2, approvedTerms} = this.state;

        let isEnabled =
        this.removeMaskChars(school_key) === 12 &&
        name.length > 0 &&
        role !== "" &&
        email !== "" &&
        password !== "" &&
        password2 !== "" &&
        approvedTerms
    return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
            {/* <LockOutlinedIcon /> */}
          <Typography component="h1" variant="h5">
          Registrering 
          </Typography>
          <Box component="form" noValidate onSubmit={this.onSubmit} sx={{ mt: 3 }}>
            <Grid container spacing={2}>
            <Grid item xs={12}>

        
              
            <InputMask value={this.state.school_key} mask={this.state.mask} onChange={this.onChangeD}  formatChars={{"9":"[a-zA-Z0-9]"}}> 
            
            {() => <TextField
              // id={attribute}
              // label={attribute}
              // name={attribute}
              // className={this.props.classes.textField}
              placeholder="XXXX-XXXX-XXXX"
              autocomplete="off"
              type="text"
              label="Aktiveringskod till skola"
              style = {{width: 300}}
              InputProps={{
                endAdornment: (<IconButton onClick={this.handleClickOpen} ><HelpIcon style={{color:"lightgrey"}}/> </IconButton>) 
              }}
              />}

            </InputMask>
            <SimpleDialog
                   open={this.state.open}
                   onClose={this.handleClose}/> 

              </Grid>

              <Grid item xs={12}>
                <TextField
                  autoComplete="given-name"
                  name="name"
                  required
                  fullWidth
                  id="name"
                  label="Fullständigt namn"
                  autoFocus
                  onChange={this.onChange}
                  value={name}

                />
              </Grid>
              <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label"  style={{ alignContent: "center", marginLeft:"12.5px", pattern: "[a-z]{1,15}"}} InputLabelProps={{ shrink: true }}>Roll</InputLabel>
                <Select
                  name="role"
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  onChange={this.onChange}
                  value={role}
                >
                  <MenuItem value={"Lärare"}>Lärare</MenuItem>
                  <MenuItem value={"Specialpedagog"}>Specialpedagog</MenuItem>
                  <MenuItem value={"Rektor"}>Rektor</MenuItem>
                  <MenuItem value={"Administratör"}>Administratör</MenuItem>

                </Select>
              </FormControl>
            </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="email"
                  label="E-postadress"
                  type="email"
                  name="email"
                  autoComplete="email"
                  onChange={this.onChange}
                  value={email}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="password"
                  label="Lösenord"
                  type="password"
                  id="password"
                  autoComplete="new-password"
                  onChange={this.onChange}
                  value={password}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  label="Upprepa lösenord"
                  type="password"
                  id="password"
                  autoComplete="new-password"
                  name="password2"
                  onChange={this.onChange}
                  value={password2}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                 style={{ float:'left'}}
                  control={<Checkbox required  checked={approvedTerms} name="approvedTerms"  id="approvedTerms" color="primary" value={approvedTerms} onChange={this.handleChangeCheck('approvedTerms')} />}
                  label="Jag har läst och godkänner användarvillkoren."
                />
              </Grid>
            </Grid>

            <Button
              disabled={!isEnabled}
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Registrera
            </Button>
            <Grid container justifyContent="flex-end">
              <Grid item>
                <Link to="/login" variant="body2">
                  Har du redan ett konto? Logga in  
                </Link>
              </Grid>
              <br/>
              <br/>
              <Grid item>
                <Button onClick={ () => this.handleClickOpenTerms()}variant="body2" style={{background:"none", textDecoration: "underline", color: "#0000EE", textTransform: "none", fontSize:"16px"}}>
                 * Användarvillkor för Digibility 
                </Button>
              </Grid>

            </Grid>
          </Box>
        </Box>
        {/* <Copyright sx={{ mt: 5 }} /> */}
      </Container>

      <Dialog onClose={this.handleCloseTerms} open={this.state.openTerms}>
      <DialogTitle>Användarvillkor för Digbility</DialogTitle>
      <DialogContent> 
      <DialogContentText id="alert-dialog-description">
                  1 Om Digibility <br/> 
            Genom att acceptera dessa villkor samtycker du till att ingå ett juridiskt bindande avtal med Digibility AB med organisationsnummer 559296-3085. 
            Plattformen Digibility har utav programmerare och svenska utbildningsforskare utvecklat kartläggningsverktyg av skolrelaterade förmågor, för elever i grundskolan. 
            Syftet med plattformen är till att stödja och höja kompetensen hos grundskolelärare för att kunna hjälpa så många elever som möjligt att nå läromålen. Verksamheten fokuserar på att utveckla digitala produkter och fortbildningsmaterial för lärare. 
            För att kunna nyttja Digibilitys plattform med kartläggningsfunktioner, och tillhörande administrations- och analysgränssnitt krävs att Lärare/Användare godkänner dessa användarvillkor. 
            Digibility kan komma att ändra villkoren. Om Digibility ändringar av användarvillkoren som anses betydande så kommer Digibility att meddela Läraren/Användaren via e-post. Genom att fortsätta använda plattformen efter detta har Läraren/Användaren godkänt och samtyckt till sådana ändringar. Om Läraren/Användaren inte godkänner ändringarna, är det enda alternativet att sluta använda plattformen.
            <br/> 
            <br/> 
            2. Plattformen  <br/> 
            Lärare och användare som registrerar sig på Digibilitys hemsida får tillgång till kartläggningsverktyget, som används för att kunna kartlägga elevers inlärningsförmågor. Detta hjälper lärare att bättre anpassa sin undervisning till elevernas faktiska behov. Digibilitys kartläggning utförs digitalt.
            Vid skapandet av ett konto på systemet anger lärare/användare en aktiveringskod som kopplar kontot till den specifika skolan som har köpt en licens. Det är inte tillåtet att använda en licens mellan olika skolor. 
            Efter att ha skapat ett personligt konto kan lärare/användare se introduktionsvideos för att lära sig mer om verktyget och sedan börja kartlägga elever. Elevernas namn, kön, födelsedata samt årskurs och kursplan kan registreras för att förbättra användarupplevelse, men det är valfritt och persondata kan även anonymiseras under kartläggning. 
            Om en lärare byter arbetsgivare/skola eller vill stänga ner sitt konto, måste kartläggningarna som är kopplade till kontot laddas ner för att inte gå förlorade. Användaren bör sedan ta bort sitt konto kopplat till den tidigare skolan och göra ett nytt konto. 
            Digibility har rätt att använda anonymiserad data som genereras av verktyget för statistik, utveckling av nya verktyg, rapportering, marknadsföring och som underlag för forskning. Det kan även hända konton som varit inaktiva under lång period (flera månader) behöva inaktiveras. I sådanna fall så kommer användaren informeras och ges möjligheten att förbli aktiv. 
            <br/>
            <br/> 

            3. Digibility behandling av personuppgifter <br/> 
            Digibility är personuppgiftsbiträde för användare som använder dess verktyg, och skolans huvudman är personuppgiftsansvarig. En PUB-avtal bör finnas mellan Digibility och skolans huvudman. Om en användare inte registrerar ett konto på uppdrag av en skolhuvudman är användaren ansvarig för personuppgifterna. 
            För att använda verktyget måste lärare/användare skapa ett personligt konto och ange e-postadress, arbetsroll, namn, samt ange ett  personligt lösenord. Användaren åtar sig att skapa och hantera lösenord så att det inte kan komma till anvädning av obehöriga. 
            Användarna kan när som helst ändra sina uppgifter på sin personliga profilsida. 
            <br/> 
            <br/> 

            4. Rättighetsupplåtande <br/> 
            Digibility äger varumärken, loggor, domännamn och annat innehåll i plattformen. Användare har en begränsad, icke-exklusiv rätt att använda verktyget och dess innehåll. Användare får inte publicera, sprida, underlicensiera, översätta eller kopiera upphovsrättsligt skyddat material, varumärken eller annan immaterialrättsligt skyddad information utan samtycke från Digibility.
            <br/> 
            <br/> 

            5. Tekniska begränsningar och ändringar <br/> 
            Digibility gör skäliga ansträngningar för att till största möljliga grad hålla plattformen under drift och tillgänglig, men tillfälliga avbrott kan uppstå och är inte skäl till kompensation. Digibilitys har rätt att säga upp en licens utan skyldighet gentemot användare eller dess huvudman. I sådana fall har användare rätt till kompensation för återstående tid av licensen som har sagts upp.Digibility har ingen skyldighet att uppgradera plattformen. Digibility erbjuder support genom manualler och instruktionsvideo samt kundssupport i skälig utsträckning, ej inkluderat specifika tekniska förutsättningar som t.ex. webbläsare utöver Google Chrome, Apple Safari och Firefox. 


          </DialogContentText>
      </DialogContent> 
      <DialogActions style={{ justifyContent: "space-between", float:"right"}}>

          <Button onClick={this.handleCloseTerms}>Stäng</Button>
        </DialogActions>
    </Dialog>



    </ThemeProvider>
  );
        // return (
        //     <div className="col-md-6 m-auto">
        //         <div className="card card-body mt-5">
        //             <h2 className="text-center">Registrering</h2>
        //             <form onSubmit={this.onSubmit}>
        //                 <div className="form-group">
        //                     <label>Namn</label>
        //                     <input
        //                         type="text"
        //                         className="form-control"
        //                         name="name"
        //                         onChange={this.onChange}
        //                         value={name}
        //                     />
        //                 </div>
        //                 <div className="form-group">
        //                     <label>Email</label>
        //                     <input
        //                         type="email"
        //                         className="form-control"
        //                         name="email"
        //                         onChange={this.onChange}
        //                         value={email}
        //                     />
        //                 </div>
        //                 <div className="form-group">
        //                     <label>Lösenord</label>
        //                     <input
        //                         type="password"
        //                         className="form-control"
        //                         name="password"
        //                         onChange={this.onChange}
        //                         value={password}
        //                     />
        //                 </div>
        //                 <div className="form-group">
        //                     <label>Bekräfta Lösenord</label>
        //                     <input
        //                         type="password"
        //                         className="form-control"
        //                         name="password2"
        //                         onChange={this.onChange}
        //                         value={password2}
        //                     />
        //                 </div>
        //                 <div className="form-group">
        //                     <button type="submit" className="btn btn-primary">
        //                         Registrera
        //                     </button>
        //                 </div>
        //                 <p>
        //                     Har du redan ett konto?{" "}
        //                     <Link to="/login">Logga in</Link>
        //                 </p>
        //             </form>
        //         </div>
        //     </div>
        // );
    }
}

const mapStateToProps = (state) => ({
    isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, { register, createMessage })(Register);




// return (
//     <ThemeProvider theme={theme}>
//       <Container component="main" maxWidth="xs">
//         <CssBaseline />
//         <Box
//           sx={{
//             marginTop: 8,
//             display: 'flex',
//             flexDirection: 'column',
//             alignItems: 'center',
//           }}
//         >
//           <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
//             {/* <LockOutlinedIcon /> */}
//           </Avatar>
//           <Typography component="h1" variant="h5">
//             Sign up
//           </Typography>
//           <Box component="form" noValidate onSubmit={this.onSubmit} sx={{ mt: 3 }}>
//             <Grid container spacing={2}>
//               <Grid item xs={12} sm={6}>
//                 <TextField
//                   autoComplete="given-name"
//                   name="name"
//                   required
//                   fullWidth
//                   id="name"
//                   label="Namn"
//                   autoFocus
//                   onChange={this.onChange}
//                   value={name}

//                 />
//               </Grid>
//               {/* <Grid item xs={12} sm={6}>
//                 <TextField
//                   required
//                   fullWidth
//                   id="lastName"
//                   label="Last Name"
//                   name="lastName"
//                   autoComplete="family-name"
//                 />
//               </Grid> */}
//               <Grid item xs={12}>
//                 <TextField
//                   required
//                   fullWidth
//                   id="email"
//                   label="Email Address"
//                   name="email"
//                   autoComplete="email"
//                   onChange={this.onChange}
//                   value={email}
//                 />
//               </Grid>
//               <Grid item xs={12}>
//                 <TextField
//                   required
//                   fullWidth
//                   name="password"
//                   label="Lösenord"
//                   type="password"
//                   id="password"
//                   autoComplete="new-password"
//                   onChange={this.onChange}
//                   value={password}
//                 />
//               </Grid>
//               <Grid item xs={12}>
//                 <FormControlLabel
//                   control={<Checkbox value="allowExtraEmails" color="primary" />}
//                   label="I want to receive inspiration, marketing promotions and updates via email."
//                 />
//               </Grid>
//             </Grid>
//             <Button
//               type="submit"
//               fullWidth
//               variant="contained"
//               sx={{ mt: 3, mb: 2 }}
//             >
//               Sign Up
//             </Button>
//             <Grid container justifyContent="flex-end">
//               <Grid item>
//                 <Link href="#" variant="body2">
//                   Already have an account? Sign in
//                 </Link>
//               </Grid>
//             </Grid>
//           </Box>
//         </Box>
//         {/* <Copyright sx={{ mt: 5 }} /> */}
//       </Container>
//     </ThemeProvider>
//   );


