import React from 'react';
import { Page, View, Text, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
    bodytext: {
        fontSize:10, 
        paddingTop:10,
        lineHeight:1.3,
    }
  
});

const AboutPage = ()  => (
    <Page size="a4" style={{padding:60}}>
            <View style={{width: '100%', marginBottom: 10, height: 40, backgroundColor: '#ED663B', padding:5}}>
         <Text style={{color:"white", fontSize:20 }}> 
         Om rapporten 
        </Text>
     </View>
        <View style={{width: '100%', marginBottom: 30, borderRadius: 5, height: 700, backgroundColor: 'white', padding:20}}>
           
            <Text style={styles.bodytext}> 
            Digibility består av fyra delar:
            </Text>

            <Text style={styles.bodytext}> 
            1) ”Så lär jag mig” – Metakognitivt skattningsformulär 
            </Text>

            <Text style={styles.bodytext}> 
            2) ”Tänk ut figuren” – Analogt tänkande med strategistöd
            </Text>

            <Text style={styles.bodytext}> 
            3) ”Baklängesminne” – Metakognitivt arbetsminne 
            </Text>

            <Text style={styles.bodytext}> 
            4) ”Håll koll på färgen” – Uppmärksamhetsreglering
            </Text>

            <Text style={styles.bodytext}> 
            Dessa redovisas separat i form av ett resultat och förslag till analys och rekommendation. De sammantagna resultaten bygger på en normerad jämförelse med årskursvisa grupper.
            </Text>


            <Text style={styles.bodytext}> 
            Metakognitivt skattningsformulär består av 12 frågor som eleven skattar rörande sin syn på sig själv och lärandet. Flera frågor berör generella aspekter av hur eleven upplever sitt lärande på lektionerna, att kunna följa instruktioner, upplevelse av att förstå, kunna vara koncentrerad under lektioner och bli färdig med uppgifter, att kunna påverka sitt lärande samt elevens känslor kring om de tycker om att lära sig. Dessutom berörs hur eleven upplever sin förmåga till självreglering i form av planering, uthållighet och strategianvändande. Slutligen berörs även hur eleven upplever lärares förväntningar på deras lärande och upplevelse av att lärare kan hjälpa eleven. Resultatet redovisas i form av ett sammantaget resultat, samt elevens skattningar på de 12 enskilda frågorna. 
            </Text>
                 

            <Text style={styles.bodytext}> 
            I Analogt tänkande med strategistöd löser eleven problem som kräver analogt tänkande. Analogt tänkande innebär att eleven utifrån identifierade likheter och skillnader i visuell information tänker relationellt kring samband och mönster i informationen, för resonemang och drar slutsatser. Efter att eleven når sin nivå för problemlösningen får eleven en explicit modellande instruktion för att stödja elevens strategier. Därefter får eleven genomföra ytterligare uppgifter som börjar från elevens taknivå. Resultatet redovisas i form av ett sammantaget resultat innan strategistöd, samt ett resultat efter strategistöd.        
            </Text>

            <Text style={styles.bodytext}> 
            I Metakognitivt arbetsminne presenteras eleven visuella stimuli i successiv ordning som kräver stegvis längre uppmärksamhetspann. Därefter ska eleven återge ordningen baklänges. Efter att eleven når sin självständiga taknivå får eleven stöd med exempel på två explicita minnesstrategier. Därefter får eleven genomföra ytterligare uppgifter som börjar från elevens taknivå. Resultatet redovisas i form av ett sammantaget resultat innan strategistöd, samt ett resultat efter strategistöd.       
            </Text>

            <Text style={styles.bodytext}> 
            I Uppmärksamhetsreglering presenteras eleven ett ord på en färg, skrivet med en färg som kan vara samma färg (kongruent uppgift) eller i en annan färg än ordets innebörd (inkongruent uppgift). Regeln för uppgiften är att eleven ska peka på den färg ordet är skrivet med, inte den färg som står skriven i ordet. När eleven genomför uppgiften ska eleven hålla regeln i huvudet och samtidigt uppmärksamma rätt egenskap i de ord som presenteras, utan att bli distraherad av eller agera impulsivt utifrån. Resultatet redovisas i form av ett sammantaget resultat som utgör skillnaden mellan kongruenta och inkongruenta uppgifter.        
            </Text>
            
            <Text style={styles.bodytext}> 
            Ett resultat måste alltid tolkas i sitt sammanhang utifrån den enskilda elevens förutsättningar. Det finns en rad faktorer som kan påverka resultatet så att det inte är rättvisande. I analysen är det viktigt att väga resultatet mot andra underlag och informationskällor. Det är en fördel att samtala med eleven om uppgifterna efteråt, vilka som upplevdes lätta, vilka som var utmanande för att få en kvalitativ förståelse för resultatet. På detta sätt kan man tillsammans identifiera ledtrådar som bidrar med en fördjupad förståelse för elevens lärande och behov i skolan inom olika områden, ämnen, läromedel och undervisning.      
            </Text>
        </View>
    </Page> 
);

export default AboutPage