import axiosInstance from "../../../helpers/axios";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/Button";
import React, { useEffect, useState } from "react";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { Link } from "react-router-dom";


import { useSelector, useDispatch } from "react-redux";
import { completed_and_submitted_to_db } from "../assessmentSlice";

export default function OutroBlock() {
    const [post_db_status, setPost_db_status] = useState("initial"); // itial | ongoing | failed | succeeded
    const assessmentQAstate = useSelector(
        (state) => state.assessment.assessmentQAstate
    );
    const dispatch = useDispatch();

    function refreshPage() {
        window.location = process.env.REACT_APP_URL + 'assessments/'
    }

    function post_to_db() {
        let json_string = JSON.stringify(assessmentQAstate);
        console.log(JSON.stringify(assessmentQAstate));
        setPost_db_status("ongoing");
        const token = localStorage.getItem("token");

        const config = {
            headers: { Authorization: `Token ${token}` }
        };        
            axiosInstance
            .post("assessment/submited_assessments/", {
                json: json_string,
                },  
                config
            )
            .then((res) => {
                setPost_db_status("succeeded");
                dispatch(completed_and_submitted_to_db());
            })
            .catch((err) => {
                setPost_db_status("failed");
            });
    }

    useEffect(() => {
        post_to_db();
    }, []);

    switch (post_db_status) {
        case "initial":
            return (
                <div>
                    <div>Initiating...</div>
                    <CircularProgress />
                </div>
            );

        case "ongoing":
            return (
                <div>
                    <div>Uploading...</div>
                    <CircularProgress />
                </div>
            );

        case "failed":
            return (
                <div>
                    <div>
                        Kontrollera din internetanslutning och försök igen
                    </div>
                    <Button
                        onClick={() => post_to_db()}
                        style={{
                            backgroundColor: "#0F77F2",
                        }}
                    >
                        {" "}
                        försök igen
                    </Button>
                </div>
            );

        case "succeeded":
            return(
                <div>
                <br />
                <div>
                    <h3>Bra jobbat, du är klar!</h3>
                    <CheckCircleIcon
                        style={{ color: "#009900", fontSize: "200px" }}
                    />
                </div>
                <br />
                <br />
                <br />

                <Link to="/assessments"> 
                        <Button
                            variant="outlined"
                            onClick={refreshPage}>
                            Tillbaka till start
                        </Button>
                    </Link>
            </div>
            );


        default:
            break;
    }
}
