import React from "react";
import { useSelector } from "react-redux";

import LinearProgress from "@material-ui/core/LinearProgress";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";

const normalise = (value) => ((value - 0) * 100) / (11 - 0);

function LinearProgressWithLabel(props) {
    return (
        <Box display="flex" alignItems="center">
            <Box width="100%" mr={1}>
                <LinearProgress variant="determinate" {...props} />
            </Box>
            <Box minWidth={35}>
                <Typography
                    variant="body2"
                    color="textSecondary"
                >{`${Math.round(props.value)}%`}</Typography>
            </Box>
        </Box>
    );
}

LinearProgressWithLabel.propTypes = {
    /**
     * The value of the progress indicator for the determinate and buffer variants.
     * Value between 0 and 100.
     */
    value: PropTypes.number.isRequired,
};

const useStyles = makeStyles({
    root: {
        width: "60%",
        marginLeft: "20%",
        marginRight: "20%",
    },
});

export default function LinearWithValueLabel() {
    const classes = useStyles();
    // const [progress, setProgress] = React.useState(10);
    const current_block = useSelector(
        (state) => state.assessment.current_block
    );

    return (
        <div className={classes.root}>
            <LinearProgressWithLabel value={normalise(current_block)} />
        </div>
    );
}