import React, { useEffect, useState } from "react";
import MatrixQuestion from "./MatrixQuestion";
import MatrixVideo from "./MatrixVideo";

import Card from "../../common/Card";

import Button from "@material-ui/core/Button";
import ForwardIcon from "@material-ui/icons/Forward";

import { useSelector, useDispatch } from "react-redux";
import {
    submitMatrixBlockAnswer,
    setMatrixBaseline,
    setMatrixPostBaseline,
    nextBlock,
    increaseMatrixScore_before_baseline,
    increaseMatrixScore_after_baseline,
} from "../../assessmentSlice";

function get_current_timestamp() {
    const date = new Date();
    return date.getTime();
}

export default function MatrixBlock(props) {
    const dispatch = useDispatch();

    const [level_index, setLevel_index] = useState(0);
    const [subquestion, setSubquestion] = useState("a");
    const [view_video, setView_video] = useState(false);
    const [started_question_timestamp, setSstarted_question_timestamp] =
        useState(null);
    const levels = useSelector(
        (state) => state.assessment.assessmentQAstate.matrix_block.levels
    );
    const [is_post_baseline, setIs_post_baseline] = useState(false);
    const [started_block, setStarted_block] = useState(null);

    useEffect(() => {
        // time stamp used to record time to react to every question
        setSstarted_question_timestamp(get_current_timestamp());

        // time stamp for complete block
        setStarted_block(get_current_timestamp());
    }, []);

    function get_current_question() {
        return levels[level_index][subquestion];
    }

    function is_continue_disabled() {
        return get_current_question().answer_index === null;
    }

    function nextLevel() {
        if (level_index < levels.length - 1) {
            setLevel_index((current_level) => current_level + 1);
            setSubquestion("a");
        } else {
            if (!is_post_baseline) {
                dispatch(setMatrixBaseline(level_index));
            }
            dispatch(setMatrixPostBaseline(level_index));

            exit();
        }
    }

    function handleAnswer(answer_index) {
        dispatch(
            submitMatrixBlockAnswer({
                level_index,
                subquestion,
                answer_index,
                milliseconds_to_answer:
                    get_current_timestamp() - started_question_timestamp,
            })
        );
    }

    function exit() {
        let finished_block = get_current_timestamp();
        props.blockTimer("matrix_block", started_block, finished_block);
        dispatch(nextBlock());
    }

    function next_question() {
        if (!is_post_baseline) {
            dispatch(setMatrixBaseline(level_index - 1));
        }
        dispatch(setMatrixPostBaseline(level_index - 1));

        if (
            levels[level_index][subquestion].is_correct &&
            (subquestion === "a" || subquestion === "b")
        ) {
            if (!is_post_baseline) {
                dispatch(increaseMatrixScore_before_baseline());
            }
            dispatch(increaseMatrixScore_after_baseline());
        }

        const { a, b, c, d } = levels[level_index];

        switch (
            subquestion //What is the current question? A,B,C or D?
        ) {
            case "a": //A: Continue question B
                setSubquestion("b");
                break;

            case "b": //B:
                if (!a.is_correct && !b.is_correct) {
                    //is both A and B incorrect?
                    setIs_post_baseline(true);
                    if (is_post_baseline) {
                        //are we post-baseline/have assessee failed a level before?
                        //Exit to next block
                        exit();
                    } else {
                        //continue to C
                        setSubquestion("c");
                        setView_video(true);
                    }
                } else {
                    //Go to next level, or exit if there are no more questions
                    nextLevel();
                }
                break;

            case "c": //C: Continue question D
                setSubquestion("d");
                break;

            case "d": //D:
                if (!c.is_correct && !d.is_correct) {
                    //is both C and D incorrect?
                    //Exit to next block
                    exit();
                } else {
                    //Go to next level, or exit if there are no more questions

                    nextLevel();
                }
                break;

            default:
                break;
        }

        setSstarted_question_timestamp(get_current_timestamp());
    }

    function render() {
        let qa = get_current_question();

        if (view_video) {
            //View video
            //TODO: Video view with continue button that toggles off view_video state
            return (
                <MatrixVideo
                    video={levels[level_index].video}
                    handleContinue={() => {
                        setView_video(false);
                        setSstarted_question_timestamp(get_current_timestamp());
                    }}
                ></MatrixVideo>
            );
        } else {
            //render question
            return (
                <Card>
                    <MatrixQuestion
                        img={qa.img}
                        answers={qa.alternatives}
                        selected={qa.answer_index}
                        handleChange={handleAnswer}
                    />

                    <Button
                        onClick={next_question}
                        disabled={is_continue_disabled()}
                        style={{
                            backgroundColor: !is_continue_disabled()
                                ? "#0F77F2"
                                : "#d7d8d9",
                            color: !is_continue_disabled()
                                ? "#FFFFFF"
                                : "#null",
                            margin: "50px 0 24px 0",
                        }}
                        endIcon={<ForwardIcon />}
                    >
                        Fortsätt
                    </Button>

                    <style jsx>{`
                        .card {
                            border: solid 1px lightgray;
                            border-radius: 20px;
                            padding-top: 2.5em;
                            background-color: white;
                        }
                    `}</style>
                </Card>
            );
        }
    }

    return render();
}

// import React, {useEffect, useState} from 'react';
// import MatrixQuestion from './MatrixQuestion';
// import MatrixVideo from './MatrixVideo';

// import Button from '@material-ui/core/Button';
// import { Paper } from '@material-ui/core';

// import { useSelector, useDispatch } from 'react-redux'
// import {submitMatrixBlockAnswer, setMatrixBaseline, setMatrixPostBaseline, nextBlock, increaseMatrixScore_before_baseline, increaseMatrixScore_after_baseline} from '.././assessmentSlice'

// function get_current_timestamp(){
//     const date = new Date();
//     return date.getTime();
// }

// export default function MatrixBlock(){

//     const dispatch = useDispatch();

//     const [level_index, setLevel_index] = useState(0);
//     const [subquestion, setSubquestion] = useState('a');
//     const [view_video, setView_video] = useState(false);
//     const [started_question_timestamp, setSstarted_question_timestamp] = useState(null);
//     const baseline_level = useSelector(state => state.assessment.assessmentQAstate.matrix_block.baseline_level);
//     const levels = useSelector(state => state.assessment.assessmentQAstate.matrix_block.levels);

//     useEffect(()=>{
//         setSstarted_question_timestamp(get_current_timestamp());
//     },[]);

//     function is_post_baseline(){return baseline_level != null};

//     function get_current_question(){return levels[level_index][subquestion]};

//     function is_continue_disabled(){return (get_current_question().answer_index === null)};

//     function nextLevel(){
//         if (level_index < levels.length - 1){
//             setLevel_index(current_level => current_level+1);
//             setSubquestion('a');
//         } else {
//             if (!is_post_baseline()){
//                 dispatch(setMatrixBaseline(level_index));
//             }

//             dispatch(setMatrixPostBaseline(level_index));

//             exit()
//         }
//     }

//     function handleAnswer(answer_index){
//         dispatch(submitMatrixBlockAnswer({
//             level_index,
//             subquestion,
//             answer_index,
//             milliseconds_to_answer : get_current_timestamp() - started_question_timestamp
//         }));
//     }

//     function exit(){
//         dispatch(nextBlock());
//     }

//     function increase_score(){
//         if (is_post_baseline()){
//             dispatch(increaseMatrixScore_after_baseline())
//         } else {
//             dispatch(increaseMatrixScore_after_baseline())
//             dispatch(increaseMatrixScore_before_baseline())
//         }
//     }

//     function next_question(){
//         const {a,b,c,d} = levels[level_index];

//         switch (subquestion) { //What is the current question? A,B,C or D?
//             case 'a': //A: Continue question B
//                 if (a.is_correct){
//                     increase_score();
//                 }

//                 setSubquestion('b');

//                 break;

//             case 'b': //B:
//                 if (b.is_correct){
//                     increase_score();
//                 }

//                 if ((!a.is_correct)&&(!b.is_correct)){ //is both A and B incorrect?
//                     if (is_post_baseline()){ //are we post-baseline/have assessee failed a level before?
//                         //Exit to next block
//                         dispatch(setMatrixPostBaseline(level_index-1));
//                         exit(); //Change to handlechange and submit_post_baseline_level
//                     } else {
//                         //set baseline
//                         dispatch(setMatrixBaseline(level_index-1))
//                         //continue to C
//                         setSubquestion('c');
//                         setView_video(true);
//                     }

//                 } else{ //Go to next level, or exit if there are no more questions
//                     nextLevel();
//                 }
//                 break;

//             case 'c': //C: Continue question D
//                 setSubquestion('d');
//                 break;

//             case 'd': //D:
//                 if ((!c.is_correct)&&(!d.is_correct)){ //is both C and D incorrect?
//                     if (is_post_baseline()){ //are we post-baseline/have assessee failed a level before?
//                         dispatch(setMatrixPostBaseline(level_index-1));}

//                     //Exit to next block
//                     exit();
//                 } else{ //Go to next level, or exit if there are no more questions
//                     nextLevel();
//                 }
//                 break;

//             default:
//                 break;
//         }

//         setSstarted_question_timestamp(get_current_timestamp());
//     }

//     function render() {
//         let qa = get_current_question()

//         if (view_video){
//             //View video
//             //TODO: Video view with continue button that toggles off view_video state
//             return(
//                 <MatrixVideo
//                     video = {levels[level_index].video}
//                     handleContinue = {() => {setView_video(false); setSstarted_question_timestamp(get_current_timestamp());}}
//                 ></MatrixVideo>
//             )
//         } else{
//             //render question
//             return (
//                 <Paper style={{marginLeft:'25%', marginRight:'25%'}} elevation={3}>
//                     <div >
//                         {level_index}
//                         {subquestion}
//                     </div>

//                     <MatrixQuestion
//                         img = {qa.img}
//                         answers = {qa.alternatives}
//                         selected = {qa.answer_index}
//                         handleChange = {handleAnswer}//FIXME
//                     />

//                     <Button
//                         onClick = {next_question}
//                         disabled = {is_continue_disabled()}
//                         style= {{
//                             backgroundColor: !is_continue_disabled()? '#0F77F2': null, margin:'50px'
//                           }}
//                     >Fortsätt</Button>
//                 </Paper>
//             )
//         }
//     }

//     return render();

// }
