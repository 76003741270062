import React from "react";
import {
    Document,
    Page,
    Text,
    View,
    StyleSheet,
    Font,
  } from '@react-pdf/renderer';

import FrontPage from "./FrontPage";
import AboutPage from "./AboutPage";
import InfoPage from "./InfoPage";
import AnalogPage from "./AnalogPage";
import CorsiPage from "./CorsiPage";
import StroopPage from "./StroopPage";
import SubtestPage from "./SubtestPage"; 




// Create styles
// const styles = StyleSheet.create({
//     page: {
//         flexDirection: "row",
//         backgroundColor: "#E4E4E4",
        
//     },
//     content: {
//       padding: 10,

//       '@media max-width: 400': {
//         flexDirection: 'column',
//       },
//       '@media min-width: 400': {
//         flexDirection: 'row',
//       },

//     },
//     section: {
//         margin: 10,
//         padding: 10,
//         flexGrow: 1,
//     },
//     gaugeheader: {
//       fontSize: 12,
//     },

//     view: {
//         fontFamily: 'Helvetica',
//         width: '250px',
//         fontSize: 11,
//         paddingTop: 10,
//         lineHeight: 2,
//         flexDirection: 'column',
//         position: 'absolute',
//         left: 0,
//         top: 0,
//     }, 

//     headertext:{
//         padding: '10 12 0 12',
//         fontSize: 14
//     },

//     bodytext: {
//         padding: '5 12',
//         fontSize:9, 
//     },
// });


// GPTSTYLES
const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: '#fff',
    padding: '1in',
  },
  table: {
    display: 'table',
    width: '100%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },
  tableHeader: {
    flexDirection: 'row',
    backgroundColor: '#4B9CD3',
  },
  tableRow: {
    flexDirection: 'row',
    backgroundColor: 'white',
  },
  tableRowGrey: {
    flexDirection: 'row',
    backgroundColor: 'lightgrey',
  },
  tableCol: {
    flexGrow: 1,
    borderStyle: 'solid',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    padding: 8,
  },
  tableHeaderCol: {
    flexGrow: 1,
    borderStyle: 'solid',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    borderBottomWidth: 0,
    padding: 8,
  },
  tableCell: {
    fontSize: 8,
    wrap: true,
  },
  tableHeaderCell: {
    fontSize: 12,
    fontWeight: 'bold',
    color: '#fff',
  },


  tableColLeft: {
    flexGrow: 9,
    borderStyle: 'solid',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    padding: 10,
  },
  tableColRight: {
    flexGrow: 1,
    borderStyle: 'solid',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    padding: 8,
  },  
});



const GPTtable = ({data}) => {

    return (
      <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.table}>
          <View style={[styles.tableRow, styles.tableHeader]}>
              <View  style={styles.tableHeaderCol}>
                <Text style={[styles.tableCell, styles.tableHeaderCell]}>
                  {"calm down"}
                </Text>
              </View>
          </View>
          {data.map((item, rowIndex) => (
            <View
              key={rowIndex}
              style={
                rowIndex % 2 === 0 ? styles.tableRow : styles.tableRowGrey
              }
            >
              <View style={styles.tableColLeft}>
                <Text style={styles.tableCell}>{item.title}</Text>
              </View>
              <View style={styles.tableColRight}>
                <Text style={styles.tableCell}>{item.value}</Text>
              </View>
            </View>
          ))}
        </View>
      </Page>
    </Document>
    );
  };
  


// Create Document Component
const Report = (data) => (

  // <GPTtable data={}>
  // </GPTtable>
  // the following code does this 
  <Document>
    <FrontPage data={data}/>  

    {data.data.test_extent === "complete" ? (
      <AboutPage data={data}/>
    ): null}

    {data.data.test_extent === "complete" ? (
      <InfoPage data={data}/>
    ): null}

    {/* custom code */}
    {data.data.test_extent === "matrix" || data.data.test_extent === "corsi" || data.data.test_extent === "stroop" ? (
    <SubtestPage data={data}/>  
    ): null }

    {data.data.test_extent === "complete" || data.data.test_extent === "matrix" ? (
      <AnalogPage data={data.data.matrix_block}/>  
    ) : null}



    {data.data.test_extent === "complete" || data.data.test_extent === "corsi" ? (
      <CorsiPage data={data.data.corsi_block} />
    ) : null}
    {data.data.test_extent === "complete" || data.data.test_extent === "stroop" ? (
      <StroopPage data={data.data.stroop_block}/>
    ) : null} 
  </Document>
);

// Metakognitivt arbetsminne - baklängesminne 

// 

export default Report;
