import React from 'react';
import { Page, View, Text, Svg, Polygon, Image } from '@react-pdf/renderer';

const FrontPage = ({data})  => (
  console.log("Frontpage:", data.data.test_extent),
<Page size="a4">
  		<View style={{position:'relative'}}> 
      <Svg height="1000" width="1000" style={{position: 'absolute'}}>
        <Polygon
          points="0,0 0,849 300,849"
          fill="#ED663B"
        />
      </Svg>
        <Svg height="1000" width="1000" style={{position: 'absolute'}}>
        <Polygon
          points="10,0 134,350 400,0"
          fill="#d3d3d3"
        />
      </Svg>
        </View>
    <View style={{ top: "325px", left: "250px" }}> 
        {/* <Text style={{fontSize:50, fontWeight:"bold", color:"#ED663B", textAlign:"center",}}> 
            Digibility 
        </Text> */}
        <Image source="https://digibility.ams3.digitaloceanspaces.com/digibility/media/images/digilogo.png" style={{ width: '50%', height: 'auto', marginBottom : 8}}/>        
      </View>

      <View style={{ top: "325px", left: "100px" }}> 
        <Text style={{fontSize:20, fontWeight:"bold",textAlign:"center",}}> 
        {data.data.test_extent === "stroop" ? "Deltest: Uppmärksamhetsreglering" : 
        data.data.test_extent === "corsi" ? "Deltest: Metakognitivt arbetsminne" :
        data.data.test_extent === "matrix" ? "Deltest: Analogt tänkande" :
        data.data.test_extent === "complete" ? "Fullständigt dynamisk kartläggning" :
        " "
        } 
        </Text>
      </View>
  
      {/* <View style={{width:200, height: 50, backgroundColor: 'lightgrey', position: 'absolute', bottom:130, right: 75, borderRadius: '10px'}}> 
        <Text style={{textAlign:'center', verticalAlign: 'middle', fontSize:14, marginTop: '15', fontWeight: 'semibold'}}> 
            {data.data.info_form_block.namn}
        </Text> 
    </View> */}

    <View style={{width:200, height: 17, backgroundColor: 'white', position: 'absolute', bottom:130, right: 75, }}> 
        <Text style={{textAlign:'left', verticalAlign: 'bottom', fontSize:14}}> 
            Elevnamn: {data.data.info_form_block.namn}
        </Text> 
    </View>

  
    <View style={{width:200, height: 17, backgroundColor: 'white', position: 'absolute', bottom:100, right: 75, }}> 
        <Text style={{textAlign:'left', verticalAlign: 'bottom', fontSize:14}}> 
            Genomförd: {data.data.created_at.slice(0,10)} 
        </Text> 
    </View>
    <View style={{width:200, height: 17, backgroundColor: 'white', position: 'absolute', bottom:70, right: 75}}> 
        <Text style={{textAlign:'left', verticalAlign: 'top', fontSize:14}}> 
            Administrerad av {data.data.owner_name}  
        </Text> 
    </View>


    </Page>

);

export default FrontPage

