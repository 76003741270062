import React from "react";
import { Card } from "@material-ui/core";

import { ToggleButton } from "@material-ui/lab";
import { ToggleButtonGroup } from "@material-ui/lab";
import Vimeo from "@u-wave/react-vimeo";

export default function TextOrVideo(props) {
    const [alternative, setAlternative] = React.useState("text");
    const handleAlternative = (event, newAlternative) => {
        setAlternative(newAlternative);
    };

    function ToggleButtons() {
        return (
            <ToggleButtonGroup
                size="small"
                value={alternative}
                exclusive
                onChange={handleAlternative}
                aria-label="text alignment"
            >
                <ToggleButton value="text" aria-label="left aligned">
                    Text
                </ToggleButton>
                <ToggleButton value="video" aria-label="centered">
                    Video
                </ToggleButton>
            </ToggleButtonGroup>
        );
    }

    function renderTextOrVideo() {
        if (alternative === "text") return <div> {props.body}</div>;
        else if (alternative === "video")
            return (
                <div>
                    <Vimeo video={props.video} width={500} height={480} />
                </div>
            );
        else return <div> </div>;
    }

    return (
        <div>
            <Card
                style={{
                    display: "inlineBlock",
                    verticalAlign: "middle",
                    marginTop: "2.5%",
                    marginLeft: "10%",
                    marginRight: "10%",
                }}
            >
                <div
                    style={{
                        display: "grid",
                        verticalAlign: "middle",
                        gridTemplateColumns: "1fr 1fr 1fr",
                    }}
                >
                    <div> </div>
                    <h2>{props.title}</h2>
                    <div style={{ marginTop: "19px", marginRight: "0px" }}>
                        {ToggleButtons()}
                    </div>
                </div>
                <hr style={{ width: "80%" }}></hr>
                <br />
                <div
                    style={{
                        marginRight: "5%",
                        marginLeft: "5%",
                        marginBottom: "5%",
                        alignContent: "center",
                        color: "black",
                    }}
                >
                    {renderTextOrVideo()}
                </div>
            </Card>
        </div>
    );
}
