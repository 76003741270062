import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import CssBaseline from "@material-ui/core/CssBaseline";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import CreateIcon from "@material-ui/icons/Create";
import AssessmentIcon from "@material-ui/icons/Assessment";
import DashboardIcon from "@material-ui/icons/Dashboard";
import SettingsIcon from "@material-ui/icons/Settings";
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';

import LibraryBooksIcon from "@material-ui/icons/LibraryBooks";

import { Link } from "react-router-dom";
import { Button } from "@material-ui/core";

import SidebarFooter from "./SidebarFooter";
// import Logo from "../../../src/digilogo.png";

// new imports 
// import Button from '@mui/material/Button';
// import List from '@mui/material/List';
// import Divider from '@mui/material/Divider';
// import ListItem from '@mui/material/ListItem';
// import ListItemButton from '@mui/material/ListItemButton';
// import ListItemIcon from '@mui/material/ListItemIcon';
// import ListItemText from '@mui/material/ListItemText';
// import InboxIcon from '@mui/icons-material/MoveToInbox';
// import MailIcon from '@mui/icons-material/Mail';


const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
    },
    appBar: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
    },
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.default,
        padding: theme.spacing(3),
    },
}));

export default function PermanentDrawerLeft() {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <CssBaseline />
            <Drawer
                className={classes.drawer}
                variant="permanent"
                classes={{
                    paper: classes.drawerPaper,
                }}
                anchor="left"
                sx={{
                    pr: '24px', // keep right padding when drawer closed
                }}
            >
                {/* <div className={classes.toolbar} /> */}

                <img  src='https://digibility.ams3.digitaloceanspaces.com/digibility%2Fmedia%2Fimages%2Fdigilogo.webp' style={{ width: 150, height: 37.5, margin: 10, display: 'block', marginLeft: 'auto', marginRight: 'auto', width: '57.5%'}}/>
                <Divider />
                <List>
                    <Link
                        to="/assessments"
                        style={{
                            textDecoration: "none",
                            backgroundColor: "#000000",
                        }}
                    >
                        <Button
                            variant="contained"
                            style={{
                                backgroundColor: "#ED663B",
                                color: "#FFFFFF",
                                width: "200px",
                                margin: "7.5px 0 7.5px 0 ",
                                textTransform: 'none', 
                            }}
                            startIcon={<CreateIcon />}
                        >
                            Ny Kartläggning
                        </Button>
                    </Link>

                    <Link
                        to="/"
                        style={{
                            textDecoration: "none",
                            color: "#000000",
                        }}
                    >
                        <ListItem button>
                            <ListItemIcon>
                                <DashboardIcon />
                            </ListItemIcon>
                            <ListItemText primary={"Startsida"} />
                        </ListItem>
                    </Link>
                    <Link
                        to="/rapporter"
                        style={{
                            textDecoration: "none",
                            color: "#000000",
                        }}
                    >
                        <ListItem button>
                            <ListItemIcon>
                                <AssessmentIcon />
                            </ListItemIcon>
                            <ListItemText primary={"Rapporter"} />
                        </ListItem>
                    </Link>
                    <Link
                        to="/moduler"
                        style={{
                            textDecoration: "none",
                            color: "#000000",
                        }}>

                    <ListItem button>
                        <ListItemIcon>
                            <LibraryBooksIcon />
                        </ListItemIcon>
                        <ListItemText primary={"Utbildningsmoduler"} />
                    </ListItem>
                    </Link> 
                </List>
                <Divider />
                <List>
                <Link
                        to="/support"
                        style={{
                            textDecoration: "none",
                            color: "#000000",
                        }}>
                    <ListItem button>
                        <ListItemIcon>
                         <HelpOutlineIcon />
                        </ListItemIcon>
                        <ListItemText primary={"Hjälp"} />
                    </ListItem>
                    </Link> 
                    <Link
                        to="/installningar"
                        style={{
                            textDecoration: "none",
                            color: "#000000",
                        }}>
                    <ListItem button>
                        <ListItemIcon>
                         <SettingsIcon />
                        </ListItemIcon>
                        <ListItemText primary={"Inställningar"} />
                    </ListItem>
                    </Link> 
     
                </List>  
              
              {/* Footer with user details and logoutButotn  */}
                <div style={{ width: "inherit", position: "fixed", bottom: 0, alignContent: "center",}}>
                    <Divider/>
                <SidebarFooter></SidebarFooter>
                </div>


            </Drawer>

        </div>
    );
}
