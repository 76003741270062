import React from "react";
import IntroVideo from "../IntroVideo";
import Card from "../../common/Card";

export default class CorsiIntroBlock extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <Card>
                <div>
                    <IntroVideo
                        handleContinue={this.props.nextBlock}
                        video={"https://vimeo.com/598877731/39b838bf4a"}
                    />
                </div>
            </Card>
        );
    }
}
